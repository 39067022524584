package ein2b.core.resource

import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import kotlinx.coroutines.flow.MutableSharedFlow

/**
 * {
 *  "data":{
 *      "list/header/userName":"@2@ pallet(unit price W @3@)"
 *      "keyPath2":"`stringList`banana,apple"
 *  }
 * }
 *
 * val v:Any = Resource["data1", {}]
 *     (v as? MutableList<String>)?.let{
 *         setList(it.map{....})
 *     }
 *
 */
val resourceSplit = """(?<!\\)@""".toRegex()
object Resource{
    val NO_KEY = object{}
    class Data:eEntity(){
        var data by stringMap
    }
    private val converter:HashMap<String, (String)->Any?> = hashMapOf(
        "int" to {it.toIntOrNull()},
        "short" to {it.toShortOrNull()},
        "long" to {it.toLongOrNull()},
        "uint" to {it.toUIntOrNull()},
        "ushort" to {it.toUShortOrNull()},
        "ulong" to {it.toULongOrNull()},
        "float" to {it.toFloatOrNull()},
        "double" to {it.toDoubleOrNull()},
        "boolean" to {it.toBooleanStrictOrNull()},
        "string" to {it},
        "intList" to {
            val items = it.split(",")
            val list:ArrayList<Int> = ArrayList(items.size)
            if(items.all{item->item.toIntOrNull()?.let(list::add) != null}) list else null
        },
        "shortList" to {
            val items = it.split(",")
            val list:ArrayList<Short> = ArrayList(items.size)
            if(items.all{item->item.toShortOrNull()?.let(list::add) != null}) list else null
        },
        "longList" to {
            val items = it.split(",")
            val list:ArrayList<Long> = ArrayList(items.size)
            if(items.all{item->item.toLongOrNull()?.let(list::add) != null}) list else null
        },
        "uintList" to {
            val items = it.split(",")
            val list:ArrayList<UInt> = ArrayList(items.size)
            if(items.all{item->item.toUIntOrNull()?.let(list::add) != null}) list else null
        },
        "ushortList" to {
            val items = it.split(",")
            val list:ArrayList<UShort> = ArrayList(items.size)
            if(items.all{item->item.toUShortOrNull()?.let(list::add) != null}) list else null
        },
        "ulongList" to {
            val items = it.split(",")
            val list:ArrayList<ULong> = ArrayList(items.size)
            if(items.all{item->item.toULongOrNull()?.let(list::add) != null}) list else null
        },
        "floatList" to {
            val items = it.split(",")
            val list:ArrayList<Float> = ArrayList(items.size)
            if(items.all{item->item.toFloatOrNull()?.let(list::add) != null}) list else null
        },
        "doubleList" to {
            val items = it.split(",")
            val list:ArrayList<Double> = ArrayList(items.size)
            if(items.all{item->item.toDoubleOrNull()?.let(list::add) != null}) list else null
        },
        "booleanList" to {
            val items = it.split(",")
            val list:ArrayList<Boolean> = ArrayList(items.size)
            if(items.all{item->item.toBooleanStrictOrNull()?.let(list::add) != null}) list else null
        },
        "stringList" to { it.split(",") },
        "intMap" to {
            val items = it.split(",")
            val map = HashMap<String,Int>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toIntOrNull() ?: return@all false
                    true
                }) map else null
        },
        "shortMap" to {
            val items = it.split(",")
            val map = HashMap<String,Short>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toShortOrNull() ?: return@all false
                    true
                }) map else null
        },
        "longMap" to {
            val items = it.split(",")
            val map = HashMap<String,Long>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toLongOrNull() ?: return@all false
                    true
                }) map else null
        },
        "uintMap" to {
            val items = it.split(",")
            val map = HashMap<String,UInt>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toUIntOrNull() ?: return@all false
                    true
                }) map else null
        },
        "ushortMap" to {
            val items = it.split(",")
            val map = HashMap<String,UShort>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toUShortOrNull() ?: return@all false
                    true
                }) map else null
        },
        "ulongMap" to {
            val items = it.split(",")
            val map = HashMap<String,ULong>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toULongOrNull() ?: return@all false
                    true
                }) map else null
        },
        "floatMap" to {
            val items = it.split(",")
            val map = HashMap<String,Float>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toFloatOrNull() ?: return@all false
                    true
                }) map else null
        },
        "doubleMap" to {
            val items = it.split(",")
            val map = HashMap<String,Double>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toDoubleOrNull() ?: return@all false
                    true
                }) map else null
        },
        "booleanMap" to {
            val items = it.split(",")
            val map = HashMap<String,Boolean>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1].toBooleanStrictOrNull() ?: return@all false
                    true
                }) map else null
        },
        "stringMap" to {
            val items = it.split(",")
            val map = HashMap<String,String>()
            if (items.all{item->
                    val keyValue = item.split("|")
                    if (keyValue.size != 2) return@all false
                    map[keyValue[0]] = keyValue[1]
                    true
                }) map else null
        },
    )
    fun addConverter(type:String, block:(String)->Any?){ converter[type] = block }
    /** @ 문자를 사용하기 위해서는 \@으로 적는다 */
    private inline fun encodeString(v:Any?):String = "$v".replace("@","\\@")
    private inline fun decodeString(v:String):String = v.replace("\\@","@")
    private val resourceFlow = MutableSharedFlow<Rsc>()


    interface Cache{
        fun set(k:String, rsc:Rsc)
        fun get(k:String, block:(Any)->Unit):Any?
        fun _value(rsc:Rsc):Any? = rsc.getValue.invoke(arrayOf())
    }
    var cache:Cache? = null
    fun load(data:Data){
        cache?.also{
            data.data.forEach{ (k, v) ->
                val rsc = Rsc(k){
                    if(v[0] == '`'){
                        val index = v.indexOf("`")
                        val type = v.substring(1, index)
                        val result = v.substring(index + 1)
                        converter[type]?.invoke(result) ?: result
                    }else decodeString(v)
                }
                it.set(k, rsc)
                eLaunch{ resourceFlow.emit(rsc) }
            }
        }
    }
    operator fun get(k:String, block:(Any)->Unit) = cache?.get(k, block)
}