package router.login

import app.Api
import app.hashManager
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.app.M42App
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class LoginP(router:eRouter<HTMLElement>):PageBase(::LoginView, router){
    override suspend fun net(data:eRouterData){
        N.rsaGet()?.also{
            if(Api.isLogin) hashManager.goUrl(M42App.homeRouterKey)
            else view?.entity(it)
        }
    }
}