package router.masterEdit

import app.CompApp
import app.Factory
import app.clearCompValue
import comp.CompPageTitle
import comp.input.compInputText
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntClientAccountEdit
import bxlt.api.member.EntApiMemberMasterE
import bxlt.vali.ValiMemAccountPhone
import bxlt.vali.ValiMemAccountUserName
import bxlt.vali.ValiMemberCompanyName
import m42.app.M42App
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URL
import prop.compLabelInputSectionText
import router.comp.compProfileImageList
import router.comp.compProfileImageListSet
import view.CompViewToast

private val factory = Factory.htmlUrl("masterEdit")
private enum class K{ email, secretKey, secretKeyDate, regenBtn, userName, phone, company, profile, submitBtn }
suspend fun MasterEditView() = eView(factory){ staticView->
    val model = EntClientAccountEdit
    CompPageTitle(staticView){
        it.title = "r@마스터 계정 수정@5/5/15"
    }
    staticView.sub(K.email)
    staticView.compLabelInputSectionTextSet(K.userName,"r@이름@5/5/16", ValiMemAccountUserName){
        it.changeBlock = { v ->
            model.userName = v
            v
        }
    }
    staticView.compLabelInputSectionTextSet(K.phone,"r@연락처@5/5/17", ValiMemAccountPhone){
        it.changeBlock = { v ->
            model.phone = v
            v
        }
    }
    staticView.compLabelInputSectionTextSet(K.company,"r@회사명@5/5/18", ValiMemberCompanyName){
        it.changeBlock = { v ->
            model.company = v
            v
        }
    }

    staticView.compProfileImageListSet(K.profile){ comp ->
        comp.setAfterUploaded{
            model.profile = URL.Companion.createObjectURL(it)
        }
        comp.setClickItem{
            model.profile = it
        }
    }
    staticView.sub(K.submitBtn)
    staticView.sub(K.secretKey)
    staticView.sub(K.secretKeyDate)
    staticView.sub(K.regenBtn).click = { _,_->
        eLaunch{
            N.editSecretKey()?.also{ M42App.reload() }
        }
    }

    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement,HcInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: HcInit) {
            model.init()
            view.clearCompValue(K.userName, K.phone, K.company, K.profile)
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntApiMemberMasterE.Res::class, object:eEntityHook<HTMLElement,EntApiMemberMasterE.Res>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiMemberMasterE.Res) {
            model.setData(entity)
            view.entity(model)
        }
    })
    staticView.addEntityHook(EntClientAccountEdit::class, object:eEntityHook<HTMLElement,EntClientAccountEdit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientAccountEdit) {
            view.sub(K.email).html = entity.email
            view.sub(K.secretKey).html = entity.secretKey
            view.sub(K.secretKeyDate).html = entity.secretKeyDate
            view.sub(K.userName).compLabelInputSectionText().inputView().compInputText().value.inputValue(entity.userName)
            view.sub(K.phone).compLabelInputSectionText().inputView().compInputText().value.inputValue(entity.phone)
            view.sub(K.company).compLabelInputSectionText().inputView().compInputText().value.inputValue(entity.company)
            view.sub(K.profile).compProfileImageList{
                it.setProfileData(entity.profile, entity.profileList)
            }
            view.sub(K.submitBtn).click = {_,_->
                eLaunch{
                    val userName = view.sub(K.userName).compLabelInputSectionText()
                    val phone = view.sub(K.phone).compLabelInputSectionText()
                    val company = view.sub(K.company).compLabelInputSectionText()
                    val profile = view.sub(K.profile).compProfileImageList()
                    if(CompApp.checkAll(userName, phone, company, profile)){
                        val profileImage = profile.out()
                        N.edit(
                            userName.out(),
                            phone.out(),
                            if(profileImage.isUploadDefault) profileImage.image else profileImage.image.replaceFirst("blob:", ""),
                            company.out(),
                            profileImage.file
                        )?.also{ res->
                            if(res.isError){
                                if(res.error.profileErrMsg.isNotEmpty()) profile.changeError(res.error.profileErrMsg, false)
                                if(res.error.companyErrMsg.isNotEmpty()) company.changeError(res.error.companyErrMsg, false)
                            }else{
                                CompViewToast.setLocalStorage("r@마스터 계정 정보를 수정했습니다.@5/5/1f", type = CompViewToast.TOAST_TYPE.SUCCESS)
                                M42App.reload()
                            }
                        }
                    }
                }
            }
        }
    })
}