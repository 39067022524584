package bxlt.api.member

import ein2b.core.entity.eEntity
import bxlt.api.API_MEMBER_MASTER_PATH
import bxlt.api.EntBxltApi
import bxlt.model.MdlMember
import bxlt.model.MdlMemberEditParam
import bxlt.model.MdlMemberJoinParam
import m42.common.enum.EnumApiAccessAllow

//마스터 회원 가입 요청
object EntApiMemberMasterJoinEmailStep1:
    EntBxltApi<MdlMemberJoinParam, EntApiMemberMasterJoinEmailStep1.Res>(Res::class, ::MdlMemberJoinParam, ::Res){
    const val PATH = "$API_MEMBER_MASTER_PATH/join/email/step1"
    const val PATH_JSON = "$PATH/json"
    override val accessAllow = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
    class Res:eEntity(true){
        class Error:eEntity(true){
            var emailErrMsg by string{ default("") } // - 이메일:이미 등록됨 -> "이미 사용 중인 이메일 주소입니다."
            var companyErrMsg by string{ default("") } // 이미 사용중인 고객사 명입니다.
        }
        var error by entity(Res::Error){ encoding.isOptional() }
        val isError get() = props["error"] != null
    }
}
//마스터 회원 가입 완료
object EntApiMemberMasterJoinEmailStep2:
    EntBxltApi<EntApiMemberMasterJoinEmailStep2.Req, EntApiMemberMasterJoinEmailStep2.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_MEMBER_MASTER_PATH/join/email/step2"
    const val PATH_JSON = "$PATH/json"
    override val accessAllow = EnumApiAccessAllow.LOGOUT
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rscKeys = listOf("joinComplete")
    class Req:eEntity(){
        var token by string()
        var actionToken by string()
    }
    class Res:eEntity(true){
        var isOk by boolean()
        var message by string{ default("") }
    }
}
//마스터 회원 정보 수정 준비
object EntApiMemberMasterE: EntBxltApi<EntApiMemberMasterE.Req, EntApiMemberMasterE.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_MEMBER_MASTER_PATH/e"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val rscKeys = listOf("account", "masterEdit")
    class Req:eEntity()
    class Res:eEntity(){
        var member by entity(::MdlMember)
        var companyName by string
        var profileUrlList by stringList()
    }
}
//마스터 회원 정보 수정 실시
object EntApiMemberMasterEp:
    EntBxltApi<MdlMemberEditParam, EntApiMemberMasterEp.Res>(Res::class, ::MdlMemberEditParam, ::Res){
    const val PATH = "$API_MEMBER_MASTER_PATH/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val apiItems = listOf(FILE_KEY)
    class Res:eEntity(){
        class Error:eEntity(true){
            var profileErrMsg by string{default("")}
            var companyErrMsg by string{default("")}
        }
        var error by entity(::Error){ encoding.isOptional() }
        val isError:Boolean get() = props["error"] != null
        var member by entity(::MdlMember)
        var companyName by string
        var profileUrlList by stringList()
    }
}
//마스터 회원 시크릿 키 수정 실시
object EntApiMemberMasterSecretKeyEp: EntBxltApi<EntApiMemberMasterSecretKeyEp.Req, EntApiMemberMasterEp.Res>(EntApiMemberMasterEp.Res::class, ::Req, EntApiMemberMasterEp::Res){
    const val PATH = "$API_MEMBER_MASTER_PATH/secretkey/ep"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    class Req:eEntity()
}
//마스터 회원 삭제
object EntApiMemberMasterDp: EntBxltApi<EntApiMemberMasterDp.Req, EntApiMemberMasterDp.Res>(Res::class, ::Req, ::Res){
    const val PATH = "$API_MEMBER_MASTER_PATH/dp"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    class Req:eEntity()
    class Res:eEntity()
}