package comp

import app.Factory
import ein2b.core.core.err
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

class CompFileImage private constructor(): Comp {
    companion object {
        private enum class K{ wrap, fileImage, deleteBtnWrap, deleteBtn }
        //language=html
        internal val factory = Factory.html("""
        <div data-view="${K.wrap}" style='display:flex;justify-content:space-between;align-items:end'>
            <div class="image-container" style='position:relative'>
                <img data-view="${K.fileImage}" alt="">
                <div data-view="${K.deleteBtnWrap}" class="deleteBtn" style='position:absolute;right:6px;top:6px;width: 24px; height: 24px'>
                    <div data-view="${K.deleteBtn}" class="ic-trash"></div>
                </div>
            </div>
        </div>
        """.trimMargin())
//            <div class="ic-image-upload disabled"></div>
        suspend operator fun invoke(root:eView<HTMLElement>, subKey:Any, hasClickEvent:Boolean = true, block:(CompFileImage)->Unit): CompFileImage {
            val comp = CompFileImage()
            comp.hasClickEvent = hasClickEvent
            block(comp)
            root.sub(subKey, factory){ v ->
                v.sub(K.wrap){ wrapView-> wrapView.className = comp.wrapClass.ifBlank { "" } }
                v.sub(K.fileImage){
                    it.src = comp.fileUrl
                    it.click = {_,_-> if(comp.isOpen) window.open(comp.fileUrl) }
                }
                v.sub(K.deleteBtnWrap){ d ->
                    if(comp.hasDeleteBtn) d.displayBlock()
                    else d.displayNone()
                }
                v.sub(K.deleteBtn) { d ->
                    d.className = comp.deleteIcon
                    d.click = {e,_->
                        if(comp.deleteBlock != null){
                            comp.deleteBlock?.invoke()
                        }
                    }
                }
                comp.target = v
                comp.update(comp.fileName, comp.fileUrl)
                comp.baseProp(v)
            }
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    var wrapClass:String = ""
    var fileName:String = ""
    var fileUrl:String = ""
    var hasDeleteBtn:Boolean = true
    var deleteIcon:String = "ic-trash"
    var deleteBlock:(()->Unit)? = null
    var hasClickEvent:Boolean = true
    private val isOpen:Boolean get() = hasClickEvent && fileUrl.isNotBlank()
    suspend fun update(fileName:String, _fileUrl:String=""){
        if(fileName.isBlank()) target.displayNone() else target.displayFlex()
        target.sub(K.fileImage){
            if(!isOpen) it.domStyle("cursor", "unset")
            it.backgroundImage = _fileUrl
            it.click = {_,_->
                if(isOpen) window.open(_fileUrl,"_blank")
            }
        }
        fileUrl = _fileUrl
    }
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compFileImage(block:(CompFileImage)->Unit = {}): CompFileImage {
    val comp = this["compFileImage"] as? CompFileImage ?: err("fail to get compFileImage")
    block(comp)
    return comp
}