package router.pwSearch

import app.Api
import app.hashManager
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.app.M42App
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class PwSearchP(router:eRouter<HTMLElement>):PageBase(::PwSearchView, router){
    override suspend fun net(data:eRouterData){
        /** 비밀번호 찾기     서버 통신   서버 통신   로그인 체크 */
        N.loginCheck()?.also {
            if(Api.isLogin) hashManager.goUrl(M42App.homeRouterKey)
        }
    }
}