package comp.input

import app.Factory
import ein2b.core.core.err
import ein2b.core.view.eView
import ein2b.core.view.value
import org.w3c.dom.HTMLElement

/*  ********************* CompInputText 사용법 *********************
        val inputText = CompInputText {
           it.maxLength = 3                                                   // 최대 자리수 설정
           it.maxValue = 100                                                  // 최대값 설정
           it.minValue = 0                                                    // 최소값 설정
           it.placeholder = "홀더"                                            // 플레이스홀더 설정

           it.inputClass = "form-input-num"                                   // 인풋 클래스 설정
           it.inputErrorClass = "error"                                       // 에러일때 인풋 클래스 설정
           it.isDisabled = false                                              // 활성화, 비활성화 여부 설정
           it.isChangeCheck = true                                          // 인풋에 입력시 바로 상태를 체크해줌

           it.enterEvent = {                                                // 엔터 눌렀을때 이벤트가 필요하면 요걸쓴다
            console.log("엔터")
           }

           it.value.inputValue("3123")                                       // Hook 안에서 기본값 설정할때 쓰는것
           it.vali = eRuleVali {}                                             // 밸리데이션 체크 로직
        }
    }
    이벤트 리스너와 같이 사용할때는 컴포넌트를 변수로잡고
    inputText.enable(true)                                                       // 활성화 / 비활성화 처리
    inputText.focus(true)                                                       //focus된 것 처럼 클래스 설정(커서가 이동하지는 않음)
    inputText.blur(true)                                                       // blur 된 것 처럼 클래스를 바꿈
*/
class CompInputText:CompInputSingle<String>(){
    companion object{
        operator fun invoke(block:(CompInputText)->Unit):CompInputText{
            val comp = CompInputText()
            block(comp)
            comp.afterTargetInited = {
                comp.value = CompValue("", "", comp.vali, comp.errorListener, CompInput.CONV){ comp.target.value = it }
            }
            return comp
        }
    }
    override var subKey:String = "CompInputText_input"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<input data-view="$subKey" type="text">""")
}

// ============================ prop ============================
suspend fun eView<HTMLElement>.compInputTextSet(subKey:Any, afterBlock:suspend (CompInputText)->Unit = {}, block:(CompInputText)->Unit = {}){
    CompInputText{ block(it) }.also{
        it.comp(this, subKey)
        afterBlock(it)
    }
}
inline fun eView<HTMLElement>.compInputText(block:(CompInputText)->Unit = {}):CompInputText{
    val comp = this["compInputText_value"] as? CompInputText ?: err("fail to get compInputText")
    block(comp)
    return comp
}