package bxlt.api

import ein2b.core.entity.eEntity
import ein2b.core.entity.eSlowEntity
import m42.common.api.EntM42Api
import kotlin.reflect.KClass

enum class BxltApiErrorMethod(val key:String){
    ALERT("alert"),
    LOGIN("login"),
    TOAST("toast"),
    TOAST_ERROR("toast_error"),
    AFTER_BLOCK("after_block"),
    AFTER_TOAST("after_toast");
}
enum class BxltApiErrorAction{
    NONE,
    BACK,
    RELOAD,
    MOVE_TO_MAIN,
    MOVE_TO_LOGIN,
    AFTER_BLOCK;
}

class BxltApiResponse<DATA:eEntity>(dataCls:KClass<DATA>, val factory:()->DATA):eSlowEntity(true){
    companion object{
        val LOGOUT = Immutable<BxltApiResponseMember>(BxltApiResponseMember.Logout())
    }
    class Error(
        message:String = "",
        method: BxltApiErrorMethod = BxltApiErrorMethod.ALERT,
        action: BxltApiErrorAction = BxltApiErrorAction.NONE
    ):eEntity(true), BxltApiError {
        override var message by string{ default(message) }
        override var method by enum<BxltApiErrorMethod>{ default(method) }
        override var action by enum<BxltApiErrorAction>{ default(action) }
        override var id by string{ default("") } //기능목록의 Error ID
        val error get() = frmFindErrById(id)
    }
    var ver by string{ default("1") }
    var member by union(BxltApiResponseMember){ default(LOGOUT) }
    var error by entity(::Error){ encoding.isOptional() }
    var data by entity(dataCls, factory){
        default{ factory() }
        encoding.setResolver{ !isError }
    }
    val isError get() = props["error"] != null
    val isLogin get() = member is BxltApiResponseMember.Login
}

abstract class EntBxltApi<REQ: eEntity, RES: eEntity>(resCls:KClass<RES>, req:()->REQ, res:()->RES):EntM42Api<REQ,RES>(resCls, req, res){
//    open var isMenu = false
    open val authzKeys:Set<String>? get() = null //허용 인가키. null이면 인가키 무시함
    open val midIgnore get() = false //mid 무시여부
}