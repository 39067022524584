package ein2b.core.date

import ein2b.core.log.log
import kotlin.js.Date
@JsName("require")
external fun requireJS(path:String):dynamic

object einDayJs {
    val dayjs = requireJS("dayjs")
    val utc = requireJS("dayjs/plugin/utc")
    val timezone = requireJS("dayjs/plugin/timezone")

    val intlZoneList: dynamic = js("""Intl.supportedValuesOf('timeZone')""")

    init {
        //console.log("Dayjs init: dayjs = " + dayjs)
        dayjs.extend(utc)
        dayjs.extend(timezone)
    }
}

actual fun eLocalDateTime.Companion.now(): eLocalDateTime =
    Date().let { jsDate ->
        eLocalDateTime(
            jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
            jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
        )
    }

private inline fun eLocalDateTime.toPlatformDateTime(): Date =
    Date(this.year, this.month-1, this.date, this.hour, this.minute, this.second, this.millisecond)

private inline fun Date.toELocalDateTime(): eLocalDateTime =
    eLocalDateTime(
        this.getFullYear(), this.getMonth() + 1, this.getDate(),
        this.getHours(), this.getMinutes(), this.getSeconds(), this.getMilliseconds()
    )


private inline fun eZonedDateTime.toUtc(): eUtc = this.local.toUtc(this.zone)
private inline fun eUtc.toEZonedDateTime(zone:String) = eZonedDateTime(this.toLocalDateTime(zone),zone)
private inline fun eUtc.toELocalDateTime() = this.toLocalDateTime("UTC")

actual fun eLocalDateTime.toUtc(zone: String): eUtc {
    if(zone.equals("utc",true)) {
        return eUtc(this.year,this.month,this.date,this.hour,this.minute,this.second,this.millisecond)
    }
    if(!eZonedDateTime.checkZone(zone)) throw IllegalArgumentException("Invalid time zone string: ${zone}")

    val altZone =
        if(!eZonedDateTime.supportedBySystem(zone)) {
            // dayjs가 지원하는 타임존이 아닌 경우 대체 시간으로 변경 후 처리
            if (zone == "Asia/Kathmandu") {
                // localdatetime - 5:45임
                var min = this.minute - 45
                val timediff = if(min < 0) { min+=60; -1 } else { 0 }
                var time = this.hour - 5 + timediff
                val datediff = if(time < 24) { time+=24; -1 } else { 0 }
                if(datediff==-1) {
                    // 시간이 변경되면서 날짜가 바뀌는데 날짜 계산은 복잡하므로
                    // dayjs에 맡기자
                    val u = einDayJs.dayjs.utc("${year}-${month}-${date} ${time}:${min}:${second}").add(-1,"day")
                    return eUtc(
                        u.year() as Int, u.month() as Int + 1, u.date() as Int,
                        time, min, second, millisecond
                    )
                } else {
                    return eUtc(year,month,date,time,min,second,millisecond)
                }
            } else {
                eZonedDateTime.altZone(zone)
            }
        } else {
            zone
        }

    val u = einDayJs.dayjs.utc(this.toString()).tz(altZone,true).utc()
    return eUtc(
        u.year() as Int, u.month() as Int + 1, u.date() as Int,
        u.hour() as Int, u.minute() as Int, u.second() as Int, u.millisecond() as Int
    )
}


actual fun eUtc.Companion.now(): eUtc =
    Date().let { jsDate ->
        eUtc(
            jsDate.getUTCFullYear(), jsDate.getUTCMonth() + 1, jsDate.getUTCDate(),
            jsDate.getUTCHours(), jsDate.getUTCMinutes(), jsDate.getUTCSeconds(), jsDate.getUTCMilliseconds()
        )
    }

actual fun eUtc.toLocalDateTime(zone: String): eLocalDateTime {
    if(zone.equals("utc",true)) {
        return eLocalDateTime(this.yearUTC, this.monthUTC, this.dateUTC, this.hourUTC, this.minuteUTC, this.secondUTC, this.millisecondUTC)
    }
    if(!eZonedDateTime.checkZone(zone)) throw IllegalArgumentException("Invalid time zone string: ${zone}")

    val altZone =
        if(!eZonedDateTime.supportedBySystem(zone)) {
            // dayjs가 지원하는 타임존이 아닌 경우 대체 시간으로 변경 후 처리
            if (zone == "Asia/Kathmandu") {
                // UTC + 5:45임
                var min = this.minuteUTC + 45
                val timediff = if(min >= 60) { min-=60; 1 } else { 0 }
                var time = this.hourUTC + 5 + timediff
                val datediff = if(time >= 24) { time-=24; 1 } else { 0 }
                if(datediff==1) {
                    // 시간이 변경되면서 날짜가 바뀌는데 날짜 계산은 복잡하므로
                    // dayjs에 맡기자
                    val u = einDayJs.dayjs.utc("${yearUTC}-${monthUTC}-${dateUTC} ${time}:${min}:${secondUTC}").add(1,"day")
                    return eLocalDateTime(
                        u.year() as Int, u.month() as Int + 1, u.date() as Int,
                        time, min, secondUTC, millisecondUTC
                    )
                } else {
                    return eLocalDateTime(yearUTC,monthUTC,dateUTC,time,min,secondUTC,millisecondUTC)
                }
            } else {
                eZonedDateTime.altZone(zone)
            }
        } else {
            zone
        }

    val l = einDayJs.dayjs.utc(this.toString()).tz(altZone)
    return eLocalDateTime(
        l.year() as Int, l.month() as Int + 1, l.date() as Int,
        l.hour() as Int, l.minute() as Int, l.second() as Int, l.millisecond() as Int
    )
}

actual fun eUtc.timeInMilli(): Long =
    Date(Date.UTC(
        yearUTC, monthUTC - 1, dateUTC, hourUTC, minuteUTC, secondUTC, millisecondUTC
    )).getTime().toLong()

actual fun eUtc.platformDayOfWeekLocale(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String> {
    val day = einDayJs.dayjs.utc(this.toString())
    val dow = day.day()

    return dayOfWeekToDAY_KIND(dow).let {
        it to it.titleEnShort
    }
}

actual fun eLocalDateTime.platformDayOfWeekLocale(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String> {
    val timezone = if(locale.isBlank()) einDayJs.dayjs.tz.guess() else locale

    val day = einDayJs.dayjs.utc(this.toString()).tz(timezone,true)
    val dow = day.day()


    return dayOfWeekToDAY_KIND(dow).let {
        it to it.titleEnShort
    }
}

actual fun eUtc.platformDayOfWeek(): eDateTimePartGetter.DAY_KIND {
    val day = einDayJs.dayjs.utc(this.toString())
    val dow = day.day()

    return dayOfWeekToDAY_KIND(dow)
}

actual fun eLocalDateTime.platformDayOfWeek(): eDateTimePartGetter.DAY_KIND {
    val day = einDayJs.dayjs.utc(this.toString())
    val dow = day.day()

    return dayOfWeekToDAY_KIND(dow)
}

actual fun eUtc.platformMonth(): eDateTimePartGetter.MONTH_KIND = monthToMONTH_KIND(month)
actual fun eLocalDateTime.platformMonth(): eDateTimePartGetter.MONTH_KIND = monthToMONTH_KIND(month)

fun dayOfWeekToDAY_KIND(dow: dynamic): eDateTimePartGetter.DAY_KIND = when(dow){
    0 -> eDateTimePartGetter.DAY_KIND.SUN
    1 -> eDateTimePartGetter.DAY_KIND.MON
    2 -> eDateTimePartGetter.DAY_KIND.TUE
    3 -> eDateTimePartGetter.DAY_KIND.WED
    4 -> eDateTimePartGetter.DAY_KIND.THU
    5 -> eDateTimePartGetter.DAY_KIND.FRI
    else -> eDateTimePartGetter.DAY_KIND.SAT
}

fun monthToMONTH_KIND(m: Int): eDateTimePartGetter.MONTH_KIND = when(m){
    1 -> eDateTimePartGetter.MONTH_KIND.JAN
    2 -> eDateTimePartGetter.MONTH_KIND.FEB
    3 -> eDateTimePartGetter.MONTH_KIND.MAR
    4 -> eDateTimePartGetter.MONTH_KIND.APR
    5 -> eDateTimePartGetter.MONTH_KIND.MAY
    6 -> eDateTimePartGetter.MONTH_KIND.JUN
    7 -> eDateTimePartGetter.MONTH_KIND.JUL
    8 -> eDateTimePartGetter.MONTH_KIND.AUG
    9 -> eDateTimePartGetter.MONTH_KIND.SEP
    10 -> eDateTimePartGetter.MONTH_KIND.OCT
    11 -> eDateTimePartGetter.MONTH_KIND.NOV
    else -> eDateTimePartGetter.MONTH_KIND.DEC
}

actual fun eUtc.platformAmPmLocale(locale: String): String =
    if(hour < 12) "AM" else "PM"

actual fun eUtc.platformAmPm(): eDateTimePartGetter.AMPM_KIND =
    if(hour < 12) eDateTimePartGetter.AMPM_KIND.AM else eDateTimePartGetter.AMPM_KIND.PM

actual fun eLocalDateTime.platformAmPmLocale(locale: String): String =
    if(hour < 12) "AM" else "PM"

actual fun eLocalDateTime.platformAmPm(): eDateTimePartGetter.AMPM_KIND =
    if(hour < 12) eDateTimePartGetter.AMPM_KIND.AM else eDateTimePartGetter.AMPM_KIND.PM

actual fun eUtc.platformMonthLocale(locale: String): String = engMonthShortTitle[this.month-1]

actual fun eLocalDateTime.platformMonthLocale(locale: String): String = engMonthShortTitle[this.month-1]

private val engMonthShortTitle = arrayOf(
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
)
actual fun implLocalDateTimeMinusYears(date: eLocalDateTime, years: Long): eLocalDateTime {
    val jsDate = Date( date.year - years.toInt(),date.month-1,date.date,date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}

actual fun implLocalDateTimeMinusMonths(date: eLocalDateTime, months: Long): eLocalDateTime {
    val jsDate = Date( date.year,date.month-1-months.toInt(),date.date,date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}

actual fun implLocalDateTimeMinusDays(date: eLocalDateTime, days: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date-days.toInt(),date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimeMinusHours(date: eLocalDateTime, hours: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour-hours.toInt(),date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimeMinusMinutes(date: eLocalDateTime, minutes: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour,date.minute-minutes.toInt(),date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimeMinusSeconds(date: eLocalDateTime, seconds: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour,date.minute,date.second-seconds.toInt(),date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimeMinusMillis(date: eLocalDateTime, millis: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour,date.minute,date.second,date.millisecond-millis.toInt() )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimeMinusWeeks(date: eLocalDateTime, weeks: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date-weeks.toInt()*7,date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusYears(date: eLocalDateTime, years: Long): eLocalDateTime {
    val jsDate = Date( date.year+years.toInt(),date.month-1,date.date,date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusMonths(date: eLocalDateTime, months: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1+months.toInt(),date.date,date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusDays(date: eLocalDateTime, days: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date+days.toInt(),date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusHours(date: eLocalDateTime, hours: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour+hours.toInt(),date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusMinutes(date: eLocalDateTime, minutes: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour,date.minute+minutes.toInt(),date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusSeconds(date: eLocalDateTime, seconds: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour,date.minute,date.second+seconds.toInt(),date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusMillis(date: eLocalDateTime, millis: Long): eLocalDateTime  {
    val jsDate = Date( date.year,date.month-1,date.date,date.hour,date.minute,date.second,date.millisecond+millis.toInt())
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}
actual fun implLocalDateTimePlusWeeks(date: eLocalDateTime, weeks: Long): eLocalDateTime {
    val jsDate = Date( date.year,date.month-1,date.date+weeks.toInt()*7,date.hour,date.minute,date.second,date.millisecond )
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
    )
}

actual fun implZonedDateTimeMinusYears(date: eZonedDateTime, years: Long): eZonedDateTime {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(years,"year")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}

actual fun implZonedDateTimeMinusMonths(date: eZonedDateTime, months: Long): eZonedDateTime {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(months,"month")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}

actual fun implZonedDateTimeMinusDays(date: eZonedDateTime, days: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(days,"day")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimeMinusHours(date: eZonedDateTime, hours: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(hours,"hour")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimeMinusMinutes(date: eZonedDateTime, minutes: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(minutes,"minute")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimeMinusSeconds(date: eZonedDateTime, seconds: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(seconds,"second")
    return eZonedDateTime( eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()), date.zone
    )
}
actual fun implZonedDateTimeMinusMillis(date: eZonedDateTime, millis: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(millis,"millisecond")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimeMinusWeeks(date: eZonedDateTime, weeks: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).subtract(weeks,"week")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusYears(date: eZonedDateTime, years: Long): eZonedDateTime {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(years,"year")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusMonths(date: eZonedDateTime, months: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(months,"month")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusDays(date: eZonedDateTime, days: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(days,"day")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusHours(date: eZonedDateTime, hours: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(hours,"hour")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusMinutes(date: eZonedDateTime, minutes: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(minutes,"minute")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusSeconds(date: eZonedDateTime, seconds: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(seconds,"second")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusMillis(date: eZonedDateTime, millis: Long): eZonedDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(millis,"millisecond")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}
actual fun implZonedDateTimePlusWeeks(date: eZonedDateTime, weeks: Long): eZonedDateTime {
    val jsDate = einDayJs.dayjs.utc(date.toStringWithoutZone()).tz(date.zone, true).add(weeks,"week")
    return eZonedDateTime( eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    ), date.zone)
}

actual fun implLocalDateTimeMinusYears(date: eLocalDateTime, years: Long, zone:String): eLocalDateTime {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(years,"year")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int)
}

actual fun implLocalDateTimeMinusMonths(date: eLocalDateTime, months: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(months,"month")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int)
}
actual fun implLocalDateTimeMinusDays(date: eLocalDateTime, days: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(days,"day")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int)
}
actual fun implLocalDateTimeMinusHours(date: eLocalDateTime, hours: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(hours,"hour")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int)
}
actual fun implLocalDateTimeMinusMinutes(date: eLocalDateTime, minutes: Long, zone:String): eLocalDateTime {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(minutes, "minute")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}

actual fun implLocalDateTimeMinusSeconds(date: eLocalDateTime, seconds: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(seconds,"second")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimeMinusMillis(date: eLocalDateTime, millis: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(millis,"millisecond")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimeMinusWeeks(date: eLocalDateTime, weeks: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).subtract(weeks,"week")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimePlusYears(date: eLocalDateTime, years: Long, zone:String): eLocalDateTime {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(years,"year")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimePlusMonths(date: eLocalDateTime, months: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(months,"month")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimePlusDays(date: eLocalDateTime, days: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(days,"day")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimePlusHours(date: eLocalDateTime, hours: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(hours,"hour")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimePlusMinutes(date: eLocalDateTime, minutes: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(minutes,"minute")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimePlusSeconds(date: eLocalDateTime, seconds: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(seconds,"second")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implLocalDateTimePlusMillis(date: eLocalDateTime, millis: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(millis,"millisecond")
    return eLocalDateTime(
        jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
        jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds())
}
actual fun implLocalDateTimePlusWeeks(date: eLocalDateTime, weeks: Long, zone:String): eLocalDateTime  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, true).add(weeks,"week")
    return eLocalDateTime(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}

actual fun implUtcMinusYears(date: eUtc, years: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(years,"year").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcMinusMonths(date: eUtc, months: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(months,"month").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcMinusDays(date: eUtc, days: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(days,"day").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcMinusHours(date: eUtc, hours: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(hours,"hour").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcMinusMinutes(date: eUtc, minutes: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(minutes,"minute").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcMinusSeconds(date: eUtc, seconds: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(seconds,"second").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcMinusMillis(date: eUtc, millis: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(millis,"millisecond").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcMinusWeeks(date: eUtc, weeks: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).subtract(weeks,"week").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusYears(date: eUtc, years: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(years,"year").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusMonths(date: eUtc, months: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(months,"month").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusDays(date: eUtc, days: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(days,"day").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusHours(date: eUtc, hours: Long, zone:String): eUtc {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(hours,"hour").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusMinutes(date: eUtc, minutes: Long, zone:String): eUtc {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(minutes,"minute").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusSeconds(date: eUtc, seconds: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(seconds,"second").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusMillis(date: eUtc, millis: Long, zone:String): eUtc  {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(millis,"millisecond").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}
actual fun implUtcPlusWeeks(date: eUtc, weeks: Long, zone:String): eUtc {
    val jsDate = einDayJs.dayjs.utc(date.toString()).tz(zone, false).add(weeks,"week").utc()
    return eUtc(
        jsDate.year() as Int, jsDate.month() as Int + 1, jsDate.date() as Int,
        jsDate.hour() as Int, jsDate.minute() as Int, jsDate.second() as Int, jsDate.millisecond() as Int
    )
}

actual fun implZonedDateTimeDiff(interval: String, me: eZonedDateTime, other: eZonedDateTime): Long {
    val jsDate1 = einDayJs.dayjs.utc(me.toStringWithoutZone()).tz(me.zone,true)
    val jsDate2 = einDayJs.dayjs.utc(other.toStringWithoutZone()).tz(other.zone,true)
    // "y", "m", "d", "h", "i", "s", "ms"
    val s = try{
        jsDate2.diff(jsDate1, intervalToDayjsInterval(interval))
    }catch(e:Throwable){
        0
    }
    return "$s".toLong()
}

// "y", "m", "d", "h", "i", "s", "ms"
private inline fun intervalToDayjsInterval(interval: String) = when(interval) {
    "y"->"y"
    "m"->"M"
    "d"->"d"
    "h"->"h"
    "i"->"m"
    "s"->"s"
    "ms"->"ms"
    else -> throw Throwable("Invalid interval: ${interval}")
}
