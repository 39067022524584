package view

import app.ALink
import app.Factory
import app.eWindow
import ein2b.core.collection.split
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import kotlinx.browser.sessionStorage
import m42.app.M42App
import m42.domBase.PageDummy
import m42.model.EntDummyOff
import m42.model.EntDummyOn
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

object CompViewLnb{
    enum class K{
        logo, subTitle, languageArea, languageCountry,
        user, user_info, user_profile, user_role, user_title, user_subTitle, user_menuList,
        user_menu_item, user_menu_link, user_menu_icon, user_menu_title,
        menuList, menuWrap, menu_link, menu_titleWrap, menu_icon, menu_title, menu_urlLink, menu_foldBtn, menu_cnt, menu_info, menu_subList,
        menu_sub_itemWrap, menu_sub_item, menu_sub_icon, menu_sub_title, menu_sub_new, menu_sub_cnt, menu_sub_more, menu_sub_btn, menu_sub_subList;
        override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
    }
    // language=html
    private val viewFactory = Factory.html("""
    <aside class="menu">
        <div class="logo-wrap">        
            <a data-view="${K.logo}" class="alink"></a>
            <div data-view="${K.subTitle}" class="logo-sub-title ellipsis"></div>
        </div>
        <div data-view="${K.languageArea}"></div>
        <b data-view="${K.user}"></b>
        <ul data-view="${K.menuList}" class="menu-list scroll1"></ul>
    </aside>
    """)
    // language=html
    private val userFactory = Factory.html("""
    <div class="user">
        <div data-view="${K.user_info}" class="user-info flex-center">
            <div class="user-image flex-shrink-0">
                <div data-view="${K.user_profile}" class="user-profile dummy-image"></div>
                <div data-view="${K.user_role}" class="user-id dummy-image"></div>
            </div>
            <div class="margin-left8 flex-grow-1">
                <div data-view="${K.user_title}" class="user-title dummy-title ellipsis"></div>
                <div data-view="${K.user_subTitle}" class="user-sub-title dummy-title ellipsis"></div>
            </div>
            <div class="ic-menu margin-left8 flex-shrink-0"></div>
        </div>
        <div data-view="${K.user_menuList}" class="user-menu"></div>
    </div>
    """)
    // language=html
    private val userMenuFactory = Factory.html("""
    <li data-view="${K.user_menu_item}" class="user-menu-item">
        <a data-view="${K.user_menu_link}" class="alink flex-center">
            <div data-view="${K.user_menu_icon}" class="user-menu-icon margin-right8"></div>
            <div data-view="${K.user_menu_title}"></div>
        </a>
    </li>
    """)
    //subItem이 없는 경우
    // language=html
    private val oneMenuFactory = Factory.html("""
    <li data-view="${K.menuWrap}" class="menu-item">
        <a data-view="${K.menu_link}" class="alink flex-center">
            <div data-view="${K.menu_icon}" class="flex-shrink-0 menu-item-icon dummy-icon"></div>
            <div data-view="${K.menu_title}" class="flex-grow-1 margin-left8 menu-item-title dummy-title"></div>
            <div data-view="${K.menu_cnt}" class="flex-shrink-0 margin-left12 menu-item-cnt dummy-icon"></div>
        </a>
    </li>
    """)
    //subItem이 있는 경우
    // language=html
    private val menuFactory = Factory.html("""
    <li data-view="${K.menuWrap}" class="menu-item">
        <div data-view="${K.menu_titleWrap}" class="flex-between-center">
            <div data-view="${K.menu_title}" class="menu-item-title dummy-title"></div>
            <div class="flex flex-shrink-0">
                <div data-view="${K.menu_cnt}" class="sub-item-cnt margin-left12 flex-shrink-0 margin-right4"></div>
                <a data-view="${K.menu_urlLink}" class="alink menu-item-icon"></a>
                <div data-view="${K.menu_foldBtn}" class="sub-menu ic-menu"></div>
                <div data-view="${K.menu_icon}"></div>
            </div>
        </div>
        <div data-view="${K.menu_info}" class="menu-info dummy-title"></div>
        <ul data-view="${K.menu_subList}" class="sub-list"></ul>
    </li>
    """)
    //language=html
    var menuIconFactory = Factory.html("""<a data-view=""></div>""")
    // language=html
    private val menuSubFactory = Factory.html("""
        <li class="relative">
            <div data-view="${K.menu_sub_itemWrap}" class="flex-between-center sub-item">
                <a data-view="${K.menu_sub_item}" class="alink flex flex-grow-1">
                    <div data-view="${K.menu_sub_title}" class="dummy-title flex-grow-1"></div>
                    <div data-view="${K.menu_sub_new}" class="sub-item-new margin-left12 flex-shrink-0"></div>
                    <div data-view="${K.menu_sub_cnt}" class="sub-item-cnt margin-left12 flex-shrink-0"></div>
                    <div data-view="${K.menu_sub_more}" class="sub-item-more margin-left12 flex-shrink-0"></div>                    
                </a>
                <a data-view="${K.menu_sub_btn}" class="flex-shrink-0 alink menu-item-icon margin-left8 margin-right12"></a>
            </div>
            <div data-view="${K.menu_sub_subList}" class="sub-sub-list"></div>
        </li>
    """)
    // language=html
    private val subSubFactory = Factory.html("""
        <a data-view="${K.menu_sub_item}" class="sub-item flex">
            <div data-view="${K.menu_sub_icon}" class="flex-shrink-0 margin-right4"></div>
            <div class="dummy-title flex-grow-1">
                <div data-view="${K.menu_sub_title}" class="inline-block"></div>
            </div>
            <div data-view="${K.menu_sub_new}" class="sub-item-new margin-left12 flex-shrink-0"></div>
            <div data-view="${K.menu_sub_cnt}" class="sub-item-cnt margin-left12 flex-shrink-0"></div>
        </a>
    """)

    // language=html
    private val languageFactory = Factory.html("""<div><b data-view='${K.languageCountry}'></b></div>""")

    class MenuUser(val profileUrl:String, val roleUrl:String, val title:String, val subTitle:String){
        class UserMenu(val key:String, val title:String, val icon:String = "", val clickLink:ALink? = null, val clickBlock:(()->Unit) = {})
        var menuList = mutableListOf<UserMenu>()
    }
    class MenuItem(
        val title:String, val key:String = "",
        val urlIcon:String = "", val info:String = "",
        val menuWrapClassName:String = "menu-item",
        val foldable:Boolean = false, val displayTotal:Boolean = false,
        val clickBlock:(()->Unit)? = null,
        val link:ALink? = null
    ){
        class SubItem(val key:String, val title:String, val iconClassName:String = ""){
            var view:eView<HTMLElement>? = null
            var isSelect:Boolean = false
            var isNew:Boolean = false
            var cnt:String = ""
            var clickBlock:((Event)->Unit)? = null
            var link:ALink? = null
            var btnClassName = ""
            var btnLink:ALink? = null

            var isSubShow:Boolean = false
            var subList = mutableListOf<SubItem>()
            val isDisabled:Boolean get() = (clickBlock == null && link == null) && subList.isEmpty()
        }
        class Icon(val link:ALink, val className:String)
        var iconList = listOf<Icon>()
        var subList = mutableListOf<SubItem>()
        var unfolded = false
    }
    class OneMenuItem(val key:String, val iconInactive:String, val iconActive:String, val title:String, val link:ALink){
        var cnt:String = ""
        var isSelect:Boolean = false
    }
    class LanguageOption(val countryCode:String, val languageCode:String, val currency:String, val title:String, val imgUrl:String)
    var isMenuOneDepth = false
    var menuList = mutableListOf<MenuItem>()
    var oneMenuList = mutableListOf<OneMenuItem>()
    var isInit = true
    var menuUser:MenuUser? = null
    var logoLink:ALink? = null
    var languageView:eView<HTMLElement>? = null

    private var key:String = ""
    lateinit var target:eView<HTMLElement>
    private val compId = uuid("")
    suspend fun init(rootEl:HTMLElement, logo:String, isImage:Boolean = false, subTitle:String = "", logoClickBlock:(()->Unit)? = null){
        target = eView(viewFactory){
            it.sub(K.logo){ logoView->
                logoView.className = if(isImage){
                    logoView.image = logo
                    "alink dummy-title logo-image"
                }else{
                    logoView.html = logo
                    "alink dummy-title logo-title"
                }
                logoView.cursor = "default"
                logoLink?.also{ ll ->
                    logoView.cursor = "pointer"
                    logoView.href = ll.link
                }
            }
            it.sub(K.languageArea).displayNone()
            it.sub(K.subTitle).displayNone()
            it.sub(K.menuList)
            it.sub(K.user, userFactory){ user ->
                M42App.emptySub(user, K.user_info, K.user_profile, K.user_role, K.user_title, K.user_subTitle, K.user_menuList)
            }
        }
        target.setTemplate()
        PageDummy.invoke(target, "menu")
        rootEl.appendChild(target.template!!)
    }
    suspend fun addHook(k:String){
        key = k.split("?")[0]
        setUser()
        if(isMenuOneDepth) setOneDepthList() else setList()
    }
    suspend fun setLogoImage(logo:String){
        target.sub(K.logo).image = M42App.cdata(logo)
    }
    suspend fun setSubTitle(subTitle: String){
        target.sub(K.subTitle){ st ->
            st.displayBlock()
            st.html = subTitle
        }
    }
    suspend fun setUserHide(){
        target.sub(K.user).displayNone()
        target.sub(K.menuList).top = "114px"
    }
    suspend fun setLogoLink() {
        logoLink?.also{ ll ->
            target.sub(K.logo).cursor = "pointer"
            target.sub(K.logo).href = ll.link
        }
    }
    suspend fun setLanguage(listClass:String = "margin-top30", block:suspend (eView<HTMLElement>, Any)->Unit){
        target.sub(K.languageArea) { la ->
            la.displayBlock()
            la.setClearList { lal ->
                lal += eView(languageFactory){
                    block(it, K.languageCountry)
                    languageView = it.sub(K.languageCountry)
                }
            }
        }
        target.sub(K.menuList) {
            it.className_ = listClass
        }
    }
    suspend fun setUser(){
        eWindow.addClick(compId){ eLaunch{ menuListClose() } }
        menuUser?.also{ userData ->
            target.sub(K.user){ user->
                val menuList = user.sub(K.user_menuList)
                user.sub(K.user_info){
                    it.selected = false
                    it.className = "user-info flex-center"
                    it.click = {e,_->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        it.selected = it.selected != true
                        if(it.selected == true){
                            it.className = "user-info flex-center active"
                            menuList.displayBlock()
                        }else{
                            it.className = "user-info flex-center"
                            menuList.displayNone()
                        }
                    }
                }
                user.sub(K.user_profile){ it.lazyBackgroundImage = "" to userData.profileUrl}
                user.sub(K.user_role){
                    it.lazyBackgroundImage = "" to userData.roleUrl
                    if(userData.roleUrl.isBlank()) it.displayNone() else it.displayBlock()
                }
                user.sub(K.user_title){ it.html = userData.title}
                user.sub(K.user_subTitle){ it.html = userData.subTitle }

                menuList.setClearList{ vList ->
                    userData.menuList.forEach{ userMenuData ->
                        vList += eView(userMenuFactory){ menu ->
                            userMenuData.clickLink?.also{
                                menu.sub(K.user_menu_item)
                                menu.sub(K.user_menu_link).href = it.link
                            } ?:also {
                                menu.sub(K.user_menu_item).click = { e,_->
                                    e.stopPropagation()
                                    e.stopImmediatePropagation()
                                    eLaunch{
                                        menuListClose()
                                        userMenuData.clickBlock()
                                    }
                                }
                                menu.sub(K.user_menu_link)
                            }
                            menu.sub(K.user_menu_icon){
                                if(userMenuData.icon.isNotBlank()){
                                    it.displayBlock()
                                    it.image = userMenuData.icon
                                }else{
                                    it.displayNone()
                                }
                            }
                            menu.sub(K.user_menu_title).html = userMenuData.title
                        }
                    }
                }
            }
        }
    }
    private suspend fun menuListClose(){
        target.sub(K.user){ user ->
            user.sub(K.user_info){
                it.selected = false
                it.className = "user-info flex-center"
            }
            user.sub(K.user_menuList).displayNone()
        }
    }
    private var unfoldList = mutableListOf<String>()

    /** 1 뎁스인 LNB 메뉴 인 경우 이걸 사용할 것 */
    suspend fun setOneDepthList(){
        target.sub(K.menuList).setClearList{ mutableListList->
            oneMenuList.forEach{ menuData ->
                menuData.isSelect = key == menuData.key
                mutableListList += eView(oneMenuFactory){ menuView ->
                    menuView.sub(K.menuWrap).className = "menu-item${if(menuData.isSelect) " active" else ""}"
                    menuView.sub(K.menu_link){ it.href = menuData.link.link }
                    menuView.sub(K.menu_icon).image = if(menuData.isSelect) menuData.iconActive else menuData.iconInactive
                    menuView.sub(K.menu_title).html = menuData.title
                    menuView.sub(K.menu_cnt).html = menuData.cnt
                }
            }
        }
    }
    private fun subItemWrapClass(data:MenuItem.SubItem) =
        "flex-between-center sub-item${if(data.isDisabled) " disabled" else if(data.isSelect) " active" else ""}${if(data.isSubShow) " sub-open" else ""}"
    suspend fun setList(){
        sessionStorage.setItem("lnbFold", unfoldList.joinToString(","))
        sessionStorage.getItem("lnbFold")?.also {
            unfoldList = it.split(",").toMutableList()
        }
        menuList.forEach { menuData ->
            val total = menuData.subList.sumOf { it.cnt.toIntOrNull()?:0 }
            if(total != 0 && isInit && menuData.foldable) {
                unfoldList.add(menuData.key)
            }
        }
        unfoldList = unfoldList.distinct().toMutableList()

//        menuList.forEach { menuData ->
//            val total = menuData.subList.sumOf { it.cnt.toIntOrNull()?:0 }
//            if(total != 0 && menuData.foldable) {
//                unfoldList.add(menuData.key)
//                menuData.unfolded = true
//            } else {
//                unfoldList.remove(menuData.key)
//                menuData.unfolded = false
//            }
//        }
//        unfoldList = unfoldList.distinct().toMutableList()
//        sessionStorage.setItem("lnbFold", unfoldList.joinToString(","))

        target.sub(K.menuList).setClearList{ mutableListList->
            menuList.forEach{ menuData ->
                if(menuData.foldable) {
                    menuData.unfolded = unfoldList.contains(menuData.key)
                } else {
                    menuData.unfolded = true
                }
                mutableListList += eView(menuFactory) { menuView ->
                    menuView.sub(K.menuWrap).className = menuData.menuWrapClassName
                    menuView.sub(K.menu_titleWrap){
                        if(menuData.title.isEmpty()) it.displayNone() else it.displayFlex()
                        if(menuData.foldable) {
                            it.className = "flex-between-center cursor"
                            it.click = { _,_->
//                                menuData.unfolded = !menuData.unfolded
                                if(menuData.unfolded) {
                                    unfoldList.remove(menuData.key)
                                } else {
                                    unfoldList.add(menuData.key)
                                }
                                unfoldList = unfoldList.distinct().toMutableList()

                                sessionStorage.setItem("lnbFold", unfoldList.joinToString(","))
                                isInit = false
                                eLaunch {
                                    setList()
                                }
                            }
                        } else {
                            it.className = "flex-between-center"
                        }
                    }
                    menuView.sub(K.menu_title).html = menuData.title
                    menuView.sub(K.menu_cnt) {
                        if(menuData.displayTotal) {
                            val total = menuData.subList.sumOf { it.cnt.toIntOrNull()?:0 }
                            if(total == 0) {
                                it.displayNone()
                            } else {
                                it.displayInlineBlock()
                                it.html = total.toString()
                            }
                        }
                        else it.displayNone()
                    }
                    menuView.sub(K.menu_urlLink){ urlLinkView ->
                        urlLinkView.displayNone()
                        menuData.clickBlock?.also{
                            urlLinkView.displayBlock()
                            urlLinkView.click = {_,_ -> it() }
                            urlLinkView.lazyBackgroundImage = "" to menuData.urlIcon
                        } ?:also{
                            urlLinkView.click = {_,_ ->}
                        }
                        menuData.link?.also{
                            urlLinkView.displayBlock()
                            urlLinkView.lazyBackgroundImage = "" to menuData.urlIcon
                            urlLinkView.href = it.link
                        }
                    }
                    menuView.sub(K.menu_foldBtn) {
                        if(menuData.foldable) {
                            it.displayBlock()
                            it.className = if(menuData.unfolded) "ic-fold" else "ic-unfold"
                        } else {
                            it.displayNone()
                        }
                    }
                    menuView.sub(K.menu_info){
                        if(menuData.info.isBlank()) it.displayNone() else it.displayBlock()
                        it.html = menuData.info
                    }
                    menuView.sub(K.menu_icon){ iconView ->
                        iconView.displayNone()
                        if(menuData.iconList.isNotEmpty()){
                            iconView.displayFlex()
                            iconView.className = "flex-center"
                            iconView.setClearList{ iconViewList ->
                                menuData.iconList.forEachIndexed{ idx, iconData ->
                                    iconViewList += eView(menuIconFactory){
                                        it.className = "alink margin-left${if(idx == 0) 8 else 4} menu-item-icon ${iconData.className}"
                                        it.href = iconData.link.link
                                        it.displayInlineBlock()
                                    }
                                }
                            }
                        }
                    }
                    menuView.sub(K.menu_subList){ subListView->
                        if(menuData.unfolded) subListView.displayBlock() else subListView.displayNone()
                        subListView.setClearList{ subListList->
                            menuData.subList.forEach{ subData->
                                /** subSub 메뉴 리스트가 있을 경우 그 중 하나라도 선택되었다면 */
                                subData.subList.forEach{ ssData-> ssData.isSelect = ssData.key == key }
                                subData.isSelect = if(subData.subList.isNotEmpty()){
                                    subData.subList.any{ it.isSelect }
                                }else{
                                    /** key값이 일치할 경우 선택으로 설정 */
                                    subData.key == key
                                }
                                subData.isSubShow = subData.isSelect && subData.subList.isNotEmpty()

                                subListList += eView(menuSubFactory){ subView->
                                    subData.view = subView
                                    val itemWrapView = subView.sub(K.menu_sub_itemWrap){
                                        it.className = subItemWrapClass(subData)
                                    }
                                    subView.sub(K.menu_sub_item){ itemView->
                                        if(!subData.isDisabled){
                                            if(subData.subList.isEmpty()){
                                                subData.link?.also{ itemView.href = it.link }
                                                subData.clickBlock?.also{ itemView.click = { e,_ -> it(e) } }
                                            }else{
                                                itemView.click = { _,_ ->
                                                    subData.isSubShow = !subData.isSubShow
                                                    itemWrapView.className = subItemWrapClass(subData)
                                                    eLaunch{ subListShowHide(subView, subData) }
                                                }
                                            }
                                        }
                                    }
                                    subView.sub(K.menu_sub_title).html = subData.title
                                    subView.sub(K.menu_sub_new){
                                        if(subData.isNew) it.displayInlineBlock() else it.displayNone()
                                    }
                                    subView.sub(K.menu_sub_cnt){
                                        it.html = subData.cnt
                                        if(subData.cnt.isNotBlank()) it.displayInlineBlock() else it.displayNone()
                                    }
                                    subListShowHide(subView, subData)
                                    subView.sub(K.menu_sub_btn){
                                        it.className = "flex-shrink-0 alink menu-item-icon margin-left8 margin-right12${if(subData.btnClassName.isNotBlank()) " ${subData.btnClassName}" else ""}"
                                        if(subData.btnLink == null) it.displayNone() else{
                                            it.href = subData.btnLink!!.link
                                            it.displayInlineBlock()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    private suspend fun subListShowHide(view:eView<HTMLElement>, data:MenuItem.SubItem){
        view.sub(K.menu_sub_more){
            if(data.subList.isNotEmpty()) it.displayInlineBlock() else it.displayNone()
        }
        view.sub(K.menu_sub_subList){ listView->
            if(data.isSubShow) listView.displayBlock() else listView.displayNone()
            listView.setClearList{ subListList->
                data.subList.forEach{ subData->
                    subListList += eView(subSubFactory){ subView->
                        subView.sub(K.menu_sub_item){
                            it.disabled = subData.clickBlock == null && subData.link == null
                            it.className = "flex alink sub-sub-item${if(it.disabled == true) " disabled" else if(subData.isSelect) " active" else ""}"
                            if(it.disabled == false){
                                it.click = { e,_ -> subData.clickBlock?.invoke(e) }
                                subData.link?.also{ l ->
                                    it.href = l.link
                                    if(subData.isSelect) {
                                        it.click = { _,_-> M42App.goUrl(l.key, *l.hashArgs) }
                                    }
                                }
                            }
                        }
                        subView.sub(K.menu_sub_icon){
                            if(subData.iconClassName.isBlank()) it.displayNone() else it.displayBlock()
                            it.className = "flex-shrink-0 margin-right4 ${subData.iconClassName}"
                        }
                        subView.sub(K.menu_sub_title).html = subData.title
                        subView.sub(K.menu_sub_new){
                            if(subData.isNew) it.displayInlineBlock() else it.displayNone()
                        }
                        subView.sub(K.menu_sub_cnt){
                            it.html = subData.cnt
                            if(subData.cnt.isNotBlank()) it.displayInlineBlock() else it.displayNone()
                        }
                    }
                }
            }
        }
    }
    suspend fun dummyOn() = target.entity(EntDummyOn)
    suspend fun dummyOff() = target.entity(EntDummyOff)

    fun displayNone() = target.displayNone()
    fun displayBlock() = target.displayBlock()
}