package m42.member.model

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.EnumRowid


//회사 종류
class MdlM42MemberCoCat: eEntity(){
    var memberCoCatRowid by int()
    var coKey by string()
}

//인가 종류
class MdlM42MemberAuthzCat:eEntity(){
    var memberAuthzCatRowid by int()
    var coKey by string()
    var authzKey by string()
    var title by string()
    var isActive by boolean()
}

//계정 상태
enum class EnumM42MemberAcCat(val title:String): EnumRowid<Int> {
    REQUEST("가입요청"){
        override val rowid = 10
    },
    ACTIVE("가입(활동중)"){
        override val rowid = 20
    },
    WITHDRAW("탈퇴"){
        override val rowid = 30
    },
    REQUESTWITHDRAW("탈퇴 요청"){
        override val rowid = 31
    },
    DORMANT("휴면"){
        override val rowid = 40
    };
    companion object{
        operator fun invoke(rowid:Int) = values().find { it.rowid == rowid }?:throw Throwable("찾을 수 없는 계정상태입니다. rowid=$rowid")
    }
}

//인증 종류
enum class EnumM42MemberAuthnCat(val title:String): EnumRowid<Int> {
    EMAIL("이메일"){
        override val rowid = 1
    },
    PHONE("전화번호"){
        override val rowid = 2
    }
}