package ein2b.core.view.router

import ein2b.core.view.displayBlock
import ein2b.core.view.displayNone
import ein2b.core.view.eView
import ein2b.core.view.opacity
import org.w3c.dom.HTMLElement

abstract class eDomPage(private val factory:suspend ()->eView<HTMLElement>, router:eRouter<HTMLElement>):ePage<HTMLElement>(router){
    override suspend fun initView() = factory()
    protected open fun prepareAdding(){}
    protected abstract suspend fun adding(data:eRouterData)
    protected abstract suspend fun restoring(data:eRouterData)
    protected open suspend fun removing(){}
    override suspend fun select(){
        super.select()
    }
    override suspend fun deselect(){
        super.deselect()
    }

    override suspend fun added(data:eRouterData){
        prepareAdding()
        adding(data)
    }
    override suspend fun restored(data:eRouterData){
        view?.displayBlock()
        restoring(data)
    }
    override suspend fun removed(isTop:Boolean){
        view?.displayNone()
        removing()
    }
    override suspend fun hided(){
        view?.displayNone()
        view?.opacity = 0.0
    }
    override suspend fun shown(data:eRouterData){
        view?.displayBlock()
        view?.opacity = 1.0
    }
    override suspend fun represent(data:eRouterData){
        //기본적으로 아무것도 안함. 필요하면 override
    }
}