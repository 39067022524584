package comp.input

import comp.OutType
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

abstract class CompInputSingle<V>:CompInput<String, V, V>{
    companion object{
        private const val ERROR_CLASS:String = "error"
        private const val SELECTED_CLASS:String = "selected"
        private const val DISABLED_CLASS:String = "disabled"
        var inputFocus:()->Unit = {}
        var inputBlur:()->Unit = {}
    }
    override val outs:HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value })
    override lateinit var value:CompValue<String, V>
    var initValue = ""
    final override var errorListener:((Boolean, String)->Unit)? = null
    final override var vali:eVali? = null
    override var placeholder = ""
    override var tabIndex = -2
    final override suspend fun init(it:eView<HTMLElement>){
        target = it.sub(subKey){
            it.value = ""
            it.className = setClassName("")
            it.placeholder = placeholder
            it.disabled = isDisabled
            it.focus = { _,_-> if(it.disabled == false) eLaunch{
                focus()
                inputFocus()
            } }
            it.blur = { _,_-> if(it.disabled == false) eLaunch{
                blur()
                inputBlur()
            } }
            it.keyup = { e,el->
                val ev = eEvent(e, el)
                changedValue(ev.value, true)
                if(ev.keycode() == 13) eLaunch{ enterEvent?.invoke(this) }
                keyUpBlock?.invoke(ev.value)
                keyUpEvent?.invoke(e)
            }
            it.keypress = { e,el->
                keyPressEvent?.invoke(e, el)
            }
            it.keydown = { e, el ->
                CompInput.keyDownEvent(maxLength, e, el)
                keyDownBlock?.invoke(eEvent(e,el).value)
                keyDownEvent?.invoke(e)
            }
            it.change = { e,el->
                val v = eEvent(e, el).value.trim()
                changedValue(changeBlock?.invoke(v) ?: v)
            }
            if(tabIndex > -2) it.attr("tabindex", tabIndex)
        }
        afterTargetInited?.invoke()
        if(initValue.isNotBlank()) value.inputValue(initValue)
        afterInited?.also{ window.requestAnimationFrame{ eLaunch{ it() } } }
    }
    fun inputInit(v:String){
        target.value = v
        value.inputValue(v)
    }
    final override suspend fun error(isOk:Boolean){
        value.isOk = isOk
        target.className = setClassName(if(isOk){ if(isFocus) SELECTED_CLASS else "" } else ERROR_CLASS)
    }
    final override suspend fun clear(){
        error(true)
        enable(true)
        value.inputValue(initValue)
    }

    abstract var subKey:String
    protected lateinit var target:eView<HTMLElement>
    protected var afterTargetInited:(()->Unit)? = null
    protected var isKeyUpEvent:Boolean = false
    private var isFocus = false
    var inputDefaultClass:String = "form-input"
    var maxLength:Int = -1
    var enterEvent:(suspend (CompInputSingle<V>)->Unit)? = null
    var isChangeCheck = false
    var focusBlock:(()->Unit)? = null
    var blurBlock:(()->Unit)? = null
    var changeBlock:((String)->String)? = null
    var keyDownBlock:((String)->Unit)? = null
    var keyDownEvent:((Event)->Unit)? = null
    var keyUpBlock:((String)->Unit)? = null
    var keyUpEvent:((Event)->Unit)? = null
    var keyPressEvent:((Event, HTMLElement)->Unit)? = null
    var elValue:String = ""
    var isDisabled = false
    var inputClass:String = ""
    var afterInited:(suspend ()->Unit)? = null
    protected open fun changedValue(v:String, isViewOnly:Boolean = false){
        elValue = v
        target.value = if(isViewOnly) eViewOnly(v) else v
        value.inputValue(v)
        if(isChangeCheck) eLaunch{ value.check() }
    }
    private fun focus(){
        isFocus = true
        target.className = setClassName(if(value.isOk) SELECTED_CLASS else ERROR_CLASS)
        focusBlock?.invoke()
    }
    private fun blur(){
        isFocus = false
        target.className = setClassName(if(value.isOk) "" else ERROR_CLASS)
        blurBlock?.invoke()
    }
    fun enable(v:Boolean){
        isDisabled = !v
        target.disabled = isDisabled
        if(isFocus) focus() else blur()
    }
    fun setPlaceholder(v:String){
        target.placeholder = v
    }
    fun runFocus(){
        target.runFocus = false
        target.runFocus = true
    }
    fun targetStyle(vararg attrs:Pair<String, Any>){
        attrs.forEach{ (k,v)-> target.attr(k, v) }
    }
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayFlex() = target.displayFlex()
    fun placeholder(v:String){ target.placeholder = v }
    private fun setClassName(cls:String):String{
        var r = inputDefaultClass
        if(inputClass.isNotBlank()) r += " $inputClass"
        if(isDisabled) r += " $DISABLED_CLASS"
        if(cls.isNotBlank()) r += " $cls"
        return r
    }
}