package bxlt.api.member

import ein2b.core.entity.eEntity
import bxlt.api.API_MEMBER_PATH
import bxlt.api.EntBxltApi
import m42.common.enum.EnumApiAccessAllow

//RSA
object EntApiMemberRsa: EntBxltApi<EntApiMemberRsa.Req, EntApiMemberRsa.Res>(
    Res::class,
    EntApiMemberRsa::Req,
    EntApiMemberRsa::Res
){
    const val PATH = "$API_MEMBER_PATH/rsa"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGOUT
    class Req:eEntity()
    class Res:eEntity(true){
        class RSA:eEntity(true){
            companion object{
                val DEFAULT = Immutable(RSA())
            }
            var id by string{ default("") }
            var key by string{ default("") }
            var ip by string{ default("") }
        }
        var rsa by entity(Res::RSA){ default(RSA.DEFAULT) }
    }
}
object EntApiMemberLoginEmail: EntBxltApi<EntApiMemberLoginEmail.Req, EntApiMemberLoginEmail.Res>(
    Res::class,
    EntApiMemberLoginEmail::Req,
    EntApiMemberLoginEmail::Res
){
    const val PATH = "$API_MEMBER_PATH/login/email"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGOUT
//    override var isMenu = true
    class Req:eEntity(){
        var rsaId by string()
        var email by string()
        var pw by string() //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음
    }
    class Res:eEntity(true){
        class Error:eEntity(true){
            var emailErrMsg by string{ default("") } // - 이메일:존재하지 않음 ->  가입되지 않은 아이디입니다.
            var pwErrMsg by string{ default("") } // - 비밀번호:일치하지 않음 -> 비밀번호가 일치하지 않습니다.
        }
        var error by entity(Res::Error){ encoding.isOptional() }
        val isError:Boolean get() = props["error"] != null
    }
}
object EntApiMemberLoginCheck: EntBxltApi<EntApiMemberLoginCheck.Req, EntApiMemberLoginCheck.Res>(
    Res::class,
    EntApiMemberLoginCheck::Req,
    EntApiMemberLoginCheck::Res
){
    const val PATH = "$API_MEMBER_PATH/logincheck"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val midIgnore = true
    override val accessAllow = EnumApiAccessAllow.LOGOUT
//    override var isMenu = true
    class Req:eEntity()
    class Res:eEntity()
}
//로그아웃
object EntApiMemberLogout: EntBxltApi<EntApiMemberLogout.Req, EntApiMemberLogout.Res>(
    Res::class,
    EntApiMemberLogout::Req,
    EntApiMemberLogout::Res
){
    const val PATH = "$API_MEMBER_PATH/logout"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val midIgnore = true
    override val accessAllow = EnumApiAccessAllow.LOGOUT
    class Req:eEntity()
    class Res:eEntity()
}

/**
 * 회원 세션 체크
 */
object EntApiMemberSessionCheck:
    EntBxltApi<EntApiMemberSessionCheck.Req, EntApiMemberSessionCheck.Res>(
        Res::class,
        EntApiMemberSessionCheck::Req,
        EntApiMemberSessionCheck::Res
    ){
    const val PATH = "$API_MEMBER_PATH/sessioncheck"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGIN
    class Req:eEntity()
    class Res:eEntity()
}

/*
* 이메일에서 보내준 토큰으로 로그인 후 페이지 이동
* */
object EntApiMemberActionToken:
    EntBxltApi<EntApiMemberActionToken.Req, EntApiMemberActionToken.Res>(
        Res::class,
        EntApiMemberActionToken::Req,
        EntApiMemberActionToken::Res
    ){
    const val PATH = "$API_MEMBER_PATH/action/token"
    const val PATH_JSON = "$PATH/json"
    override val path = PATH
    override val jsonPath = PATH_JSON
    override val accessAllow = EnumApiAccessAllow.LOGOUT
    class Req:eEntity(){
        var actionToken by string
    }
    class Res:eEntity(true){
        var url by string
    }
}