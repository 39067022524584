package ein2b.core.entity.indexer

import ein2b.core.entity.Error
import ein2b.core.entity.eEntity
import kotlin.reflect.KClass

object Indexer{
    private val indexes:HashMap<KClass<out eEntity>, HashMap<String, Int>> = hashMapOf()

    fun set(entity: eEntity, name:String, i: Int){
        val index = entity::class.let{type->
            indexes[type] ?: hashMapOf<String, Int>().also{ indexes[type] = it }
        }
        if(name !in index) index[name] = i
    }
    fun get(entity: KClass<out eEntity>, name:String):Int = indexes[entity]?.get(name) ?: throw Error(eEntity.ERROR.index_error, "no index:${entity.simpleName}.$name")
    fun getOrNull(entity: KClass<out eEntity>, name:String):Int? = indexes[entity]?.get(name)
}