package router.home

import app.Factory
import ein2b.core.view.displayBlock
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import router.subView.subHome

private val factory = Factory.htmlUrl("home")
private enum class K{ home }
suspend fun HomeView() = eView(factory){ staticView->
    subHome(staticView, K.home)
    staticView.addEntityHook(HcInit::class, object: eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            view.sub(K.home).displayBlock()
        }
    })
}