package comp

import app.Factory
import comp.input.*
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

suspend fun <OUT> eView<HTMLElement>.compInputSectionOneSet(
    subKey:Any,
    input:CompInput<*,*,*>,
    wrapperClass:String = "", errorClass:String = "", inputClass:String = "", isOneError: Boolean = false
):CompInputSection<OUT>{
    return CompInputSection(sub(subKey, CompInputSection.factory, null), wrapperClass, errorClass, inputClass, isOneError){ listOf(input) }
}
suspend fun <OUT> eView<HTMLElement>.compInputSectionSet(
    subKey:Any,
    wrapperClass:String = "", errorClass:String = "", inputClass:String = "", isOneError: Boolean = false,
    input:()->List<CompInput<*,*,*>>
):CompInputSection<OUT>{
    return CompInputSection(sub(subKey, CompInputSection.factory, null), wrapperClass, errorClass, inputClass, isOneError, input)
}
class CompInputSection <OUT>(private val view:eView<HTMLElement>):Comp, CompValueOut<OUT>{
    companion object{
        private const val WRAPPER = "CompInputSection_wrapper"
        private const val INPUT = "CompInputSection_inputs"
        private const val ERROR = "CompInputSection_error"
        internal val factory = Factory.html("""
<div data-view="$WRAPPER">
    <div data-view="$INPUT"></div>
    <div data-view="$ERROR"></div>
</div>""")
        internal suspend operator fun <OUT> invoke(
            view:eView<HTMLElement>,
            wrapperClass:String = "", errorClass:String = "", inputClass:String = "", isOneError:Boolean = false,
            input:()->List<CompInput<*,*,*>>
        ):CompInputSection<OUT>{
            val comp = CompInputSection<OUT>(view)
            comp.isOneError = isOneError
            view.sub(WRAPPER).className = wrapperClass

            comp.errors = mutableListOf()
            comp.inputs = input()
            view.sub(INPUT){
                it.className = inputClass
                it.setClearList{ list->
                    list += comp.inputs.mapIndexed{ idx, compInput ->
                        comp.checkList.add(true to "")
                        comp.errors.add(CompError("form-error"))
                        compInput.errorListener = { ok, msg->
                            eLaunch{
                                compInput.error(ok)
                                if(comp.isOneError){
                                    comp.checkList[idx] = ok to msg
                                    if(comp.checkList.any{ !it.first }){
                                        comp.checkList.find{ !it.first }?.also{ comp.errors.first().update(ok, it.second) }
                                        //if(!ok) comp.errors.first().update(ok, msg)
                                    }else{
                                        comp.errors.first().update(ok, msg)
                                    }
                                }else comp.errors[idx].update(ok, msg)
                            }
                        }
                        compInput.view().also{ v-> comp.inputViews += v }
                    }
                }
            }
            view.sub(ERROR){
                it.className = errorClass
                it.setClearList{ list->
                    list += comp.errors.map{ it.view() }
                }
            }
            comp.baseProp(view, true)
            return comp
        }
    }
    @Suppress("UNCHECKED_CAST")
    override val outs:HashMap<OutType, suspend () -> OUT> = hashMapOf(OutType.DEFAULT to{ values().first() as OUT })

    private lateinit var inputs:List<CompInput<*,*,*>>
    private lateinit var errors:MutableList<CompError>
    private val inputViews:MutableList<eView<HTMLElement>> = mutableListOf()
    private var checkList = mutableListOf<Pair<Boolean, String>>()
    var isOneError = false

    override suspend fun clear(){
        inputs.forEach{ it.clear() }
        errors.forEach{ it.clear() }
    }

    suspend fun changeError(ok:Boolean, msg:String){
        inputs.forEach{ it.error(ok) }
        if(isOneError){
            errors.forEach{ it.update(true, "") }
            errors[0].update(ok, msg)
        }else errors.forEach{ it.update(ok, msg) }
    }
    private var isCheck = false
    override fun check():Boolean{
        isCheck = true
        inputs.forEach{ if(!it.value.check()) isCheck = false }
        return isCheck
    }
    suspend fun values():List<Any> = if(isCheck) inputs.map{ it.out() ?: "" } else listOf()
    suspend fun displayInlineBlock(){
        view.sub(WRAPPER).displayInlineBlock()
    }
    suspend fun displayBlock(){
        view.sub(WRAPPER).displayBlock()
    }
    suspend fun displayNone(){
        view.sub(WRAPPER).displayNone()
        clear()
    }
    suspend fun displayFlex(){
        view.sub(WRAPPER).displayFlex()
    }
    fun inputView(idx:Int = 0):eView<HTMLElement> = inputViews[idx]
}