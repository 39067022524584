package ein2b.core.view.router

import ein2b.core.core.*
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.js.js.isTruthy
import ein2b.js.js.obj
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

class eHashManager(
    defaultUrl:String,
    afterRouteHook:((String)->Unit)? = null,
    routeKeyBlock:((String)->String)? = null,
    private val fakeUrlPrefix:String = "__"
):eMananger<HTMLElement>(defaultUrl, afterRouteHook, routeKeyBlock){
    suspend fun init(){
        window.addEventListener("hashchange", {
            eLaunch { onHashChange() }
        })
        isInited = true
        onHashChange()
    }
    private suspend fun onHashChange(){route(getPath())}
    private fun getPath(v:String? = null):String{
        var hash = v?: window.location.hash
        hash = if(hash.isBlank() || hash == "#") defaultUrl else hash
        hash = hash.replace("#", "")
        val k = eParseUrl(hash).first
        val path = if(
            hash.startsWith(fakeUrlPrefix) &&
            isTruthy(window.history.state) &&
            window.history.state?.asDynamic().key != ""
        ) window.history.state.asDynamic().key as String
        else if(routers.any{k in it}) hash
        else defaultUrl
        return path
    }
    override suspend fun beforeRoute(url: String) {}
    override suspend fun goProcess(path: String) {
        window.location.hash = if(path[0] == '#') path else "#$path"
    }
    override suspend fun reloadProcess() {
        window.location.reload()
    }
    override suspend fun backProcess() {
        window.history.back()
    }
    @Suppress("UnsafeCastFromDynamic")
    fun goFakeUrl(k:String, vararg arg:Pair<String, Any>){
        if(!isInited) err("before inited")
        val path = if(arg.isEmpty()) k else "${k}?${eEncodeQueryString(*arg)}"
        eLaunch{
            prepare(k)
            prepared?.let{
                val url = "#$fakeUrlPrefix${uuid()}"
                window.history.pushState(obj{
                    key = path
                }, "", url)
                onHashChange()
            }
        }
    }
}