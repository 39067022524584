package app

import ein2b.core.core.eDecodeQueryString
import ein2b.core.core.err
import ein2b.core.view.router.eDomBase
import ein2b.js.browser.eSessionStorage
import kotlinx.browser.document
import m42.app.M42App
import org.w3c.dom.HTMLElement

object CompRouter{
    var maxWidth = 1440
    var minWidth = 800
    var menuWidth = 200
    val menuRouterEl = document.querySelector("#menuRouter") as? HTMLElement ?: err("invalid #menuRouter")
    val staticRouterEl = document.querySelector("#staticRouter") as? HTMLElement ?: err("invalid #staticRouter")
    val baseRouterEl = document.querySelector("#baseRouter") as? HTMLElement ?: err("invalid #baseRouter")
    val uiRouterEl = document.querySelector("#uiRouter") as? HTMLElement ?: err("invalid #uiRouter")
    val modalEL = document.querySelector("#modal") as? HTMLElement
    val viewEL = document.querySelector("#view") as? HTMLElement ?: err("invalid #view")

    class CompRouter(el:HTMLElement):eDomBase(el){
        override suspend fun select(){
            menuRouterEl.style.display = "block"
            staticRouterEl.style.display = "none"
            baseRouterEl.style.display = "none"
            uiRouterEl.style.display = "none"
            if(maxWidth > -1) el.style.maxWidth = "calc(${maxWidth}px - ${menuWidth}px - 34px)"
            el.style.minWidth = "calc(${minWidth}px - ${menuWidth}px - 34px)"
            el.style.display = "block"
        }
        override suspend fun deselect(){
            el.style.display = "none"
        }
    }
    var staticRouter:()->eDomBase = { CompRouter(staticRouterEl) }
    var baseRouter:()->eDomBase = { CompRouter(baseRouterEl) }
    var uiRouter = object:eDomBase(uiRouterEl){
        override suspend fun select(){
            menuRouterEl.style.display = "none"
            staticRouterEl.style.display = "none"
            baseRouterEl.style.display = "none"
            uiRouterEl.style.display = "none"
            el.style.display = "block"
        }
        override suspend fun deselect(){
            el.style.display = "none"
        }
    }
    fun setStaticRouterMinWidth(v:Int){
        staticRouterEl.style.minWidth = "${v}px"
    }
    fun setBaseRouterMinWidth(v:Int){
        baseRouterEl.style.minWidth = "${v}px"
    }

    fun routerKey(key:String):String = key.split("?").first()
    fun backHash(k:String, v:String? = null, vararg args:Pair<String, Any>){
        v?.also{
            eSessionStorage[k] = it
        } ?:also {
            val arg = eSessionStorage[k]?.let{
                eDecodeQueryString(it).toList().toTypedArray()
            } ?: args
            M42App.hashManager.goUrl(k, *arg)
        }
    }
    var backHashLinkMap = mapOf<String, List<String>>()
    fun backLinkFromHash(key:String){
        val routerKey = routerKey(key)
        backHashLinkMap.forEach{ (k, list) ->
            list.find{ it == routerKey }?.also{
                eSessionStorage[k] = key
            }
        }
    }
    fun backLink(key:String, args:MutableMap<String, Any> = mutableMapOf()):ALink{
        var routerKey = key
        val arg:MutableMap<String, Any> = eSessionStorage[routerKey]?.let{
            var queryString = it
            if(backHashLinkMap.containsKey(key)){
                val keyArr = queryString.split("?")
                routerKey = keyArr.first()
                queryString = if(keyArr.size > 1) keyArr.last() else ""
            }
            eDecodeQueryString(queryString).toMutableMap()
        } ?: args
        return ALink(routerKey, arg)
    }
}