package comp.input

import app.Factory
import comp.Comp
import ein2b.core.core.err
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompError(private val className:String = ""):Comp{ 
    companion object{
        private const val VIEW_KEY = "FORM_ERROR"
        private val factory = Factory.html("""<div data-view="$VIEW_KEY"></div>""")
    }
    private var target:eView<HTMLElement>? = null
    suspend fun init(it:eView<HTMLElement>){
        target = it.sub(VIEW_KEY){
            it.className = className
            it.displayNone()
        }
        baseProp(it)
    }
    suspend fun view():eView<HTMLElement> = eView(factory){ init(it) }
    suspend fun comp(root:eView<HTMLElement>, subKey:Any):eView<HTMLElement> = root.sub(subKey, factory){ init(it) }
    fun update(isOk:Boolean, msg:String){
        target?.let{
            if(isOk){
                it.html = ""
                it.displayNone()
            }else{
                it.html = msg
                it.displayBlock()
            }
        } ?: err("CompError - not inited")
    }
    fun clear(){
        update(true, "")
    }
}
suspend fun eView<HTMLElement>.compErrorSet(subKey:Any, className:String = "form-error"){
    CompError(className).also{
        it.comp(this, subKey)
    }
}
inline fun eView<HTMLElement>.compError() = this["compError"] as? CompError ?: err("fail to get CompError")


class CompErrorIcon(private val className:String = "flex-center form-error"):Comp{
    companion object{
        enum class K{
            wrap, label
        }
        //language=html
        private val factory = Factory.html("""
        <div data-view="${K.wrap}">
            <div class="ic-info2 margin-right6"></div><label data-view="${K.label}" style="line-height:24px;width:fit-content"></label>
        </div>""".trimIndent())
    }
    private var target:eView<HTMLElement>? = null
    suspend fun init(it:eView<HTMLElement>){
        it.sub(K.wrap){
            it.className = className
            it.displayNone()
        }
        it.sub(K.label)
        target = it
        baseProp(it)
    }
    suspend fun view():eView<HTMLElement> = eView(factory){ init(it) }
    suspend fun comp(root:eView<HTMLElement>, subKey:Any):eView<HTMLElement> = root.sub(subKey, factory){ init(it) }
    suspend fun update(isOk:Boolean, msg:String, vararg attrList:Pair<String, String>){
        target?.let{
            it.sub(K.wrap){ if(isOk) it.displayNone() else it.displayFlex() }
            it.sub(K.label){ l ->
                attrList.forEach { l.attr(it) }
                l.html = msg
            }
        } ?: err("CompErrorIcon - not inited")
    }
    suspend fun clear(){
        update(true, "")
    }
}
inline fun eView<HTMLElement>.compErrorIcon() = this["compErrorIcon"] as? CompError ?: err("fail to get CompErrorIcon")