package bxlt.enum

// 그룹 종류
enum class EnumGrpCat(val grpTitle:String) {
    MASTER("마스터"){
        override val authzCatList = listOf(
            EnumAuthz.SUBMEMBER_READ,
            EnumAuthz.SUBMEMBER_WRITE,
            EnumAuthz.GROUP_READ,
            EnumAuthz.GROUP_WRITE
        )
    },
    DEFAULT("기본 그룹"){
        override val authzCatList = listOf<EnumAuthz>()
    },
    GENERAL("일반 그룹"){
        override val authzCatList = listOf<EnumAuthz>()
    };
    abstract val authzCatList:List<EnumAuthz>

    companion object{
        operator fun invoke(grpKey:String) = values().find { it.name == grpKey }?:throw Throwable("없는 그룹입니다. grpKey=$grpKey")
    }
}