package router.joinComplete

import app.ALink
import app.Factory
import app.RouterKey
import ein2b.core.view.*
import bxlt.api.member.EntApiMemberMasterJoinEmailStep2
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("joinComplete")
private enum class K{ success, fail, moveLoginBtn }
suspend fun JoinCompleteView() = eView(factory){ staticView->
    staticView.sub(K.success)
    staticView.sub(K.fail).displayNone()
    staticView.sub(K.moveLoginBtn).href = ALink(RouterKey.LOGIN).link
    staticView.addEntityHook(EntApiMemberMasterJoinEmailStep2.Res::class, object:eEntityHook<HTMLElement,EntApiMemberMasterJoinEmailStep2.Res> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntApiMemberMasterJoinEmailStep2.Res) {
            if(entity.isOk) {
                staticView.sub(K.success).displayBlock()
                staticView.sub(K.fail).displayNone()
            } else {
                staticView.sub(K.success).displayNone()
                staticView.sub(K.fail).displayBlock()
            }
        }
    })
}