package router.pwChange

import app.CompApp
import app.Factory
import app.clearCompValue
import comp.CompPageTitle
import compLabelInputSectionPasswordSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.js.crypto.eCrypto
import bxlt.vali.ValiMemberAccountOldPw
import bxlt.vali.ValiMemberAccountPw
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionPassword
import view.CompViewToast

private val factory = Factory.htmlUrl("pwChange")
private enum class K{ oldPw, pw, pwConfirm, submitBtn }
suspend fun PwChangeView() = eView(factory){ staticView->
    CompPageTitle(staticView){
        it.title = "r@비밀번호 변경@5/5/6"
    }
    staticView.compLabelInputSectionPasswordSet(K.oldPw,"r@기존 비밀번호@5/5/7", ValiMemberAccountOldPw)

    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection	레이블	새로운 비밀번호 */
    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	클라 벨리 - 입력값: 빈 값	비밀번호를 입력해 주세요. */
    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	클라 벨리 - 입력값: 8자 미만	8~32자 이내로 입력해 주세요. */
    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	클라 벨리 - 입력값: 32자 초과	8~32자 이내로 입력해 주세요. */
    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	클라 밸리 - 입력값: 모두 영문	영문/숫자/특수문자 중 2개 이상의 조합으로 입력해 주세요. */
    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	클라 밸리 - 입력값: 모두 숫자	영문/숫자/특수문자 중 2개 이상의 조합으로 입력해 주세요. */
    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	클라 밸리 - 입력값: 모두 (입력 가능한) 특수문자	영문/숫자/특수문자 중 2개 이상의 조합으로 입력해 주세요.9 */
    /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	클라 밸리 - 입력값: 입력 불가능한 특수문자가 포함되어 있음	영문/숫자/특수문자 중 2개 이상의 조합으로 입력해 주세요. */
    staticView.compLabelInputSectionPasswordSet(K.pw,"r@새로운 비밀번호@5/5/8", ValiMemberAccountPw){
        /** 비밀 번호 변경	새로운 비밀번호		[컴포넌트] CompLabelInputSection - CompInputPassword	플레이스 홀더	8~32자, 영문/숫자/특수문자 중 2개 이상의 조합 */
        it.placeholder = "r@8~32자, 영문/숫자/특수문자 중 2개 이상의 조합@pwChange/pw/placeholder"
    }

    /** 비밀 번호 변경	새로운 비밀번호 확인		[컴포넌트] CompLabelInputSection	레이블	새로운 비밀번호 확인 */
    staticView.compLabelInputSectionPasswordSet(K.pwConfirm,"r@새로운 비밀번호 확인@5/5/1o")
    staticView.sub(K.submitBtn).click = {_,_ ->
        eLaunch{
            val oldPw = staticView.sub(K.oldPw).compLabelInputSectionPassword()
            val pw = staticView.sub(K.pw).compLabelInputSectionPassword()
            val pwConfirm = staticView.sub(K.pwConfirm).compLabelInputSectionPassword()
            /** 비밀번호 변경 	비밀번호 변경 버튼	    클릭시 - 체크할 항목	기존 비밀번호	[컴포넌트] CompLabelInputSection - CompInputPassword */
            /** 비밀번호 변경 	비밀번호 변경 버튼	    클릭시 - 체크할 항목	새로운 비밀번호	[컴포넌트] CompLabelInputSection - CompInputPassword */
            /** 비밀번호 변경 	비밀번호 변경 버튼	    클릭시 - 체크할 항목	새로운 비밀번호 확인	[컴포넌트] CompLabelInputSection - CompInputPassword */
            if(CompApp.checkAll(oldPw, pw, pwConfirm)){
                if(pw.out() != pwConfirm.out()){
                    /** 비밀번호 변경	    비밀번호 재설정 버튼	클릭시 - 새로운 비밀번호와 새로운 비밀번호 확인이 일치하지 않을 때	새로운 비밀번호 확인 - [컴포넌트] CompLabelInputSection - CompInputPassword	새로운 비밀번호가 일치하지 않습니다. */
                    pwConfirm.changeError("r@새로운 비밀번호가 일치하지 않습니다.@5/5/1p")
                }else{
                    N.rsaGet()?.also{ rsa ->
                        N.pwChange(rsa.rsa.id, eCrypto.rsaEncrypt(oldPw.out(), rsa.rsa.key), eCrypto.rsaEncrypt(pw.out(), rsa.rsa.key))?.also{ res ->
                            if(res.isSuccess){
                                /** 비밀번호 변경     서버 통신 - 성공      CompViewToast   비밀번호를 변경했습니다. */
                                CompViewToast.open("r@비밀번호를 변경했습니다.@5/5/1q")
                                staticView.entity(HcInit)
                            }else{
                                /** 비밀번호 변경     서버 통신 - 실패 - 기존 비밀번호 - [컴포넌트] CompLabelInputSection - CompInputPassword    기존 비밀번호와 일치하지 않는 경우	기존 비밀번호가 일치하지 않습니다. */
                                oldPw.changeError(res.errorMsg, false)
                            }
                        }
                    }
                }
            }
        }
    }
    staticView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement,HcInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: HcInit){
            /** 비밀번호 변경 	초기화	기존 비밀번호      	view.clearCompValue	[컴포넌트] CompLabelInputSection - CompInputPassword */
            /** 비밀번호 변경 	초기화	새로운 비밀번호      	view.clearCompValue	[컴포넌트] CompLabelInputSection - CompInputPassword */
            /** 비밀번호 변경 	초기화	비밀번호	확인      	view.clearCompValue	[컴포넌트] CompLabelInputSection - CompInputPassword */
            view.clearCompValue(K.oldPw, K.pw, K.pwConfirm)
        }
    })
}