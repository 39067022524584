package router.joinComplete

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import entity.EntClientJoinCompleteUrl
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class JoinCompleteP(router:eRouter<HTMLElement>):PageBase(::JoinCompleteView, router) {
    override suspend fun net(data: eRouterData){
        data.parse(EntClientJoinCompleteUrl())?.also { url ->
            N.joinComplete2(url.token, url.actionToken)?.also { res ->
                view?.entity(res)
            }
        }
    }
}