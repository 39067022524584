package m42.resource.model

import ein2b.core.entity.eEntity

//s3 업로드 경로 - /resource/category/...
/**
 *  {
 *      "categoryList":[
 *          {
 *              "key":""
 *              "itemList":[
 *                  {
 *                      "key":"co",
 *                      "valueList":["KR", "SG"]
 *                  },
 *                  {
 *                      "key":"ln",
 *                      "valueList":["ko", "en"]
 *                  }
 *              ]
 *          }
 *      ]
 *  }*/
class MdlS3CategoryData:eEntity(){
    var categoryList by entityList(::MdlS3Category){ default{ mutableListOf() } }
}
class MdlS3Category:eEntity(){
    var key by string
    var itemList by entityList(::MdlS3CategoryItem){ default{ mutableListOf() } }
    var fileList by stringList{ default(defaultStringList) }
}
class MdlS3CategoryItem:eEntity(){
    var key by string
    var valueList by stringList
}

