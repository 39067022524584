package comp.input

import comp.Comp
import comp.CompValueOut
import ein2b.core.validation.eVali
import ein2b.core.view.eView
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

interface CompInput<IN:Any, V, OUT>:Comp, CompValueOut<OUT>{
    companion object{
        val CONV:(String)->String = { it }
        //TODO:: num lock 일때 .도 커버해야 함 (48~57), .(190), num lock 숫자코드 (96~105), .(110)
        var keyDownEvent = { maxLength:Int, e:Event, el:HTMLElement->
            val ev = eEvent(e, el)
            if(maxLength > -1 && ev.keycode() !in KEYCODE_DEFAULT && ev.value.length >= maxLength){
                e.stopImmediatePropagation()
                ev.prevent()
            }
        }

        //Backspace(8), Tab(9), Enter(13)
        //Insert 45, Delete 46, Page Up 33, Page Down 34, End 35, Home 36, Arrow Left 37, Arrow Up 38, Arrow Right 39, Arrow Down 40
        //Ctrl: 17, v: 86
        val KEYCODE_DEFAULT = hashSetOf(8,9,13,45,46,33,34,35,36,37,38,39,40,17,86)
        //숫자 & 숫자 키패드
        val KEYCODE = KEYCODE_DEFAULT + (48..57) + (96..105)
    }
    var value:CompValue<IN, V>
    var errorListener:((Boolean, String)->Unit)?
    var vali:eVali?
    var placeholder:String
    var tabIndex:Int
    val factory:suspend ()->HTMLElement
    suspend fun init(it:eView<HTMLElement>)
    suspend fun view():eView<HTMLElement> = eView(factory){
        baseProp(it, true)
        init(it)
    }
    suspend fun comp(root:eView<HTMLElement>, subKey:Any):eView<HTMLElement> = root.sub(subKey, factory){
        baseProp(it, true)
        init(it)
    }
    suspend fun error(isOk:Boolean)
    override fun check() = value.check()

    suspend fun displayNone()
    suspend fun displayBlock()
    suspend fun displayInlineBlock()
    suspend fun displayFlex()
}