package router.comp

import app.Factory
import comp.Comp
import ein2b.core.core.err
import ein2b.core.view.eView
import ein2b.core.view.html
import org.w3c.dom.HTMLElement

class CompMemberEmail:Comp{
    companion object{
        private enum class K{
            title, targetEmail, contents;
            override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
        }
        val factory = Factory.htmlUrl("comp/memberEmail")
        suspend operator fun invoke(rootView: eView<HTMLElement>, subKey:Any, block:(CompMemberEmail)->Unit): CompMemberEmail {
            val comp = CompMemberEmail()
            block(comp)
            comp.target = rootView.sub(subKey, factory){ target ->
                target.sub(K.title).html = comp.title
                target.sub(K.targetEmail).html = comp.targetEmail
                target.sub(K.contents){
                    it.html = comp.contents
                }
                comp.baseProp(target)
            }
            return comp
        }
    }
    lateinit var target: eView<HTMLElement>
    var title = ""
    var contents = ""
    var targetEmail = ""
    suspend fun setEmail(v:String){
        title = v
        target.sub(K.targetEmail){ titleView->
            titleView.html = v
        }
    }
}
inline fun eView<HTMLElement>.compMemberEmail(block:(CompMemberEmail)->Unit = { }): CompMemberEmail {
    val comp = this["compMemberEmail"] as? CompMemberEmail ?: err("fail to get CompMemberEmail")
    block(comp)
    return comp
}