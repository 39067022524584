package ein2b.core.date

import ein2b.core.core.*


class eUtc(
    val yearUTC:Int=1900,
    val monthUTC:Int=1,
    val dateUTC:Int=1,
    val hourUTC:Int=0,
    val minuteUTC:Int=0,
    val secondUTC:Int=0,
    val millisecondUTC:Int=0
): Comparable<eUtc>, eDateTimePartGetter, eDateTimeArithmeticInZone<eUtc> {
    fun isDefault():Boolean = this == DEFAULT
    inline override val year:Int get() = yearUTC
    inline override val month:Int get() = monthUTC
    inline override val date:Int get() = dateUTC
    inline override val hour:Int get() = hourUTC
    inline override val minute:Int get() = minuteUTC
    inline override val second:Int get() = secondUTC
    inline override val millisecond:Int get() = millisecondUTC
    override fun localeDayOfWeek(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String> =
        platformDayOfWeekLocale(locale)

    override fun localeAmPm(locale: String): String =
        platformAmPmLocale(locale)

    override fun localeMonth(locale:String): String = platformMonthLocale(locale)

    override fun dayOfWeekEnum(): eDateTimePartGetter.DAY_KIND = platformDayOfWeek()

    override fun amPmEnum(): eDateTimePartGetter.AMPM_KIND = platformAmPm()

    override fun monthEnum(): eDateTimePartGetter.MONTH_KIND = platformMonth()

    private fun toYymmdd():Int =
            dateUTC + monthUTC * 100 + yearUTC * 1_0000
    private fun toHhmmss():Int =
            secondUTC + minuteUTC * 100 + hourUTC * 1_0000
    override operator fun compareTo(other: eUtc): Int =
            (this.toYymmdd() - other.toYymmdd()).let { ymddiff ->
                if(ymddiff == 0) {
                    (this.toHhmmss() - other.toHhmmss()).let {
                        if(it==0)
                            this.millisecondUTC - other.millisecondUTC
                        else
                            it
                    }
                } else
                  ymddiff
            }

    override fun minusYears(years: Long, zone: String): eUtc = implUtcMinusYears(this,years,zone)
    override fun minusMonths(months: Long, zone: String): eUtc = implUtcMinusMonths(this,months,zone)
    override fun minusDays(days: Long, zone: String): eUtc = implUtcMinusDays(this, days,zone)
    override fun minusHours(hours: Long, zone: String): eUtc = implUtcMinusHours(this, hours,zone)
    override fun minusMinutes(minutes: Long, zone: String): eUtc = implUtcMinusMinutes(this, minutes,zone)
    override fun minusSeconds(seconds: Long, zone: String): eUtc = implUtcMinusSeconds(this, seconds,zone)
    override fun minusMillis(millis: Long, zone: String): eUtc = implUtcMinusMillis(this, millis,zone)
    override fun minusWeeks(weeks: Long, zone: String): eUtc = implUtcMinusWeeks(this, weeks,zone)
    override fun plusYears(years: Long, zone: String): eUtc = implUtcPlusYears(this, years,zone)
    override fun plusMonths(months: Long, zone: String): eUtc = implUtcPlusMonths(this, months,zone)
    override fun plusDays(days: Long, zone: String): eUtc = implUtcPlusDays(this, days,zone)
    override fun plusHours(hours: Long, zone: String): eUtc = implUtcPlusHours(this, hours,zone)
    override fun plusMinutes(minutes: Long, zone: String): eUtc = implUtcPlusMinutes(this, minutes,zone)
    override fun plusSeconds(seconds: Long, zone: String): eUtc = implUtcPlusSeconds(this, seconds,zone)
    override fun plusMillis(millis: Long, zone: String): eUtc = implUtcPlusMillis(this, millis,zone)
    override fun plusWeeks(weeks: Long, zone: String): eUtc = implUtcPlusWeeks(this, weeks,zone)

    override fun equals(other: Any?): Boolean = when(other) {
        null -> false
        !is eUtc -> false
        else ->
            this.compareTo(other) == 0
    }
    
    override fun toString() = "${yearUTC.pad0_4()}-${(monthUTC).pad0_2()}-${dateUTC.pad0_2()}T${hourUTC.pad0_2()}:${minuteUTC.pad0_2()}:${secondUTC.pad0_2()}.${millisecondUTC.pad0_3()}Z"

    // mysql 입력시 필요한 문자열 형태
    fun toDbString() = "${yearUTC.pad0_4()}-${(monthUTC).pad0_2()}-${dateUTC.pad0_2()} ${hourUTC.pad0_2()}:${minuteUTC.pad0_2()}:${secondUTC.pad0_2()}.${millisecondUTC.pad0_3()}"

    companion object {
        // yyyy-mm-ddThh:mmZ, yyyy-mm-ddThh:mm:ssZ, yyyy-mm-ddThh:mm.ss.{f,fff,fff}Z 유형 허용
        val rISO = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?Z""".toRegex()
        val rISOMicro = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{4,6}))?)?Z""".toRegex()

        val rDbLocal = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d) (2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()
        val rDbLocalMicro = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d) (2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()

        val rDbLocalT = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()
        val rDbLocalMicroT = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()

        val DB_ZERO_VAL = eUtc(1900,1,1,0,0,0)

        private fun milliSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100
            2 -> s.toInt()*10
            3 -> s.toInt()
            0 -> 0
            else -> s.substring(0,3).toInt()
        }

        private fun microSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100000
            2 -> s.toInt()*10000
            3 -> s.toInt()*1000
            4 -> s.toInt()*100
            5 -> s.toInt()*10
            6 -> s.toInt()
            0 -> 0
            else -> s.substring(0,6).toInt()
        } / 1000

        private fun groupToEUtc(match: MatchResult, subFractionConv:(String)->Int):eUtc {
            val m = match.groupValues.drop(1)
            val year = m[0].toInt()
            val month = m[1].toInt()
            val day = m[2].toInt()
            val hourStr = m[3]
            val minStr = m[4]
            val secStr = m[5]
            val milliStr = m[6]

            return if(hourStr.isBlank()) eUtc(year, month, day)
            else if(minStr.isBlank()) eUtc(year, month, day, hourStr.toInt())
            else if(secStr.isBlank()) eUtc(year, month, day, hourStr.toInt(), minStr.toInt())
            else if(milliStr.isBlank()) eUtc(year, month, day, hourStr.toInt(), minStr.toInt())
            else eUtc(year, month, day, hourStr.toInt(), minStr.toInt(), secStr.toInt(), subFractionConv(milliStr))
        }

        private val DEFAULT = of("1900-01-01T00:00:00:000Z")!!
        fun default():eUtc = DEFAULT
        fun isDefault(target:eUtc):Boolean = target == DEFAULT

        fun of(dateStr:String):eUtc? =
            (rISO.matchEntire(dateStr)?:rDbLocal.matchEntire(dateStr)?:rDbLocalT.matchEntire(dateStr))?.let {
                groupToEUtc(it, eUtc::milliSecParse)
            } ?: (rISOMicro.matchEntire(dateStr)?:rDbLocalMicro.matchEntire(dateStr)?:rDbLocalMicroT.matchEntire(dateStr))?.let {
                groupToEUtc(it, eUtc::microSecParse)
            }

        fun diff(interval:String, d1:eUtc, d2:eUtc, zone: String):Long =
            eZonedDateTime(d1.toLocalDateTime(zone),zone).diff(interval,eZonedDateTime(d2.toLocalDateTime(zone),zone))

        private fun _leapYear(v:Int)= (v% 4 == 0 && v % 100 != 0) || v % 400 == 0
    }

    // UTC를 UTC존으로(시간 변환 없이) 문자열로 변경하기
    fun format(format:String, locale:String = "") = eDateTimePartGetter.part(format, this, locale)

    // UTC를 지정한 존의 시간으로 변환 후 문자열로 변경하기
    fun format(format:String, zone: String, locale:String = "") = eDateTimePartGetter.part(format, this.toLocalDateTime(zone), locale)
}

expect fun eUtc.Companion.now(): eUtc

expect fun eUtc.toLocalDateTime(zone: String): eLocalDateTime

expect fun eUtc.timeInMilli(): Long

expect fun eUtc.platformDayOfWeekLocale(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String>

expect fun eUtc.platformAmPmLocale(locale: String): String

expect fun eUtc.platformMonthLocale(locale: String): String

expect fun eUtc.platformDayOfWeek(): eDateTimePartGetter.DAY_KIND

expect fun eUtc.platformAmPm(): eDateTimePartGetter.AMPM_KIND

expect fun eUtc.platformMonth(): eDateTimePartGetter.MONTH_KIND

expect fun implUtcMinusYears(date: eUtc, years: Long, zone:String): eUtc
expect fun implUtcMinusMonths(date: eUtc, months: Long, zone:String): eUtc
expect fun implUtcMinusDays(date: eUtc, days: Long, zone:String): eUtc
expect fun implUtcMinusHours(date: eUtc, hours: Long, zone:String): eUtc
expect fun implUtcMinusMinutes(date: eUtc, minutes: Long, zone:String): eUtc
expect fun implUtcMinusSeconds(date: eUtc, seconds: Long, zone:String): eUtc
expect fun implUtcMinusMillis(date: eUtc, millis: Long, zone:String): eUtc
expect fun implUtcMinusWeeks(date: eUtc, weeks: Long, zone:String): eUtc
expect fun implUtcPlusYears(date: eUtc, years: Long, zone:String): eUtc
expect fun implUtcPlusMonths(date: eUtc, months: Long, zone:String): eUtc
expect fun implUtcPlusDays(date: eUtc, days: Long, zone:String): eUtc
expect fun implUtcPlusHours(date: eUtc, hours: Long, zone:String): eUtc
expect fun implUtcPlusMinutes(date: eUtc, minutes: Long, zone:String): eUtc
expect fun implUtcPlusSeconds(date: eUtc, seconds: Long, zone:String): eUtc
expect fun implUtcPlusMillis(date: eUtc, millis: Long, zone:String): eUtc
expect fun implUtcPlusWeeks(date: eUtc, weeks: Long, zone:String): eUtc
