package comp

import app.Factory
import ein2b.core.core.err
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

const val KILO = 1000L
const val MEGA = KILO * 1000
const val GIGA = MEGA * 1000
const val TERA = GIGA * 1000
const val PETA = TERA * 1000

fun byteToSize(bytes: String): String {
    val size = bytes.toLong()
    if(size < 0) throw Throwable("..")
    val (unit,unitValue) = when {
        size >= PETA -> "PB" to PETA
        size >= TERA -> "TB" to TERA
        size >= GIGA -> "GB" to GIGA
        size >= MEGA -> "MB" to MEGA
        size >= KILO -> "KB" to KILO
        size >= 0 -> "Bytes" to 1L
        else -> throw Throwable("..")
    }
    val aboveUnit = size / unitValue
    val belowUnit = (((100*size) / unitValue) + 5) % 100 / 10
    return "${aboveUnit}.${belowUnit} $unit"
}
/* 엄지민
*
*  CompFileName(rootView, "compFileName") {
        it.fileUrl = "https://s3.ap-northeast-2.amazonaws.com/awb-upload.mobility42.io/real/data+report.pdf"
        it.fileName = "상당히긴이름상당히긴이름상당히긴이름상당히긴이름.pdf"
        it.size = 15000000
        it.deleteBlock = {
            console.log("이곳에 엑스버튼 누르면 동작할 행위를 적는다..")
        }
    }
*
*
* */

class CompFileName private constructor():Comp{
    companion object {
        private enum class K{ wrap, icon, fileNameSize, fileName, size, delete, deleteIcon, ext }
        //language=html
        internal val factory = Factory.html("""
        <div data-view="${K.wrap}">
            <div data-view="${K.icon}" class="flex-shrink-0">
                <div data-view="${K.ext}" style="text-align:center;line-height:39px;font-weight:bold;color:#ffffff;font-size:10px"></div>
            </div>
            <div data-view="${K.fileNameSize}" class="flex-grow-1 margin-left16 margin-right8" style='width:70%'>
                <div data-view="${K.fileName}" class="ellipsis" style="width:100%;display:block"></div>
                <div data-view="${K.size}" class="margin-top8" style="width:100%"></div>
            </div>
            <div data-view="delete" class="flex-shrink-0 cursor btn disabled-border" style="padding:9px;width:36px;height:36px">
                <div data-view="deleteIcon" style="width:16px;height:16px"></div>
            </div>
        </div>
        """.trimMargin())
        //<b data-view="${K.compEllipsis}"></b><div data-view="${K.fileExt}"></div>
        suspend operator fun invoke(root:eView<HTMLElement>, subKey:Any, isClick:Boolean = true, block:(CompFileName)->Unit):CompFileName{
            val comp = CompFileName()
            //byteToSize()
            comp.isClick = isClick
            block(comp)
            root.sub(subKey, factory){
                it.sub(K.wrap){ wrapView->
                    wrapView.className = "${comp.wrapDefaultClass}${if(comp.wrapClass.isNotBlank()) " ${comp.wrapClass}" else ""}"
                }
                it.sub(K.icon) {icon ->
                    icon.displayNone()
                    if(comp.hasIcon) icon.displayBlock()
                    icon.className = comp.getExtClassName(comp.fileName)
                }
                it.sub(K.fileNameSize)/*.domStyle("maxWidth", "calc(100% - ${if(comp.hasDeleteBtn) 100 else 50}px)")*/
                it.sub(K.fileName) {
                    it.className = if(comp.isOpen || comp.clickBlock != null) "link-btn-text ellipsis" else "ellipsis"
                    it.click = {e,_->
                        if(comp.isOpen) window.open(comp.fileUrl)
                        else if(comp.clickBlock != null){
                            e.stopImmediatePropagation()
                            e.stopPropagation()
                            comp.clickBlock?.invoke()
                        }
                    }
                }
                it.sub(K.ext) {ext ->
                    ext.displayNone()
                }
                it.sub(K.delete) {d ->
                    d.displayNone()
                    if(comp.hasDeleteBtn) d.displayBlock()
                    d.click = {e,_->
                        if(comp.deleteBlock != null){
                            comp.deleteBlock?.invoke()
                        }
                    }
                }
                it.sub(K.deleteIcon).className = "${comp.deleteIcon}"
                comp.target = it
                comp.update(comp.fileName, "${comp.size}")
                comp.baseProp(it)
            }
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    private var wrapDefaultClass:String = "view-border flex-center compFileName"
    var fileName:String = ""
    var fileUrl:String = ""
    var wrapClass:String = ""
    var wrapWidth:Int = 0
    var size:Int = 0
    var hasDeleteBtn:Boolean = true
    var hasIcon:Boolean = true
    var deleteIcon:String = "ic-delete-blue"
    var deleteBlock:(()->Unit)? = null
    var clickBlock:(()->Unit)? = null
    var isClick:Boolean = true
    private val isOpen:Boolean get() = isClick && fileUrl.isNotBlank()
    suspend fun update(fileName:String, fileSize:String="", _fileUrl:String=""){
        fileUrl = _fileUrl
        if(fileName.isBlank()) target.displayNone() else target.displayFlex()
        target.sub(K.icon){icon ->
            icon.displayNone()
            if(hasIcon) icon.displayBlock()
            icon.className = getExtClassName(fileName)
        }
        target.sub(K.fileName){
            it.className = if(isOpen || clickBlock != null) "link-btn-text ellipsis" else "ellipsis"
            it.html = fileName
            it.titleName = fileName
        }
        val idx = fileName.lastIndexOf('.')
        target.sub(K.ext) {
            if(getExtClassName(fileName).contains("ic-doc-default")) {
                it.displayBlock()
                it.html = fileName.substring(idx+1).uppercase()
            }
        }
        target.sub(K.size){ s ->
            s.displayNone()
            if(fileSize.isNotBlank()) {
                s.displayBlock()
                s.html = byteToSize(fileSize)
            }
        }
    }
    fun getExtClassName(fileName: String): String{
        val ext = fileName.substring(fileName.lastIndexOf('.') + 1)
        return "flex-shrink-0 ic-docs ${when(ext){
            "xlsx" -> "ic-excel"
            "pdf" -> "ic-pdf"
            else -> "ic-doc-default"
        }}"
    }
    fun displayNone() = target.displayNone()
    fun displayFlex() = target.displayFlex()
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compFileName(block:(CompFileName)->Unit = {}):CompFileName{
    val comp = this["compFileName"] as? CompFileName ?: err("fail to get compFileName")
    block(comp)
    return comp
}