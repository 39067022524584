package router.join

import app.ClientApiMemberLoginCheck
import app.ClientApiMemberMasterJoinEmailStep1
import app.ClientApiMemberRsa
import bxlt.api.member.EntApiMemberLoginCheck
import bxlt.api.member.EntApiMemberMasterJoinEmailStep1
import bxlt.api.member.EntApiMemberRsa
import bxlt.model.MdlMemberJoinParam
import m42.common.api.EntM42Api

object N{
    suspend fun rsaGet(): EntApiMemberRsa.Res? = ClientApiMemberRsa.net()
    suspend fun joinStep1(param: MdlMemberJoinParam): EntApiMemberMasterJoinEmailStep1.Res? = ClientApiMemberMasterJoinEmailStep1.net(
        EntM42Api.ENTITY_KEY to { param })
    suspend fun loginCheck(): EntApiMemberLoginCheck.Res? = ClientApiMemberLoginCheck.net()
}