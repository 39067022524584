package ein2b.core.date

import ein2b.core.core.*

class eLocalDateTime(
    override val year:Int=0,
    override val month:Int=0,
    override val date:Int=0,
    override val hour:Int=0,
    override val minute:Int=0,
    override val second:Int=0,
    override val millisecond:Int=0
): Comparable<eLocalDateTime>, eDateTimePartGetter, eDateTimeArithmetic<eLocalDateTime>, eDateTimeArithmeticInZone<eLocalDateTime> {
    fun isDefault():Boolean = this == DEFAULT
    private fun toYymmdd():Int =
            date + month * 100 + year * 1_0000
    private fun toHhmmss():Int =
            second + minute * 100 + hour * 1_0000
    override operator fun compareTo(other: eLocalDateTime): Int =
            (this.toYymmdd() - other.toYymmdd()).let { ymddiff ->
                if(ymddiff == 0) {
                    (this.toHhmmss() - other.toHhmmss()).let {
                        if(it==0)
                            this.millisecond - other.millisecond
                        else
                            it
                    }
                } else
                  ymddiff
            }

    override fun localeDayOfWeek(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String>  = platformDayOfWeekLocale(locale)

    override fun localeAmPm(locale: String): String  = platformAmPmLocale(locale)

    override fun localeMonth(locale:String): String = platformMonthLocale(locale)

    override fun dayOfWeekEnum(): eDateTimePartGetter.DAY_KIND = platformDayOfWeek()

    override fun amPmEnum(): eDateTimePartGetter.AMPM_KIND = platformAmPm()

    override fun monthEnum(): eDateTimePartGetter.MONTH_KIND = platformMonth()

    override fun equals(other: Any?): Boolean = when(other) {
        null -> false
        !is eLocalDateTime -> false
        else ->
            this.compareTo(other) == 0
    }

    fun format(format:String, locale:String = "") = eDateTimePartGetter.part(format, this, locale)

    override fun toString() = "${year.pad0_4()}-${(month).pad0_2()}-${date.pad0_2()}T${hour.pad0_2()}:${minute.pad0_2()}:${second.pad0_2()}.${millisecond.pad0_3()}"

    companion object {
        // yyyy-mm-ddThh:mm, yyyy-mm-ddThh:mm:ss, yyyy-mm-ddThh:mm.ss.{f,fff,fff} 유형만 허용
        val rISO = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()
        val rISOMicro = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{4,6}))?)?""".toRegex()
        private fun milliSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100
            2 -> s.toInt()*10
            3 -> s.toInt()
            0 -> 0
            else -> s.substring(0,3).toInt()
        }

        private fun microSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100000
            2 -> s.toInt()*10000
            3 -> s.toInt()*1000
            4 -> s.toInt()*100
            5 -> s.toInt()*10
            6 -> s.toInt()
            0 -> 0
            else -> s.substring(0,6).toInt()
        }

        private fun groupToELocalDateTime(match: MatchResult, subFractionConv:(String)->Int):eLocalDateTime {
            val m = match.groupValues.drop(1)
            val year = m[0].toInt()
            val month = m[1].toInt()
            val day = m[2].toInt()
            val hourStr = m[3]
            val minStr = m[4]
            val secStr = m[5]
            val milliStr = m[6]

            return if(hourStr.isBlank()) eLocalDateTime(year, month, day)
            else if(minStr.isBlank()) eLocalDateTime(year, month, day, hourStr.toInt())
            else if(secStr.isBlank()) eLocalDateTime(year, month, day, hourStr.toInt(), minStr.toInt())
            else if(milliStr.isBlank()) eLocalDateTime(year, month, day, hourStr.toInt(), minStr.toInt())
            else eLocalDateTime(year, month, day, hourStr.toInt(), minStr.toInt(), secStr.toInt(), subFractionConv(milliStr))
        }

        private val DEFAULT = of("1900-01-01T00:00:00:000")!!
        fun default():eLocalDateTime = DEFAULT
        fun isDefault(target:eLocalDateTime):Boolean = target == DEFAULT

        fun of(dayStr:String):eLocalDateTime? =
            (rISO.matchEntire(dayStr))?.let {
                groupToELocalDateTime(it, ::milliSecParse)
            } ?: (rISOMicro.matchEntire(dayStr))?.let {
                groupToELocalDateTime(it, ::microSecParse)
            }
    }

    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusYears(years, zone)"))
    override fun minusYears(years: Long): eLocalDateTime = implLocalDateTimeMinusYears(this,years)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusMonths(months, zone)"))
    override fun minusMonths(months: Long): eLocalDateTime = implLocalDateTimeMinusMonths(this,months)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusDays(days, zone)"))
    override fun minusDays(days: Long): eLocalDateTime = implLocalDateTimeMinusDays(this, days)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusHours(hours, zone)"))
    override fun minusHours(hours: Long): eLocalDateTime = implLocalDateTimeMinusHours(this, hours)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusMinutes(minutes, zone)"))
    override fun minusMinutes(minutes: Long): eLocalDateTime = implLocalDateTimeMinusMinutes(this, minutes)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusSeconds(seconds, zone)"))
    override fun minusSeconds(seconds: Long): eLocalDateTime = implLocalDateTimeMinusSeconds(this, seconds)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusMillis(millis, zone)"))
    override fun minusMillis(millis: Long): eLocalDateTime = implLocalDateTimeMinusMillis(this, millis)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("minusWeeks(weeks, zone)"))
    override fun minusWeeks(weeks: Long): eLocalDateTime = implLocalDateTimeMinusWeeks(this, weeks)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusYears(years, zone)"))
    override fun plusYears(years: Long): eLocalDateTime = implLocalDateTimePlusYears(this, years)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusMonths(months, zone)"))
    override fun plusMonths(months: Long): eLocalDateTime = implLocalDateTimePlusMonths(this, months)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusDays(days, zone)"))
    override fun plusDays(days: Long): eLocalDateTime = implLocalDateTimePlusDays(this, days)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusHours(hours, zone)"))
    override fun plusHours(hours: Long): eLocalDateTime = implLocalDateTimePlusHours(this, hours)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusMinutes(minutes, zone)"))
    override fun plusMinutes(minutes: Long): eLocalDateTime = implLocalDateTimePlusMinutes(this, minutes)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusSeconds(seconds, zone)"))
    override fun plusSeconds(seconds: Long): eLocalDateTime = implLocalDateTimePlusSeconds(this, seconds)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusMillis(millis, zone)"))
    override fun plusMillis(millis: Long): eLocalDateTime = implLocalDateTimePlusMillis(this, millis)
    @Deprecated("이 함수는 시스템 타임존을 씁니다. 시스템 설정에 따라 처리가 달라질 수 있습니다.", ReplaceWith("plusWeeks(weeks, zone)"))
    override fun plusWeeks(weeks: Long): eLocalDateTime = implLocalDateTimePlusWeeks(this, weeks)

    override fun minusYears(years: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusYears(this,years,zone)
    override fun minusMonths(months: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusMonths(this,months,zone)
    override fun minusDays(days: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusDays(this, days,zone)
    override fun minusHours(hours: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusHours(this, hours,zone)
    override fun minusMinutes(minutes: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusMinutes(this, minutes,zone)
    override fun minusSeconds(seconds: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusSeconds(this, seconds,zone)
    override fun minusMillis(millis: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusMillis(this, millis,zone)
    override fun minusWeeks(weeks: Long, zone: String): eLocalDateTime = implLocalDateTimeMinusWeeks(this, weeks,zone)
    override fun plusYears(years: Long, zone: String): eLocalDateTime = implLocalDateTimePlusYears(this, years,zone)
    override fun plusMonths(months: Long, zone: String): eLocalDateTime = implLocalDateTimePlusMonths(this, months,zone)
    override fun plusDays(days: Long, zone: String): eLocalDateTime = implLocalDateTimePlusDays(this, days,zone)
    override fun plusHours(hours: Long, zone: String): eLocalDateTime = implLocalDateTimePlusHours(this, hours,zone)
    override fun plusMinutes(minutes: Long, zone: String): eLocalDateTime = implLocalDateTimePlusMinutes(this, minutes,zone)
    override fun plusSeconds(seconds: Long, zone: String): eLocalDateTime = implLocalDateTimePlusSeconds(this, seconds,zone)
    override fun plusMillis(millis: Long, zone: String): eLocalDateTime = implLocalDateTimePlusMillis(this, millis,zone)
    override fun plusWeeks(weeks: Long, zone: String): eLocalDateTime = implLocalDateTimePlusWeeks(this, weeks,zone)
}

expect fun implLocalDateTimeMinusYears(date: eLocalDateTime, years: Long): eLocalDateTime
expect fun implLocalDateTimeMinusMonths(date: eLocalDateTime, months: Long): eLocalDateTime
expect fun implLocalDateTimeMinusDays(date: eLocalDateTime, days: Long): eLocalDateTime
expect fun implLocalDateTimeMinusHours(date: eLocalDateTime, hours: Long): eLocalDateTime
expect fun implLocalDateTimeMinusMinutes(date: eLocalDateTime, minutes: Long): eLocalDateTime
expect fun implLocalDateTimeMinusSeconds(date: eLocalDateTime, seconds: Long): eLocalDateTime
expect fun implLocalDateTimeMinusMillis(date: eLocalDateTime, millis: Long): eLocalDateTime
expect fun implLocalDateTimeMinusWeeks(date: eLocalDateTime, weeks: Long): eLocalDateTime
expect fun implLocalDateTimePlusYears(date: eLocalDateTime, years: Long): eLocalDateTime
expect fun implLocalDateTimePlusMonths(date: eLocalDateTime, months: Long): eLocalDateTime
expect fun implLocalDateTimePlusDays(date: eLocalDateTime, days: Long): eLocalDateTime
expect fun implLocalDateTimePlusHours(date: eLocalDateTime, hours: Long): eLocalDateTime
expect fun implLocalDateTimePlusMinutes(date: eLocalDateTime, minutes: Long): eLocalDateTime
expect fun implLocalDateTimePlusSeconds(date: eLocalDateTime, seconds: Long): eLocalDateTime
expect fun implLocalDateTimePlusMillis(date: eLocalDateTime, millis: Long): eLocalDateTime
expect fun implLocalDateTimePlusWeeks(date: eLocalDateTime, weeks: Long): eLocalDateTime

expect fun eLocalDateTime.Companion.now(): eLocalDateTime

expect fun eLocalDateTime.toUtc(zone: String): eUtc

expect fun eLocalDateTime.platformDayOfWeekLocale(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String>

expect fun eLocalDateTime.platformAmPmLocale(locale: String): String

expect fun eLocalDateTime.platformMonthLocale(locale: String): String

expect fun eLocalDateTime.platformDayOfWeek(): eDateTimePartGetter.DAY_KIND

expect fun eLocalDateTime.platformAmPm(): eDateTimePartGetter.AMPM_KIND

expect fun eLocalDateTime.platformMonth(): eDateTimePartGetter.MONTH_KIND

expect fun implLocalDateTimeMinusYears(date: eLocalDateTime, years: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimeMinusMonths(date: eLocalDateTime, months: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimeMinusDays(date: eLocalDateTime, days: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimeMinusHours(date: eLocalDateTime, hours: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimeMinusMinutes(date: eLocalDateTime, minutes: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimeMinusSeconds(date: eLocalDateTime, seconds: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimeMinusMillis(date: eLocalDateTime, millis: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimeMinusWeeks(date: eLocalDateTime, weeks: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusYears(date: eLocalDateTime, years: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusMonths(date: eLocalDateTime, months: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusDays(date: eLocalDateTime, days: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusHours(date: eLocalDateTime, hours: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusMinutes(date: eLocalDateTime, minutes: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusSeconds(date: eLocalDateTime, seconds: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusMillis(date: eLocalDateTime, millis: Long, zone:String): eLocalDateTime
expect fun implLocalDateTimePlusWeeks(date: eLocalDateTime, weeks: Long, zone:String): eLocalDateTime