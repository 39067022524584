package router.pwChange

import app.ClientApiMemberPwEp
import app.ClientApiMemberRsa
import app.ClientApiMemberSessionCheck
import bxlt.api.member.EntApiMemberPwEp
import bxlt.api.member.EntApiMemberRsa
import bxlt.api.member.EntApiMemberSessionCheck

object N{
    suspend fun rsaGet(): EntApiMemberRsa.Res? = ClientApiMemberRsa.net()
    suspend fun pwChangeReady(): EntApiMemberSessionCheck.Res? = ClientApiMemberSessionCheck.net()
    suspend fun pwChange(rsaId:String, pw:String, newPw:String):EntApiMemberPwEp.Res? = ClientApiMemberPwEp.net{ req->
        req.rsaId = rsaId
        req.oldPw = pw
        req.newPw = newPw
    }
}