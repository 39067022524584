package ein2b.core.net

import ein2b.core.entity.eEntity

interface eRequestTask{
    class Retry(private val count:Int):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            request.retry(count)
            return true
        }
    }
    class ReadTimeOut(private val timeout:Int):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            request.readTimeout(timeout)
            return true
        }
    }
    class ConnectTimeOut(private val timeout:Int):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            request.connectTimeout(timeout)
            return true
        }
    }
    // val a = eAPI("aaa",...)
    // a("a" to 17, "b" to "hika")
    // eRequestTask.Form("a", "b")
    // a=17&b=hika

    class Form(private vararg val items:String):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            return items.all{k->
                request.items[k]?.let{
                    request.form(k, "$it")
                    true
                } ?: false
            }
        }
    }
    class XeinFromEntity(private val itemKey:String):eRequestTask{
        override suspend fun run(request:eRequest):Boolean{
            @Suppress("UNCHECKED_CAST")
            return (request.items[itemKey] as? ()-> eEntity)?.let {
                request.entity(it())
                true
            } ?: false
        }
    }
    class JsonFromEntity(private val itemKey:String):eRequestTask{
        override suspend fun run(request:eRequest): Boolean {
            @Suppress("UNCHECKED_CAST")
            return (request.items[itemKey] as? ()-> eEntity)?.let {
                request.json(it().stringify())
                true
            } ?: false
        }
    }
    class JsonFromString(private val itemKey:String):eRequestTask{
        override suspend fun run(request:eRequest): Boolean {
            return request.items[itemKey]?.let {
                request.json("$it")
                true
            } ?: false
        }
    }
    class Header(private vararg val items:String):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
             return items.all {k->
                 request.items[k]?.let {
                     request.header(k, "$it")
                     true
                 } ?: false
             }
        }
    }
    class UrlPath(private vararg val items:String):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            return items.all {k->
                request.items[k]?.let {
                    request.url += "/$it"
                    true
                } ?: false
            }
        }
    }
    class TextBody(private val itemKey:String):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            return  request.items[itemKey]?.let {
                request.body("$it")
                true
            } ?: false
        }
    }
    class ByteBody(private val itemKey:String):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            return  (request.items[itemKey] as? ByteArray)?.let {
                request.bytes(it)
                true
            } ?: false
        }
    }
    /*class BlobFile(private val itemKey:String):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            return  request.items[itemKey]?.let {
                request.blobFile(itemKey, it)
                true
            } ?: false
        }
    }*/
    class BlobFile(private vararg val items:String):eRequestTask{
        override suspend fun run(request:eRequest):Boolean{
            return items.all{ k->
                request.items[k]?.let{
                    request.blobFile(k, it)
                    true
                } ?: false
            }
        }
    }
    class file(private vararg val items:String):eRequestTask{
        override suspend fun run(request: eRequest): Boolean {
            return  items.all {k->
                (request.items[k] as? eApiFile)?.let{
                    request.file(k, it)
                    true
                } ?: false
            }
        }
    }
    suspend fun run(request:eRequest):Boolean
}