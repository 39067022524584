package router.comp

import app.Factory
import comp.Comp
import comp.CompValueOut
import comp.OutType
import comp.input.CompError
import comp.input.CompInputFile.Companion.MB10
import comp.input.CompUpDownFile
import comp.input.compError
import comp.input.compUpDownFile
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import m42.app.M42App
import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.File

class CompProfileImageList:Comp,CompValueOut<CompProfileImageList.ProfileData>{
    class ProfileData(var image:String, var itemList:List<String>, var file:Blob = DEFAULT_FILE){
        val isImageDefault get() = image == DEFAULT_IMAGE
        val isUploadDefault get() = file == DEFAULT_FILE
    }
    companion object{
        /**기본 프로필 이미지*/
        private const val DEFAULT_IMAGE = "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_comp3/2023/06/20/c9a0c56aae448da94d20affa79ed38ea.svg"
        private val DEFAULT_FILE = Blob()
        enum class K{
            wrap, imageLabel, profileLabel, uploadImage, uploadError, uploadBtn, profileList, profile_image, profile_imageCheck;
            override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
        }
        val factory = Factory.htmlUrl("comp/profile")
        private val itemFactory = Factory.html("""<li data-view="image"><div data-view="imageCheck"></div></li>""")

        suspend operator fun invoke(rootView:eView<HTMLElement>, subKey:Any, block:(CompProfileImageList)->Unit): CompProfileImageList {
            val comp = CompProfileImageList()
            block(comp)
            comp.target = rootView.sub(subKey, factory){ target ->
                target.sub(K.imageLabel).html = comp.imageLabel
                CompError("form-error").also{ it.comp(target, K.uploadError) }
                CompUpDownFile(comp.btnLabel){ compUpDownFile ->
                    compUpDownFile.isChangeSubmit = true
                    compUpDownFile.wrapperClass = "inline-flex"
                    compUpDownFile.acceptExtList = compUpDownFile.acceptExtImage
                    compUpDownFile.vali = compUpDownFile.singleRule(
                        false,
                        "r@프로필 이미지를 업로드하거나 아바타를 선택해 주세요.@5/5/1I",
                        "r@파일 용량이 너무 큽니다. 10MB 이내의 파일을 업로드해 주세요.@5/5/1J",
                        MB10,
                        "r@이미지 형식(jpg, jpeg, png, gif)의 파일을 업로드해 주세요.@5/5/1K",
                        "jpg","jpeg","png","gif"
                    )
                    compUpDownFile.changeBlock = {
                        eLaunch{
                            /** 용량 에러가 났을 경우엔 compUpDownFile.invalidFile에 파일이 들어 있음 */
                            (if(compUpDownFile.check()){ compUpDownFile.out() as File }else{ compUpDownFile.invalidFile })?.also{ file ->
                                comp.profileData.file = file
                                comp.profileData.image = URL.createObjectURL(file)
                                comp.afterUploaded?.invoke(file)
                            }
                            comp.setProfileList()
                        }
                    }
                    compUpDownFile.errorListener = { ok, msg->
                        eLaunch{
                            if(!ok) comp.profileData.image = DEFAULT_IMAGE
                            target.sub(K.uploadError).compError().update(ok, msg)
                            comp.setProfileList()
                        }
                    }
                    comp.compUpload = compUpDownFile
                }.also{ it.comp(target, K.uploadBtn) }
                target.sub(K.profileLabel).html = comp.profileLabel
                M42App.emptySub(target, K.uploadImage, K.profileList)
                comp.baseProp(target, true)
            }
            comp.setProfileList()
            return comp
        }
    }
    override val outs:HashMap<OutType, suspend ()-> ProfileData> = hashMapOf(OutType.DEFAULT to { profileData })
    override suspend fun clear(){
        target.sub(K.uploadError).compError().clear()
        profileData.image = DEFAULT_IMAGE
        profileData.file = DEFAULT_FILE
        profileData.itemList = listOf()
        compUpload.clear()

        setProfileList()
    }
    override fun check() = if(profileData.isImageDefault) compUpload.check()
    else !(!profileData.isImageDefault && compUpload.invalidFile != null)


    lateinit var target:eView<HTMLElement>
    lateinit var compUpload:CompUpDownFile
    var imageLabel = "r@프로필 이미지@5/5/1L"
    var profileLabel = "r@또는 아바타 선택@5/5/1M"
    var btnLabel = "r@파일 선택@5/5/1N"
    var profileData = ProfileData(DEFAULT_IMAGE, listOf())
    private var afterUploaded:((File)->Unit)? = null
    private var clickItem:((String)->Unit)? = null

    suspend fun setProfileData(url:String, urlList:List<String>){
        profileData.image = if(url == "") DEFAULT_IMAGE else url
        profileData.itemList = urlList
        setProfileList()
    }
    fun setAfterUploaded(block:((File)->Unit)? = null){ afterUploaded = block }
    fun setClickItem(block:((String)->Unit)? = null){ clickItem = block }

    suspend fun changeError(msg:String, ok:Boolean){
        target.sub(K.uploadError).compError().update(ok, msg)
        profileData.image = DEFAULT_IMAGE
        profileData.file = DEFAULT_FILE
        compUpload.clear()
        setProfileList()
    }

    private suspend fun setProfileList(){
        /**
         1. 업로드 에러도 안났고 선택 안함 경우 :: clear
         2. 업로드 에러가 난 경우 :: 업로드 시
         3. 선택 안함 경우 :: 서밋 시
        */
        val isOk =  compUpload.value.isOk

        target.sub(K.uploadImage){
            it.lazyBackgroundImage = "" to profileData.image
            it.className = "upload-image profile${if(profileData.isImageDefault) " default" else ""}${if(isOk) "" else " form-error"}"
        }
        target.sub(K.profileList).setClearList{ listView ->
            profileData.itemList.forEach{ url ->
                val isSelected = profileData.image == url
                listView += eView(itemFactory){ pView ->
                    pView.sub(K.profile_imageCheck).className = "profile-check${if(isSelected) " checked" else ""}"
                    pView.sub(K.profile_image){
                        it.className = "profile${if(isSelected) " selected" else ""}"
                        it.lazyBackgroundImage = "" to url
                        it.click = { _,_ ->
                            eLaunch{
                                profileData.image = url
                                profileData.file = DEFAULT_FILE
                                compUpload.clear()
                                target.sub(K.uploadError).compError().clear()
                                setProfileList()

                                clickItem?.invoke(url)
                            }
                        }
                    }
                }
            }
        }
    }
}
suspend fun eView<HTMLElement>.compProfileImageListSet(subKey:Any, block:(CompProfileImageList)->Unit): CompProfileImageList {
    return CompProfileImageList(this, subKey, block)
}
inline fun eView<HTMLElement>.compProfileImageList(block:(CompProfileImageList)->Unit = {}): CompProfileImageList {
    val comp = this["compProfileImageList_value"] as? CompProfileImageList ?:err("fail to get compProfileImageList")
    block(comp)
    return comp
}