package ein2b.core.view.router

import ein2b.core.core.eParseUrl
import ein2b.core.entity.Report
import ein2b.core.entity.eEntity

class eQueryData(val url:String): eRouterData {
    override val key:String
    val data:Map<String, String>
    init{
        val (k, v) = eParseUrl(url)
        key = k
        data = v
    }
    override fun <T: eEntity> parse(entity:T, error:((Report)->Unit)?):T?{
        var json = ""
        for((k, v) in data) json += "\"$k\":\"$v\","
        return eEntity.parse(entity, "{${json.dropLast(1)}}", error = error ?: {})
    }
}