package m42.common.api

import ein2b.core.entity.eEntity
import m42.common.enum.EnumApiAccessAllow
import kotlin.reflect.KClass

abstract class EntM42Api<REQ: eEntity, RES: eEntity>(val resCls:KClass<RES>, private val req:()->REQ, private val res:()->RES){
    companion object{
        const val ENTITY_KEY = "entity"
        const val FILE_KEY = "upfile"
        val FILE_KEY_LIST_10 = (1..10).map{ "${FILE_KEY}${it}" }
        const val APP_VER_KEY = "appVer"
    }
    protected abstract val path:String
    protected abstract val jsonPath:String
    fun url() = urlDeco(if(isTest) jsonPath else path)
    protected open fun urlDeco(path:String) = path

    fun request():REQ = req()
    fun response():RES = res()

    open val rscKeys:List<String> = listOf()
    open val apiItems:List<String> = listOf()
    open val accessAllow get() = EnumApiAccessAllow.LOGIN
    open val readTimeOut = 5000
    open val connectTimeOut = 3000

    /** 진짜 데이터가 아니라 가짜 Json으로 테스트 할때 사용 */
    open var isTest = false
    open var isXeinFormat = false
}