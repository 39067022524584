package ein2b.core.view.router

import ein2b.core.log.log
import ein2b.core.view.router.eBaseOrder.Companion.TOP

class eStaticRouter<T>(
    base:eBase<T>,
    val useCache:(String,Map<String,String>?)->Boolean = {_,_-> true }
):eRouter<T>(base){
    private var curr:ePage<T>? = null
    private val caches = hashMapOf<String, ePage<T>>()
    private val afterShowHide = mutableSetOf<suspend (eRouterData)->Unit>()
    fun addAfterShowHideHook(vararg blocks:suspend (eRouterData)->Unit){afterShowHide += blocks}
    override suspend fun prepare(k:String, arg:Map<String,String>?):Pair<eBaseOrder, ePage<T>>?{
        if(useCache(k, arg)) caches[k]?.let{
            return TOP to it
        }
        return factories[k]?.let{
            TOP to it(this).also{
                base.addLast(it)
                caches[k] = it
            }
        } ?: checkFactories[k]?.let{ (checkBlock, f)->
            val isCheck = checkBlock(k, arg)
            if(isCheck){
                TOP to f(this).also{
                    base.addLast(it)
                    caches[k] = it
                }
            }else{
                null
            }
        }
    }
    override suspend operator fun invoke(order:eBaseOrder, page:ePage<T>, data:eRouterData){
        curr = page
        base.curr = page
        caches.forEach{ (_, v)->
            if(v === page){
                v.data = data
                v.show()
            }else v.hide()
        }
        afterShowHide.forEach{ it(data) }
    }
    override suspend fun restore(){
        caches.clear()
        curr = null
    }
}