package m42.member.vali

import ein2b.core.validation.eRuleVali

val ValiMemCoName = eRuleVali{
    Case{
        Trim()
        MinLength(1, "c@고객사명을 입력해 주세요.@member/vali/companyName/01")
        MaxLength(32, "c@32자 이내로 입력해 주세요.@member/vali/companyName/02")
    }
}