package ein2b.core.view.router

import ein2b.core.view.router.eBaseOrder.Companion.BOTTOM
import ein2b.core.view.router.eBaseOrder.Companion.TOP

class eBaseRouter<T>(
    base:eBase<T>,
    val useCache:(String,Map<String,String>?)->Boolean = {_,_-> true }
):eRouter<T>(base){
    private val caches = hashMapOf<String, ePage<T>>()
    private val cachesExact = hashMapOf<String, ePage<T>>()
    fun addCache(k:String, page:ePage<T>, isExact:Boolean = false){
        if(isExact) cachesExact[k] = page
        else caches[k] = page
    }
    override suspend fun prepare(k:String, arg:Map<String,String>?):Pair<eBaseOrder, ePage<T>>?{
        val a = arg?.entries?.joinToString("&"){ (k, v)->
            "$k=$v"
        } ?: ""
        val cKey = if(a.isEmpty()) k else "$k?$a"
        if(useCache(k, arg)){
            cachesExact[cKey]?.let{ return BOTTOM to it }
            val cacheKey = caches.keys.filter{ cKey.startsWith(it) }.sortedByDescending{ it.length }
            if(cacheKey.isNotEmpty()) return BOTTOM to caches[cacheKey[0]]!!
        }
        return factories[k]?.let{ TOP to it(this) } ?: checkFactories[k]?.let{ (checkBlock, f)->
            val isCheck = checkBlock(k, arg)
            if(isCheck) TOP to f(this) else null
        }
    }
    override suspend operator fun invoke(order:eBaseOrder, page:ePage<T>, data:eRouterData){
        page.data = data
        order(base, page)
    }
    override suspend fun restore(){
        caches.values.forEach {it.renew()}
        cachesExact.values.forEach {it.renew()}
    }
}