package ein2b.core.date

import ein2b.core.core.err

interface eDateTimePartGetter {
    val year:Int
    val month:Int
    val date:Int
    val hour:Int
    val minute:Int
    val second:Int
    val millisecond:Int

    // 아래 6가지 함수는 타입이
    // localDateTime이라면 타임존 무시하고 값을 해석해 돌려주고,
    // utc라면 UTC 기준으로 값을 해석해 돌려주며,
    // zone이 있는 경우에는 해당 zone의 로컬 타임을 기준으로 돌려주자
    // 로케일과 타임존은 관계가 없음에 유의하라
    fun localeDayOfWeek(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String>
    fun localeAmPm(locale: String): String
    fun localeMonth(locale:String): String
    fun dayOfWeekEnum(): DAY_KIND
    fun amPmEnum(): AMPM_KIND
    fun monthEnum(): MONTH_KIND

    enum class DAY_KIND(val day:Int, val title:String, val titleEnLong:String, val titleEnShort:String){
        SUN(0, "일", "Sunday", "Sun"),
        MON(1, "월", "Monday", "Mon"),
        TUE(2, "화", "Tuesday", "Tue"),
        WED(3, "수", "Wednesday", "Wed"),
        THU(4, "목", "Thursday", "Thu"),
        FRI(5, "금", "Friday", "Fri"),
        SAT(6, "토", "Saturday", "Sat");
        companion object{
            operator fun invoke(day:Int) = values().find{ it.day == (day%7) } ?: err("Wrong DAY_KIND value = $day")
        }
    }

    enum class AMPM_KIND {
        AM, PM
    }

    enum class MONTH_KIND(val month:Int) {
        JAN(1), FEB(2), MAR(3), APR(4), MAY(5), JUN(6),
        JUL(7), AUG(8), SEP(9), OCT(10), NOV(11), DEC(12)
    }

    companion object {

        private inline fun String.right(v: Int): String = this.substring(this.length - v)

        data class StringFoldReturn(val stringFromPart: String, val countToReduce: Int)

        private inline infix fun String.sfr(remain: Int) = StringFoldReturn(this, remain)

        private inline fun Int.digitToChar(): Char = "0123456789"[this]!! // 0부터 9까지의 정수만 처리할 수 있음에 유의하라

        // WARNING: n이 Int의 자릿수보다 작으면 잘린다는 점에 주의
        private tailrec fun Int.toRightPaddedString(n: Int, acc: StringBuilder = StringBuilder()): String =
            if(n==0)
                acc.reverse().toString()
            else
                (this/10).toRightPaddedString(n-1,acc.append((this%10).digitToChar()))


        // case문을 추가할 때 실행 순수에 주의할것. 다른 케이스를 덮는 케이스를 앞에 추가하면 X됨
        private inline fun yearCheck(s: String, d: eDateTimePartGetter): StringFoldReturn = when {
            s.startsWith("yyyyy") -> (d.year).toRightPaddedString(5) sfr 5
            s.startsWith("yyyy") -> (d.year).toRightPaddedString(4) sfr 4
            s.startsWith("yy") -> (d.year%100).toRightPaddedString(2) sfr 2
            // y 매치는 이미 확정임
            else -> (d.year%100).toString() sfr 1
        }

        val engMonthFullTitle = arrayOf(
            "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
        )
        val engMonthShortTitle = arrayOf(
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        )

        private fun Int.toEnglishShortString(case: EngCase): String = when(case) {
            EngCase.UPPER ->
                engMonthShortTitle[this-1].uppercase()
            EngCase.LOWER ->
                engMonthShortTitle[this-1].lowercase()
            else -> engMonthShortTitle[this-1]
        }
        private fun Int.toEnglishFullString(case: EngCase): String = when(case) {
            EngCase.UPPER ->
                engMonthFullTitle[this-1].uppercase()
            EngCase.LOWER ->
                engMonthFullTitle[this-1].lowercase()
            else -> engMonthFullTitle[this-1]
        }

        private enum class EngCase {
            UPPER, LOWER, TITLE
        }

        // TODO: month 문자 포맷에서 short form(JAVA의 경우 LLL을 사용하는데, full이나 long form도 지원해야 할지?
        private inline fun monthCheck(s: String, d: eDateTimePartGetter, locale: String): StringFoldReturn = when {
            s.startsWith("mm") -> (d.month).toRightPaddedString(2) sfr 2
            s.startsWith("mEl") -> (d.month).toEnglishShortString(EngCase.LOWER) sfr 3
            s.startsWith("mEU") -> (d.month).toEnglishShortString(EngCase.UPPER) sfr 3
            s.startsWith("mFl") -> (d.month).toEnglishFullString(EngCase.LOWER) sfr 3
            s.startsWith("mFU") -> (d.month).toEnglishFullString(EngCase.UPPER) sfr 3
            s.startsWith("mLd") -> d.localeMonth(locale) sfr 3
            s.startsWith("mE") -> (d.month).toEnglishShortString(EngCase.TITLE) sfr 2
            s.startsWith("mF") -> (d.month).toEnglishFullString(EngCase.TITLE) sfr 2
            s.startsWith("mL") -> d.localeMonth(locale) sfr 2
            // m 매치는 이미 확정임
            else -> d.month.toString() sfr 1
        }

        private inline fun dateCheck(s: String, d: eDateTimePartGetter): StringFoldReturn = when {
            s.startsWith("dd") -> (d.date).toRightPaddedString(2) sfr 2
            // d 매치는 이미 확정임
            else -> d.date.toString() sfr 1
        }

        private inline fun hourCheck(s: String, d: eDateTimePartGetter): StringFoldReturn = when {
            s.startsWith("HH24") -> (d.hour).toRightPaddedString(2) sfr 4
            s.startsWith("HH") -> (d.hour%12).toRightPaddedString(2) sfr 2
            s.startsWith("H24") -> (d.hour).toString() sfr 3
            // H 매치는 이미 확정임
            else -> (d.hour%12).toString() sfr 1
        }

        private inline fun minuteCheck(s: String, d: eDateTimePartGetter): StringFoldReturn= when {
            s.startsWith("MM") -> (d.minute).toRightPaddedString(2) sfr 2
            // M 매치는 이미 확정임
            else -> "${d.minute}" sfr 1
        }

        private inline fun secondCheck(s: String, d: eDateTimePartGetter): StringFoldReturn= when {
            s.startsWith("SS") -> (d.second).toRightPaddedString(2) sfr 2
            // S 매치는 이미 확정임
            else -> "${d.second}" sfr 1
        }

        private inline fun dayOfWeekCheck(s: String, d: eDateTimePartGetter, locale: String): StringFoldReturn {
            val (dow, dowWithLocale) = d.localeDayOfWeek(locale)
            return when {
                s.startsWith("WFU") -> (dow.titleEnLong.uppercase()) sfr 4
                s.startsWith("WFl") -> (dow.titleEnLong.lowercase()) sfr 4
                s.startsWith("WEU") -> (dow.titleEnShort.uppercase()) sfr 3
                s.startsWith("WEl") -> (dow.titleEnShort.lowercase()) sfr 3
                s.startsWith("WKF") -> "${dow.title}요일" sfr 3
                s.startsWith("WF") -> (dow.titleEnLong) sfr 2
                s.startsWith("WE") -> (dow.titleEnShort) sfr 2
                s.startsWith("WK") -> (dow.title) sfr 2
                // W 매치는 이미 확정임
                else -> dowWithLocale sfr 1
            }
        }

        private inline fun amPmCheck(s: String, d: eDateTimePartGetter, locale: String): StringFoldReturn {
            val isAm = d.hour < 12
            return when {
                s.startsWith("PEl") -> (if (isAm) "am" else "pm") sfr 3
                s.startsWith("PEU") -> (if (isAm) "AM" else "PM") sfr 3
                s.startsWith("PFl") -> (if (isAm) "a.m." else "p.m.") sfr 3
                s.startsWith("PFU") -> (if (isAm) "A.M." else "P.M.") sfr 3
                s.startsWith("PF") -> (if (isAm) "A.m." else "P.m.") sfr 3
                s.startsWith("PE") -> (if (isAm) "Am" else "Pm") sfr 2
                s.startsWith("PK") -> (if (isAm) "오전" else "오후") sfr 2
                // P 매치는 이미 확정임
                else -> (d.localeAmPm(locale)) sfr 1
            }
        }

        private val _da: Map<Char, (String, eDateTimePartGetter, String) -> StringFoldReturn> = hashMapOf(
            'y' to { s, d, _-> yearCheck(s,d) },
            'm' to { s, d, l-> monthCheck(s,d,l) },
            'd' to { s, d, _-> dateCheck(s,d) },
            'H' to { s, d, _-> hourCheck(s,d) },
            'M' to { s, d, _-> minuteCheck(s,d) },
            'S' to { s, d, _-> secondCheck(s,d) },
            'V' to { s, d, _-> "00${d.millisecond}".right(3) sfr 1 },
            'U' to { s, d, _-> "00${d.millisecond}000".right(6) sfr 1 },
            'W' to { s, d, l-> dayOfWeekCheck(s,d,l) },
            'P' to { s, d, l-> amPmCheck(s,d,l) }
        )

        private inline fun oneCharacterConsumer(s:String, d: eDateTimePartGetter, locale: String): StringFoldReturn =
            s.substring(0,1) sfr 1

        tailrec fun String.partFold( acc: StringBuilder, date: eDateTimePartGetter, locale: String ): String {
            if(this.isBlank()) return acc.toString()

            val action:  (String, eDateTimePartGetter, String) -> StringFoldReturn = _da[this.first()] ?: ::oneCharacterConsumer

            val result = action(this,date,locale)
            return this.drop(result.countToReduce).partFold(acc.append(result.stringFromPart),date,locale)
        }

        fun part(format: String, date: eDateTimePartGetter, locale: String=""): String =
            format.partFold(StringBuilder(), date, locale)
    }
}