package router.login

import app.ClientApiMemberLoginEmail
import app.ClientApiMemberRsa
import bxlt.api.member.EntApiMemberLoginEmail
import bxlt.api.member.EntApiMemberRsa

object N {
    suspend fun rsaGet(): EntApiMemberRsa.Res? = ClientApiMemberRsa.net()
    suspend fun login(rsaId:String, email:String, pw:String): EntApiMemberLoginEmail.Res? = ClientApiMemberLoginEmail.net{ req->
        req.rsaId = rsaId
        req.email = email
        req.pw = pw
    }
}