package ein2b.core.date

import ein2b.core.core.err

class eZonedDateTime(val local: eLocalDateTime, val zone: String): Comparable<eZonedDateTime>, eDateTimePartGetter by local, eDateTimeArithmetic<eZonedDateTime> {

    override fun localeDayOfWeek(locale: String): Pair<eDateTimePartGetter.DAY_KIND, String>  = local.localeDayOfWeek(locale)

    override fun localeAmPm(locale: String): String  = local.localeAmPm(locale)

    override fun localeMonth(locale: String): String = local.localeMonth(locale)

    override fun dayOfWeekEnum(): eDateTimePartGetter.DAY_KIND = local.dayOfWeekEnum()

    override fun amPmEnum(): eDateTimePartGetter.AMPM_KIND = local.amPmEnum()

    override fun monthEnum(): eDateTimePartGetter.MONTH_KIND = local.monthEnum()

    init {
        if(!eZonedDateTime.checkZone(zone)) throw IllegalArgumentException("Invalid time zone string: ${zone}")
    }
    override fun compareTo(other: eZonedDateTime): Int = local.toUtc(zone).compareTo(other.local.toUtc(other.zone))

    override fun toString(): String = "${local.toString()} (${zone})"
    fun toStringWithoutZone(): String = local.toString()

    companion object {
    }

    fun toLocalWithSameUtc(targetZone:String) = this.toUtc().toLocalDateTime(targetZone)

    fun toUtc() = local.toUtc(zone)

    override fun minusYears(years: Long): eZonedDateTime = implZonedDateTimeMinusYears(this,years)
    override fun minusMonths(months: Long): eZonedDateTime = implZonedDateTimeMinusMonths(this,months)
    override fun minusDays(days: Long): eZonedDateTime = implZonedDateTimeMinusDays(this, days)
    override fun minusHours(hours: Long): eZonedDateTime = implZonedDateTimeMinusHours(this, hours)
    override fun minusMinutes(minutes: Long): eZonedDateTime = implZonedDateTimeMinusMinutes(this, minutes)
    override fun minusSeconds(seconds: Long): eZonedDateTime = implZonedDateTimeMinusSeconds(this, seconds)
    override fun minusMillis(millis: Long): eZonedDateTime = implZonedDateTimeMinusMillis(this, millis)
    override fun minusWeeks(weeks: Long): eZonedDateTime = implZonedDateTimeMinusWeeks(this, weeks)
    override fun plusYears(years: Long): eZonedDateTime = implZonedDateTimePlusYears(this, years)
    override fun plusMonths(months: Long): eZonedDateTime = implZonedDateTimePlusMonths(this, months)
    override fun plusDays(days: Long): eZonedDateTime = implZonedDateTimePlusDays(this, days)
    override fun plusHours(hours: Long): eZonedDateTime = implZonedDateTimePlusHours(this, hours)
    override fun plusMinutes(minutes: Long): eZonedDateTime = implZonedDateTimePlusMinutes(this, minutes)
    override fun plusSeconds(seconds: Long): eZonedDateTime = implZonedDateTimePlusSeconds(this, seconds)
    override fun plusMillis(millis: Long): eZonedDateTime = implZonedDateTimePlusMillis(this, millis)
    override fun plusWeeks(weeks: Long): eZonedDateTime = implZonedDateTimePlusWeeks(this, weeks)

    fun diff(interval:String, other:eZonedDateTime):Long {
        if(this.zone != other.zone)
            err("diff between different zones(this=${this} / other=${other})")
        when(val ii=interval.lowercase()){
            "y", "m", "d", "h", "i", "s", "ms" -> {}
            else -> err("invalid interval $interval")
        }
        return implZonedDateTimeDiff(interval, this, other)
    }
}

// zone을 체크해서 zone이 올바르지 않으면 IllegalArgumentException 예외를 발생시켜라
expect fun eZonedDateTime.Companion.checkZone(zone: String): Boolean
expect fun eZonedDateTime.Companion.SYSTEM_TIMEZONE():String

// 시스템이 지원하는 존이면 true, 아니면 false를 반환한다.
// 이 값과 checkZone을 함께 봐야 한다. checkZone이 true인데 이 값이 false면
// 시스템에서는 아직 지원하지 않는 타임존 문자열이므로 특별한 처리가 필요하다.
expect fun eZonedDateTime.Companion.supportedBySystem(zone: String): Boolean

expect fun eZonedDateTime.Companion.altZone(zone: String): String?

expect fun implZonedDateTimeDiff(interval: String, me: eZonedDateTime, other: eZonedDateTime):Long

expect fun implZonedDateTimeMinusYears(date: eZonedDateTime, years: Long): eZonedDateTime
expect fun implZonedDateTimeMinusMonths(date: eZonedDateTime, months: Long): eZonedDateTime
expect fun implZonedDateTimeMinusDays(date: eZonedDateTime, days: Long): eZonedDateTime
expect fun implZonedDateTimeMinusHours(date: eZonedDateTime, hours: Long): eZonedDateTime
expect fun implZonedDateTimeMinusMinutes(date: eZonedDateTime, minutes: Long): eZonedDateTime
expect fun implZonedDateTimeMinusSeconds(date: eZonedDateTime, seconds: Long): eZonedDateTime
expect fun implZonedDateTimeMinusMillis(date: eZonedDateTime, millis: Long): eZonedDateTime
expect fun implZonedDateTimeMinusWeeks(date: eZonedDateTime, weeks: Long): eZonedDateTime
expect fun implZonedDateTimePlusYears(date: eZonedDateTime, years: Long): eZonedDateTime
expect fun implZonedDateTimePlusMonths(date: eZonedDateTime, months: Long): eZonedDateTime
expect fun implZonedDateTimePlusDays(date: eZonedDateTime, days: Long): eZonedDateTime
expect fun implZonedDateTimePlusHours(date: eZonedDateTime, hours: Long): eZonedDateTime
expect fun implZonedDateTimePlusMinutes(date: eZonedDateTime, minutes: Long): eZonedDateTime
expect fun implZonedDateTimePlusSeconds(date: eZonedDateTime, seconds: Long): eZonedDateTime
expect fun implZonedDateTimePlusMillis(date: eZonedDateTime, millis: Long): eZonedDateTime
expect fun implZonedDateTimePlusWeeks(date: eZonedDateTime, weeks: Long): eZonedDateTime