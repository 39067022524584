package comp

import app.ALink
import app.Factory
import comp.input.CompInputText
import comp.input.compInputText
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compInputSectionString

class CompPageTitle:Comp{
    enum class K{
        nav, wrap, title, back, edit, edit_wrap, edit_btn, edit_input, edit_cancel, edit_submit,
        btnList;
        override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
    }
    class Edit(val cancelLabel:String = "c@취소@", val submitLabel:String = "c@저장@", val vali: eRuleVali, val submit:(v:String)->Unit)
    companion object{
        //language=html
        private val factory = Factory.html("""
<div class="flex-start page-title-wrap">
    <a data-view="${K.back}" class="alink btn backBtn disabled-border flex-shrink-0 margin-right16" style='margin-top: 7px'>
        <div class="ic-back" style="display:block"></div>
    </a>
    <div class="flex-grow-1">
        <div data-view="${K.wrap}">
            <div data-view="${K.title}" style='max-width: 571px; word-break: break-all'></div>
            <div data-view="${K.edit}.${K.edit_btn}" class="margin-left15 ic-edit2 cursor" style='min-width:20px; margin-bottom: 11px'></div>
            <div data-view="${K.btnList}"></div>
        </div>        
        <div data-view="${K.edit}.${K.edit_wrap}" class="margin-top4 flex-start margin-bottom4">
            <b data-view="${K.edit}.${K.edit_input}"></b>
            <div data-view="${K.edit}.${K.edit_cancel}" class="margin-left20 btn border"></div>
            <div data-view="${K.edit}.${K.edit_submit}" class="margin-left8 btn"></div>
        </div>
    </div>
</div>""")

        suspend operator fun invoke(root:eView<HTMLElement>, block:(CompPageTitle)->Unit):CompPageTitle{
            val comp = CompPageTitle()
            block(comp)
            comp.target = root.sub(K.nav, factory){ target->
                target.sub(K.wrap) {
                    if(comp.titleEditing) it.displayNone()
                    else it.displayFlex()
                    it.className = comp.wrapperClass.ifEmpty { "flex-end" }
                }
                target.sub(K.title).className = comp.titleClass.ifEmpty { "page-title flex" }
                target.sub(K.back).displayNone()
                target.sub(K.title).html = comp.title
                target.sub(K.edit){ editView->
                    comp.edit?.also{ edit->
                        editView.compInputSectionOneSet<String>(K.edit_input, CompInputText{ comp ->
                            comp.vali = edit.vali
                            comp.inputClass = "width3-1 form-input"
                        })
                        editView.sub(K.edit_btn){
                            it.displayBlock()
                            it.click = {_,_->
                                eLaunch{
                                    target.sub(K.wrap).displayNone()
                                    editView.sub(K.edit_wrap).displayFlex()
                                    editView.sub(K.edit_input).compInputSection().inputView().compInputText().value.inputValue(comp.title)
                                }
                            }
                        }
                        editView.sub(K.edit_wrap).displayNone()
                        editView.sub(K.edit_cancel){
                            it.html = edit.cancelLabel
                            it.click = {_,_->
                                eLaunch{
                                    comp.setEditInit()
                                }
                            }
                        }
                        editView.sub(K.edit_submit){
                            it.html = edit.submitLabel
                            it.click = {_,_->
                                eLaunch{
                                    val compInputTextSection = editView.sub(K.edit_input).compInputSectionString()
                                    if(compInputTextSection.check()) edit.submit(compInputTextSection.out()).also{
                                        target.sub(K.wrap).displayFlex()
                                        editView.sub(K.edit_wrap).displayNone()
                                    }
                                }
                            }
                        }
                    }?:also{
                        editView.sub(K.edit_btn).displayNone()
                        editView.sub(K.edit_wrap).displayNone()
                        editView.sub(K.edit_input)
                        editView.sub(K.edit_cancel)
                        editView.sub(K.edit_submit)
                    }
                }
                target.sub(K.btnList)
            }
            comp.setBtnListSetting()
            comp.setBack(comp.backLink)
            comp.baseProp(comp.target)
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    var title = ""
    var wrapperClass = ""
    var titleClass = ""
    var backLink:ALink? = null
    var edit:Edit? = null
    var isEllipsis = false
    var btnListSetting:(suspend (eView<HTMLElement>)->Unit)? = null
    var titleEditing = false
    var backClick:(()->Unit)? = null

    suspend fun setTitle(v:String, vararg attrList:Pair<String, String>){
        title = v
        target.sub(K.title){ titleView->
            attrList.forEach{ titleView.attr(it) }
            titleView.html = v
        }
    }
    suspend fun setBack(backLink:ALink? = null){
        target.sub(K.back){ backView ->
            backClick?.also{
                backView.displayBlock()
                backView.click = { _,_->
                    it()
                }
            }
            backLink?.also{
                backView.displayBlock()
                backView.href = it.link
            }
        }
    }
    suspend fun setEditInit(){
        target.sub(K.wrap).displayFlex()
        target.sub(K.edit){ editView->
            editView.sub(K.edit_wrap).displayNone()
            editView.sub(K.edit_input).compInputSectionString().clear()
        }
    }
    suspend fun setBtnListSetting(block:(suspend (eView<HTMLElement>)->Unit)? = null){
        btnListSetting = block
        target.sub(K.btnList){ btnListView->
            btnListSetting?.also{
                it.invoke(btnListView)
                btnListView.displayBlock()
            } ?: btnListView.displayNone()
        }
    }
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compPageTitle(block:(CompPageTitle)->Unit = {}):CompPageTitle{
    val comp = this["compPageTitle"] as? CompPageTitle ?: err("fail to get compPageTitle")
    block(comp)
    return comp
}