package router.subView

import app.*
import compLabelInputSectionPasswordSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.href
import ein2b.js.crypto.eCrypto
import bxlt.api.member.EntApiMemberRsa
import bxlt.vali.ValiMemberLoginPw
import bxlt.vali.ValiMemberLoginUserid
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionPassword
import prop.compLabelInputSectionText
import router.login.N

private val factory = Factory.htmlUrl("subView/loginForm")
private enum class LOGIN{ email, pw, pwSearchBtn, submit, join }
suspend fun subLogin(staticView:eView<HTMLElement>, subKey:Any, block:(EntApiMemberRsa.Res)->Unit) = staticView.sub(subKey, factory){ subView ->
    subView.compLabelInputSectionTextSet(LOGIN.email, "r@이메일@5/5/1", ValiMemberLoginUserid, sectionWrapperClass = "")
    subView.compLabelInputSectionPasswordSet(LOGIN.pw, "r@비밀번호@5/5/4", ValiMemberLoginPw)
    subView.sub(LOGIN.submit)
    subView.sub(LOGIN.pwSearchBtn).href = ALink(RouterKey.PW_SEARCH).link

    subView.sub(LOGIN.join).click = {_,_->
        hashManager.goUrl(RouterKey.JOIN)
    }
    subView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){
            view.clearCompValue(LOGIN.email, LOGIN.pw)
        }
    })
    subView.addEntityHook(EntApiMemberRsa.Res::class, object:eEntityHook<HTMLElement, EntApiMemberRsa.Res>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntApiMemberRsa.Res){
            view.sub(LOGIN.submit){ loginBtnView ->
                loginBtnView.click = { _, _ ->
                    eLaunch{
                        val email = view.sub(LOGIN.email).compLabelInputSectionText()
                        val pw = view.sub(LOGIN.pw).compLabelInputSectionPassword()
                        if(CompApp.checkAll(email, pw)){
                            N.login(entity.rsa.id, email.out(), eCrypto.rsaEncrypt(pw.out(), entity.rsa.key))?.also{ res->
                                if(res.isError){
                                    email.changeError(res.error.emailErrMsg, res.error.emailErrMsg.isBlank())
                                    pw.changeError(res.error.pwErrMsg, res.error.pwErrMsg.isBlank())
                                }else{
                                    block(entity)
                                }
                            }
                        }
                    }
                }
            }
        }
    })
}