package app

import app.RouterKey.HOME
import app.RouterKey.HOME_LOGIN
import app.RouterKey.JOIN
import app.RouterKey.JOIN_COMPLETE
import app.RouterKey.LOGIN
import app.RouterKey.MASTER_EDIT
import app.RouterKey.PW_CHANGE
import app.RouterKey.PW_RESET
import app.RouterKey.PW_SEARCH
import ein2b.core.core.eDecodeQueryString
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.router.eBaseRouter
import ein2b.core.view.router.eHashManager
import ein2b.core.view.router.eStaticRouter
import router.home.HomeP
import router.homeLogin.HomeLoginP
import router.join.JoinP
import router.joinComplete.JoinCompleteP
import router.login.LoginP
import router.masterEdit.MasterEditP
import router.pwChange.PwChangeP
import router.pwReset.PwResetP
import router.pwSearch.PwSearchP
import view.CompViewLnb
import view.CompViewToast

object RouterKey{
    const val HOME_LOGIN = "" //홈+로그인
    const val HOME = "home" //홈
    const val LOGIN = "login"
    const val JOIN = "join"
    const val JOIN_COMPLETE = "joinComplete" // 회원가입 완료
    const val PW_CHANGE = "pwChange"
    const val PW_SEARCH = "pwSearch"  // 비밀번호 찾기
    const val PW_RESET = "pwReset"  // 비밀번호 재설정
    const val MASTER_EDIT = "masterEdit"

    const val VIEW_EDIT = "viewEdit"
    const val VIEW_DETAIL = "viewDetail"
    const val LOG = "log"
    const val EVENT = "event"

}
var hashManager = eHashManager(HOME_LOGIN, { hash->
    eLaunch{
        val keyArr = hash.split("?")
        val (routerKey, routerArgStr) = keyArr.first() to (if(keyArr.size > 1) keyArr.last() else "")
        CompRouter.backHash(routerKey, routerArgStr)
        CompRouter.backLinkFromHash(hash)
        CompViewToast.openLocalStorage()

        val routerArg = eDecodeQueryString(routerArgStr)
//        val menuKey = when(routerKey){
//            VIEW_DETAIL, VIEW_EDIT, LOG, EVENT -> "$VIEW_DETAIL/${routerArg["r"]}"
//            else -> routerKey
//        }
        CompViewLnb.addHook(routerKey)
    }
}).apply{
    addRouters(
        eStaticRouter(CompRouter.uiRouter).apply{
            addFactories(
                LOGIN to { router -> LoginP(router) },
                JOIN to { router -> JoinP(router) },
                JOIN_COMPLETE to { router -> JoinCompleteP(router) },
                PW_SEARCH to { router -> PwSearchP(router) },
                PW_RESET to { router -> PwResetP(router) }
            )
        },
        eStaticRouter(CompRouter.staticRouter()).apply{
            addFactories(
                HOME_LOGIN to { router -> HomeLoginP(router) },
                HOME to { router -> HomeP(router) },
                PW_CHANGE to { router -> PwChangeP(router) },
                MASTER_EDIT to { router -> MasterEditP(router) }
            )
        },
        //rowid있는애
        eBaseRouter(CompRouter.baseRouter()).apply{
            addFactories(
            )
        }
    )
}