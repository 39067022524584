package entity

import m42.model.ViewModel

object EntClientPwSearch:ViewModel() {
    var email = "" // 아이디 (이메일)

    fun init(){
        email = ""
    }
}