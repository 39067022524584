package ein2b.core.view.router

import ein2b.core.entity.eEntity

@Suppress("UNCHECKED_CAST")
abstract class eBase<T>{
    var curr: ePage<T>? = null
    abstract suspend fun addFirst(page:ePage<T>)
    abstract suspend fun addLast(page:ePage<T>)
    abstract suspend fun remove(page:ePage<T>)
    open suspend fun clearOne(){}
    protected abstract suspend fun renew()
    suspend fun _select(){
        select()
        curr?.select()
    }
    protected abstract suspend fun select()
    suspend fun _deselect(){
        curr?.deselect()
        deselect()
    }
    protected abstract suspend fun deselect()
    suspend fun bottom(page:ePage<T>){
        if(page === curr) page.represent(page.data)
        else{
            val old = curr
            curr = page
            addFirst(page)
            page.restore()
            old?.let{
                it.isTop = false
                it.remove()
                remove(it)
            }
            clearOne()
        }
    }
    suspend fun top(page:ePage<T>){
        if(page === curr) page.represent(page.data)
        else{
            val old = curr
            curr = page
            addLast(page)
            page.add()
            old?.let{
                it.isTop = true
                it.remove()
                remove(old)
            }
            clearOne()
        }
    }
    suspend fun clear() = curr?.let{
        curr = null
        it.isTop = false
        it.remove()
        remove(it)
    }
    suspend fun restore(){
        renew()
        curr?.let{
            remove(it)
            it.renew()
            addLast(it)
        }
    }
    suspend fun <RETURN> entityReturn(entity: eEntity):RETURN?{
        return curr?.entityReturn(entity)
    }
}