package m42.domBase

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.core.view.router.*
import m42.app.M42App
import m42.common.M42ApiSetting
import m42.model.EntDummyOff
import m42.model.EntDummyOn
import m42.model.HcInit
import org.w3c.dom.HTMLElement
abstract class PageBase(factory:suspend ()-> eView<HTMLElement>, router:eRouter<HTMLElement>):eDomPage(factory, router){
    private suspend fun routeNet(data:eRouterData, event:String){
        //(data as? eQueryData)?.also{ console.log("====${event}:${data.url}====") }
        view?.displayNone()

        view?.entity(HcInit)
        M42ApiSetting.blockingOpen?.invoke()

        (eView.binder as eDomBinder).let{ domBinder ->
            dummy()
            view?.entity(EntDummyOn)
            domBinder.addRenderJob{
                eLaunch{
                    net(data).also{
                        view?.entity(EntDummyOff)
                        view?.displayBlock()
                        M42ApiSetting.blockingClose?.invoke()
                        M42App.windowScrollTo()
                    }
                }
                it.stop()
            }
        }
    }
    override suspend fun adding(data:eRouterData){
        (data as? eQueryData)?.also{ (router as eBaseRouter).addCache(data.url, this, isExact = true) }
        routeNet(data, "adding")
    }
    override suspend fun restoring(data:eRouterData){ routeNet(data, "restoring") }
    override suspend fun represent(data:eRouterData){ routeNet(data, "represent") }
    override suspend fun removing(){
        //console.log("====removing====")
        view?.entity(HcInit)
    }
    override suspend fun renew(){}
    override suspend fun shown(data:eRouterData){ routeNet(data, "shown") }
    override suspend fun hided(){
        view?.apply{
            scrollY = -1
            scrollY = 0.0
            displayNone()
            entity(HcInit)
        }
    }
    open suspend fun dummy(){}
    open suspend fun net(data:eRouterData){}
}