package router.pwReset

import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import bxlt.api.member.EntApiMemberPwEmailE2
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class PwResetP(router:eRouter<HTMLElement>):PageBase(::PwResetView, router) {
        override suspend fun net(data: eRouterData){
            data.parse(EntApiMemberPwEmailE2.Req())?.also {url ->
                /** 비밀번호 재설정	서버 통신				토큰 확인 */
                N.emailE2(url.urlToken)?.also{ res ->
                    view?.entity(res)
                }
            }
        }
}