import app.Factory
import comp.*
import comp.input.*
import ein2b.core.validation.eVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

suspend fun eView<HTMLElement>.compLabelInputSectionTextSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    sectionWrapperClass:String = "margin-top24",
    block:((CompInputText)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputText{
       it.vali = vali
        block?.invoke(it)
    }, isInline, width, sectionClass, sectionWrapperClass)
suspend fun <V> eView<HTMLElement>.compLabelInputSectionToggleRadioSet(
    subKey:Any, label:String, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    sectionWrapperClass:String = "x",
    block:((CompToggleRadio<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompToggleRadio<V>{
    block?.invoke(it)
}, isInline, width, sectionClass, sectionWrapperClass)
suspend fun <V> eView<HTMLElement>.compLabelInputSectionRadioSet(
    subKey:Any, label:String, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    sectionWrapperClass:String = "x",
    block:((CompRadio<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompRadio<V>{
    block?.invoke(it)
}, isInline, width, sectionClass, sectionWrapperClass)


suspend fun eView<HTMLElement>.compLabelInputSectionNumberSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    sectionWrapperClass:String = "margin-top24",
    block:((CompInputNumber)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputNumber{
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass, sectionWrapperClass)

suspend fun eView<HTMLElement>.compLabelInputSectionTextAreaSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    sectionWrapperClass:String = "",
    block:((CompTextarea)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompTextarea{
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass, sectionWrapperClass)
suspend fun eView<HTMLElement>.compLabelInputSectionDateSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100,
    sectionClass: String = "input-section", ymdPattern:String = "Y/m/d(w)", wrapperClass: String = "input-date",
    sectionWrapperClass:String = "margin-top24",
    block:((CompInputDate)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputDate{
    it.vali = vali
    block?.invoke(it)
    it.ymdPattern = ymdPattern
    it.wrapperClass = wrapperClass
}, isInline, width, sectionClass, sectionWrapperClass)
suspend fun eView<HTMLElement>.compLabelInputSectionDateNewSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100,
    sectionClass: String = "input-section", ymdPattern:String = "Y/m/d(w)", wrapperClass: String = "input-date",
    sectionWrapperClass:String = "margin-top24",
    block:((CompInputDateNew)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputDateNew{
    it.vali = vali
    block?.invoke(it)
    it.ymdPattern = ymdPattern
    it.wrapperClass = wrapperClass
}, isInline, width, sectionClass, sectionWrapperClass)

suspend fun eView<HTMLElement>.compLabelInputSectionPasswordSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section",
    block:((CompInputPassword)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputPassword{
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass)
suspend fun eView<HTMLElement>.compLabelInputSectionPriceSet(
    subKey:Any, label:String, isInline:Boolean = false, width:Int = 100, sectionClass:String = "input-section",
    block:((CompInputPrice)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputPrice{
    block?.invoke(it)
}, isInline, width, sectionClass)

suspend fun <V> eView<HTMLElement>.compLabelInputSectionSelectSet(
    subKey:Any, label:String,
    valiMsg:String = "", placeholder:String = "선택해 주세요", wrapperClass:String = "width6-1",
    isInline:Boolean = false, width:Int = 100,
    sectionWrapperClass:String = "margin-top24",
    itemTitleClass:String = "",
    block:((CompSelect<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompSelect<V>{
        it.wrapperClass = "selectbox-border${if(wrapperClass.isNotBlank()) " $wrapperClass" else ""}"
        it.placeholder = placeholder
        it.vali = it.singleRule(valiMsg.ifBlank{ "선택해 주세요" })
        it.itemTitleClass = itemTitleClass
        block?.invoke(it)
    }, isInline, width, wrapperClass = sectionWrapperClass)

suspend fun <V> eView<HTMLElement>.compLabelInputSectionCheckboxSet(
    subKey:Any, label:String,
    valiMsg:String = "", wrapperClass:String = "",
    isInline:Boolean = false, width:Int = 100,
    sectionWrapperClass:String = "margin-top24",
    block:((CompCheckBox<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompCheckBox<V>{
    it.wrapperClass = "checkbox-list${if(wrapperClass.isNotBlank()) " $wrapperClass" else ""}"
    if(valiMsg.isNotBlank()) it.vali = it.multiRule(valiMsg)
    block?.invoke(it)
}, isInline, width, wrapperClass = sectionWrapperClass)

suspend fun <V> eView<HTMLElement>.compLabelInputSectionGroupSelectSet(
    subKey:Any, label:String,
    valiMsg:String = "", placeholder:String = "선택해 주세요", wrapperClass:String = "width2-1",
    isInline:Boolean = false, width:Int = 100, isReverse:Boolean = false,
    sectionWrapperClass:String = "margin-top24",
    block:((CompGroupSelect<V>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompGroupSelect<V>{
    it.wrapperClass = "selectbox-border${if(wrapperClass.isNotBlank()) " $wrapperClass" else ""}"
    it.placeholder = placeholder
    it.isReverse = isReverse
    it.vali = it.singleRule(valiMsg.ifBlank{ "선택해 주세요" })
    block?.invoke(it)
}, isInline, width, wrapperClass = sectionWrapperClass)
suspend fun eView<HTMLElement>.compLabelInputSectionPriceSet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100,
    sectionClass: String = "input-section",
    sectionWrapperClass:String = "margin-top24",
    block:((CompInputPrice)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputPrice{
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass, sectionWrapperClass)
suspend fun eView<HTMLElement>.compLabelInputSectionUpDownFileSet(
    subKey:Any, label:String, btnLabel:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100,
    sectionClass: String = "input-section",
    sectionWrapperClass:String = "margin-top24",
    block:((CompUpDownFile)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompUpDownFile(btnLabel){
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass, sectionWrapperClass)
suspend fun eView<HTMLElement>.compLabelInputSectionUpDownFileDragSet(
    subKey:Any, label:String, infoMsg:String, btnLabel:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100,
    sectionClass: String = "",
    sectionWrapperClass:String = "margin-top24",
    block:((CompUpDownFileDrag)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompUpDownFileDrag(infoMsg, btnLabel){
    it.vali = vali
    block?.invoke(it)
}, isInline, width, sectionClass, sectionWrapperClass)
suspend fun <IN:Any,V,OUT,INPUT:CompInput<IN, V, OUT>> eView<HTMLElement>.compLabelInputSectionOneSet(
    subKey:Any, label:String, input:INPUT, isInline:Boolean = false, width:Int = 100,
    sectionClass: String = "",
    sectionWrapperClass:String = "margin-top24"
) = CompLabelInputSection(this, subKey, label, input, isInline, width, sectionClass, sectionWrapperClass)

class CompLabelInputSection<IN:Any,V, OUT, INPUT:CompInput<IN, V, OUT>>(val input:INPUT):Comp, CompValueOut<OUT>{
    companion object{
        //language=html
        private fun factory(className:String) = Factory.html("""
<div class="$className">
    <label data-view="label" class="input-block-label"></label>
    <b data-view="inputSection"></b>
</div>""")
        //language=html
        private fun inLineFactory(className:String) = Factory.html("""
<div class="flex $className vertical-top">
    <label data-view="label" class="margin-top12 input-inline-label flex-shrink-0"></label>
    <b data-view="inputSection"></b>
</div>""")
        suspend operator fun <IN:Any,V,OUT,INPUT:CompInput<IN, V, OUT>> invoke(
            root:eView<HTMLElement>, subKey:Any, label:String, input:INPUT, isInline:Boolean = false, width:Int = 100, sectionClass:String = "input-section",
            wrapperClass:String = "margin-top24"
        ):CompLabelInputSection<IN, V, OUT, INPUT>{
            val comp = CompLabelInputSection(input)
            comp.target = root.sub(subKey, if(isInline) inLineFactory(wrapperClass) else factory(wrapperClass)){ subView->
                subView.sub("label"){
                    it.html = label
                    if(isInline) it.width = "${width}px"
                }
                comp.inputSection = subView.compInputSectionOneSet("inputSection", input, "flex-grow-1", "", sectionClass)
            }
            comp.baseProp(root.sub(subKey), true)
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    lateinit var inputSection: CompInputSection<V>
    fun inputView(idx:Int = 0):eView<HTMLElement> = inputSection.inputView(idx)
    suspend fun setLabel(label:String, vararg attrList:Pair<String, String>){
        target.sub("label") {t ->
            attrList.forEach { t.attr(it) }
            t.html = label
        }
    }
    override suspend fun clear() = inputSection.clear()
    override fun check() = inputSection.check()
    suspend fun changeError(msg:String, ok:Boolean = false) = inputSection.changeError(ok, msg)
    fun inputValue(v:IN) = input.value.inputValue(v)
    fun displayBlock(){ target.displayBlock() }
    fun displayFlex(){ target.displayFlex() }
    fun displayNone(){ target.displayNone() }
    override val outs: HashMap<OutType, suspend () -> OUT> = hashMapOf(OutType.DEFAULT to { input.out() })
}