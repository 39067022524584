package entity

import ein2b.core.entity.eEntity
import m42.model.ViewModel

class EntClientJoinCompleteUrl:eEntity(){
    var token by string()
    var actionToken by string()
}
object EntClientJoinComplete:ViewModel() {
}