package ein2b.core.entity.field

import ein2b.core.entity.Union
import ein2b.core.entity.eEntity
import ein2b.core.entity.task.DefaultFactoryTask
import kotlin.reflect.KClass

class UnionField<T: eEntity>(val union: Union<T>): Field<T>(){
    companion object{
        val fields:HashMap<KClass<out eEntity>, UnionField<out eEntity>> = hashMapOf()
        inline operator fun <reified ENTITY: eEntity> get(union: Union<ENTITY>): UnionField<ENTITY> {
            @Suppress("UNCHECKED_CAST")
            return (fields[ENTITY::class] ?: UnionField(union).also { fields[ENTITY::class] = it }) as UnionField<ENTITY>
        }
    }
}
class UnionListField<T: eEntity>(val union: Union<T>): Field<MutableList<T>>(){
    companion object{
        val fields:HashMap<KClass<out eEntity>, UnionListField<out eEntity>> = hashMapOf()
        inline operator fun <reified ENTITY: eEntity> get(union: Union<ENTITY>): UnionListField<ENTITY> {
            @Suppress("UNCHECKED_CAST")
            return (fields[ENTITY::class] ?: UnionListField(union).also { fields[ENTITY::class] = it }) as UnionListField<ENTITY>
        }
    }
    @Suppress("NOTHING_TO_INLINE")
    inline fun eEntity.default(noinline factory:()->List<T>){
        //_task?.default = DefaultFactoryTask(factory)
        _defaultMap = _defaultMap ?: hashMapOf()
        _defaultMap!![_index] = DefaultFactoryTask(factory)
    }
}
class UnionMapField<T: eEntity>(val union: Union<T>): Field<MutableMap<String, T>>(){
    companion object{
        val fields:HashMap<KClass<out eEntity>, UnionMapField<out eEntity>> = hashMapOf()
        inline operator fun <reified ENTITY: eEntity> get(union: Union<ENTITY>): UnionMapField<ENTITY> {
            @Suppress("UNCHECKED_CAST")
            return (fields[ENTITY::class] ?: UnionMapField(union).also { fields[ENTITY::class] = it }) as UnionMapField<ENTITY>
        }
    }
    @Suppress("NOTHING_TO_INLINE")
    inline fun eEntity.default(noinline factory:()->Map<String,T>){
        //_task?.default = DefaultFactoryTask(factory)
        _defaultMap = _defaultMap ?: hashMapOf()
        _defaultMap!![_index] = DefaultFactoryTask(factory)
    }
}