package router.pwSearch

import app.ClientApiMemberLoginCheck
import app.ClientApiMemberPwEmailE1
import bxlt.api.member.EntApiMemberLoginCheck
import bxlt.api.member.EntApiMemberPwEmailE1

object N {
    suspend fun loginCheck(): EntApiMemberLoginCheck.Res? = ClientApiMemberLoginCheck.net()
    suspend fun emailE1(email: String):EntApiMemberPwEmailE1.Res? = ClientApiMemberPwEmailE1.net{req->
        req.email = email
    }

}