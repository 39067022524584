package m42.common

import ein2b.core.date.SYSTEM_TIMEZONE
import ein2b.core.date.eZonedDateTime
import ein2b.core.entity.eEntity
import ein2b.core.net.*
import m42.common.api.EntM42Api
import m42.common.api.EntM42Api.Companion.ENTITY_KEY
import m42.common.api.EntM42Api.Companion.FILE_KEY
import m42.common.api.EntM42ApiResponse

object M42ApiSetting{
    lateinit var storageKey:String
    lateinit var domain:String
    lateinit var geolocationDomain:String
    var blockingOpen:(()->Unit)? = null
    var blockingClose:(()->Unit)? = null
    var apiAfterBlock:(()->Unit)? = null
    init{
        eApi.sender = FetchSender()
    }
}
abstract class M42ApiNet<REQ: eEntity, RES: eEntity>(private val apiObject:EntM42Api<REQ, RES>){
    companion object{
        val timeZone = eZonedDateTime.SYSTEM_TIMEZONE()
    }
    abstract suspend fun goHome()
    abstract suspend fun responseProcess(result: eApiResult):RES?

    var headers = mutableListOf<String>()
    private val apiUrl = "${M42ApiSetting.domain}${apiObject.url()}"
    private var api:eApi = eApi("", eApi.DEFAULT to eApiInfo{
        method = eApi.POST
        url = apiUrl

        items += headers
        items += ENTITY_KEY
        items += apiObject.apiItems
        //if(FILE_KEY in apiObject.apiItems) requestTask += eRequestTask.BlobFile(FILE_KEY)
        apiObject.apiItems.filter{ it.indexOf(FILE_KEY) == 0 }.let{
            if(it.isNotEmpty()) requestTask += eRequestTask.BlobFile(*it.toTypedArray())
        }

        requestTask += eRequestTask.ReadTimeOut(apiObject.readTimeOut)
        requestTask += eRequestTask.JsonFromEntity(ENTITY_KEY)
        requestTask += eRequestTask.Header(*headers.toTypedArray())

        responseTask += eResponseTask.Text
        responseTask += eResponseTask.Entity{ EntM42ApiResponse(apiObject.resCls){ apiObject.response() } }
    })
    suspend fun net(vararg items:Pair<String,Any>, block:(suspend (req:REQ)->Unit)? = null):RES?{
        val req = apiObject.request()
        M42ApiSetting.blockingOpen?.invoke()
        block?.invoke(req)
        if(apiObject.rscKeys.isNotEmpty()) M42ApiRsc.rsc(*apiObject.rscKeys.toTypedArray())

        val argItems:MutableList<Pair<String, Any>> = mutableListOf(ENTITY_KEY to { req })
        items.find{ it.first == ENTITY_KEY }?.also{
            argItems.forEachIndexed{ idx,d->
                if(d.first == ENTITY_KEY){
                    argItems.removeAt(idx)
                    return@forEachIndexed
                }
            }
        }
        items.forEach{ argItems += it }

        return responseProcess(api(*argItems.toTypedArray())).also{ M42ApiSetting.blockingClose?.invoke() }
    }

    fun localStorageRsc(){
        M42ApiRsc.localStorageRsc(*apiObject.rscKeys.toTypedArray())
    }
}