package app

import bxlt.api.member.*
import bxlt.api.*
import bxlt.model.*

//------------ 계정
object ClientApiMemberRsa:ClientApi<EntApiMemberRsa.Req, EntApiMemberRsa.Res>(EntApiMemberRsa)
object ClientApiMemberLoginEmail:ClientApi<EntApiMemberLoginEmail.Req, EntApiMemberLoginEmail.Res>(
    EntApiMemberLoginEmail
)
object ClientApiMemberLoginCheck:ClientApi<EntApiMemberLoginCheck.Req, EntApiMemberLoginCheck.Res>(
    EntApiMemberLoginCheck
)
object ClientApiMemberLogout:ClientApi<EntApiMemberLogout.Req, EntApiMemberLogout.Res>(EntApiMemberLogout)
object ClientApiMemberPwEp:ClientApi<EntApiMemberPwEp.Req, EntApiMemberPwEp.Res>(EntApiMemberPwEp)
object ClientApiMemberPwEmailE1:ClientApi<EntApiMemberPwEmailE1.Req, EntApiMemberPwEmailE1.Res>(EntApiMemberPwEmailE1)
object ClientApiMemberPwEmailE2:ClientApi<EntApiMemberPwEmailE2.Req, EntApiMemberPwEmailE2.Res>(EntApiMemberPwEmailE2)
object ClientApiMemberPwEmailEp:ClientApi<EntApiMemberPwEmailEp.Req, EntApiMemberPwEmailEp.Res>(EntApiMemberPwEmailEp)
object ClientApiMemberSessionCheck:ClientApi<EntApiMemberSessionCheck.Req, EntApiMemberSessionCheck.Res>(
    EntApiMemberSessionCheck
)
object ClientApiMemberActionToken:ClientApi<EntApiMemberActionToken.Req, EntApiMemberActionToken.Res>(
    EntApiMemberActionToken
)
//------------- 마스터
object ClientApiMemberMasterJoinEmailStep1:ClientApi<MdlMemberJoinParam, EntApiMemberMasterJoinEmailStep1.Res>(EntApiMemberMasterJoinEmailStep1)
object ClientApiMemberMasterJoinEmailStep2:ClientApi<EntApiMemberMasterJoinEmailStep2.Req, EntApiMemberMasterJoinEmailStep2.Res>(EntApiMemberMasterJoinEmailStep2)
object ClientApiMemberMasterE:ClientApi<EntApiMemberMasterE.Req, EntApiMemberMasterE.Res>(EntApiMemberMasterE)
object ClientApiMemberMasterEp:ClientApi<MdlMemberEditParam, EntApiMemberMasterEp.Res>(EntApiMemberMasterEp)
object ClientApiMemberMasterSecretKeyEp:ClientApi<EntApiMemberMasterSecretKeyEp.Req, EntApiMemberMasterEp.Res>(EntApiMemberMasterSecretKeyEp)
