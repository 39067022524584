package bxlt.model

import ein2b.core.date.eUtc
import ein2b.core.entity.eEntity
import bxlt.enum.EnumGrpCat
import bxlt.vali.*
import m42.member.model.EnumM42MemberAcCat
import m42.member.model.MdlM42MemberGrp
import m42.member.model.MdlM42MemberGrpWithCnt
import m42.member.vali.ValiMemCoName

// 회원 가입 Param
class MdlMemberJoinParam:eEntity(true){
    var email by string{ validator(ValiMemAccountEmail) }
    var rsaId by string()
    var pw by string()
    var userName by string{ validator(ValiMemAccountUserName) }
    var phone by string{ validator(ValiMemAccountPhone) }
    var companyName by string{ validator(ValiMemCoName) }
}

// 회원 계정 정보
class MdlMember:eEntity(true){
    var memberAcRowid by long
    var masterAcRowid by long
    val isMaster get() = memberAcRowid == masterAcRowid
    var email by string
    var userName by string
    var phone by string
    var profileUrl by string
    var joinDate by utc
    var secretKey by string
    var secretKeyDate by utc
    var groupList by entityList(::MdlMemberGrp)
    var memberAcCat by enum<EnumM42MemberAcCat>() //회원 계정 상태
}
// 회원 계정 정보
class MdlMemberSummary:eEntity(true){
    var memberAcRowid by long
    var email by string
    var userName by string
    var phone by string
    var profileUrl by string
}

// 계정 정보 수정
class MdlMemberEditParam:eEntity(){
    var userName by string{ validator(ValiMemAccountUserName) }
    var phone by string{ validator(ValiMemAccountPhone) }
    var profileUrl by string{ validator(ValiMemAccountProfileUrl) }
    var companyName by string{ validator(ValiMemberCompanyName) }
}

// 서브 회원 등록 Param
class MdlMemberSubAddParam:eEntity(true){
    var email by string{validator(ValiMemAccountEmail)}
    var userName by string{validator(ValiMemAccountUserName)}
    var phone by string{validator(ValiMemAccountPhone)}
    var profileUrl by string{ validator(ValiMemAccountProfileUrl) }
    var memberGrpRowidList by longList
}

//서브 회원 수정 Param
class MdlMemberSubEditParam:eEntity(true){
    var memberRowid by long
    var userName by string{validator(ValiMemAccountUserName)}
    var phone by string{validator(ValiMemAccountPhone)}
    var profileUrl by string{ validator(ValiMemAccountProfileUrl) }
    var memberGrpRowidList by longList
}


// 회원 계정 그룹
class MdlMemberGrp:eEntity(){
    companion object{
        operator fun invoke(g: MdlM42MemberGrp) = MdlMemberGrp().also{
            it.memberGrpRowid = g.memberGrpRowid
            it.masterAcRowid = g.masterAcRowid
            it.grpTitle = g.grpTitle
            it.grpCat = EnumGrpCat(g.grpCatTitle)
            it.coKey = g.coKey
        }
        operator fun invoke(g: MdlM42MemberGrpWithCnt) = MdlMemberGrp().also{
            it.memberGrpRowid = g.memberGrpRowid
            it.masterAcRowid = g.masterAcRowid
            it.grpTitle = g.grpTitle
            it.grpCat = EnumGrpCat(g.grpCatTitle)
            it.coKey = g.coKey
            it.memberCnt = g.memberCnt
        }
    }
    var memberGrpRowid by long
    var masterAcRowid by long
    var grpCat by enum<EnumGrpCat>()
    val grpCatTitle get() = grpCat.grpTitle
    var grpTitle by string()
    var coKey by string()
    var memberCnt by int{default(0)}
}

//회원 회사 정보
class MdlMemberCompany:eEntity(){
    var companyName by string
    var companyRowid by long
}

//회원 탈퇴 관련 정보
class MdlMemberShipperDeleteInfo:eEntity(){
    companion object{
        val NULL = {MdlMemberShipperDeleteInfo()}
    }
    var member1Rowid by long{default(0L)} //화주 일련번호
    var member1AcCat by enum<EnumM42MemberAcCat> {default(EnumM42MemberAcCat.ACTIVE)} // 화주 계정 상태
    var title by string {default("")} // (삭제 요청, 삭제 요청 취소, 삭제)에 대한 타이틀
    var coKey by string{default("")} //화주 : shipper, 포워더 : forwarder
    var userName by string{default("")} //탈퇴요청한 사람 이름
    var profileUrl by string{default("")} //탈퇴요청한 사람 프로필
    var regDate by utc{default { eUtc.default() } } //탈퇴날짜

    val isNull get() = coKey == ""
}

class MdlRedisMember:eEntity(true){
    var memberRowid by long
    var name by string
    var email by string
}
class MdlMemberPwEpParam:eEntity(true){
    var pw by string
    var newPw by string
    var rsaId by string
    var _email by string{ encoding.isExcluded() }
}