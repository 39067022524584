package app

import comp.CompValueOut
import ein2b.core.core.eEncodeQueryString
import ein2b.core.core.ePage
import ein2b.core.core.err
import ein2b.core.core.uuid
import ein2b.core.view.click
import ein2b.core.view.eView
import ein2b.core.view.href
import kotlinx.browser.window
import m42.app.M42App
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import org.w3c.dom.pointerevents.PointerEvent

object CompApp{
    fun <T: CompValueOut<*>> checkAll(vararg compList:T?):Boolean = compList.map{ comp -> comp?.check() ?:false }.all{ it }
    fun link(e:Event, url:String, vararg arg:Pair<String, Any>) {
        val path = if(arg.isEmpty()) url else "$url?${eEncodeQueryString(*arg)}"
        val fullUrl = "${window.location.href.split('#')[0]}${if(path[0] == '#') path else "#$path"}"
        (e as? PointerEvent)?.also { pe ->
            when{
                pe.shiftKey -> window.open(fullUrl)
                pe.ctrlKey -> window.open(fullUrl, "_blank")
                else ->  M42App.hashManager.goUrl(url, *arg)
            }
        }
    }
    //language=html
    internal fun getLanguageTitleLnb(title: String, urlPath:String) =
        "<div class='flex-center'><div style='width:24px;height:16px;overflow:hidden'><img src='$urlPath' alt='' style='width:100%;height:100%;object-fit:cover'></div><div class='margin-left8' style='font-size:10px;color:#fff'>$title</div></div>"
}
suspend fun eView<HTMLElement>.clearCompValue(vararg subKeys:Any, isThrow:Boolean = true){
    subKeys.forEach {
        val sub = this.sub(it)
        val key = sub.props?.keys?.find{ "_value" in it }
        if(key == null){
            if(isThrow) err("clearCompValue invalid key : $key")
            else return@forEach
        }else{
            (sub.props!![key] as CompValueOut<*>).clear()
        }
    }
}

fun eView<HTMLElement>.setLink(url:String, vararg arg:Pair<String, Any>){
    val path = if(arg.isEmpty()) url else "$url?${eEncodeQueryString(*arg)}"
    val fullUrl = "${window.location.href.split('#')[0]}${if(path[0] == '#') path else "#$path"}"
    this.click = { e, _ ->
        (e as? PointerEvent)?.also { pe ->
            when {
                pe.shiftKey -> window.open(fullUrl)
                pe.ctrlKey -> window.open(fullUrl, "_blank")
                else ->  M42App.hashManager.goUrl(url, *arg)
            }
        }
    }
}

class ALink{
    companion object{
        operator fun invoke(key:String, args:MutableMap<String,Any> = mutableMapOf(), addArgKey:String):ALink{
            return ALink().also{
                it.key = key
                it.args = args
                it.addArgKey = addArgKey
            }
        }
        operator fun invoke(key:String, args:MutableMap<String,Any>):ALink{
            return ALink().also{
                it.key = key
                it.args = args
            }
        }
        operator fun invoke(key:String):ALink{
            return ALink().also{
                it.key = key
            }
        }
    }
    var key = ""
    var args = mutableMapOf<String,Any>()
    var addArgKey:String? = null
    val hashArgs get() = args.toList().toTypedArray()
    private val _link get() = if(args.isEmpty()) "#$key" else "#$key?${eEncodeQueryString(*hashArgs)}"
    private val separator get() = if(args.isEmpty()) "?" else "&"

    val link get() = if(window.location.href == window.location.origin+"/index.html"+_link) _link + separator + uuid() else _link

    fun href(view:eView<HTMLElement>, v:Any? = null){
        if(addArgKey != null && v != null) args[addArgKey!!] = "$v"
        view.href = link
    }
    fun href(view:eView<HTMLElement>, v:Any? = null, argKey:String? = null){
        if(argKey != null && v != null) args[argKey] = "$v"
        view.href = link
    }
}