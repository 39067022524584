package m42.member.model

import ein2b.core.entity.eEntity

//계정(ac=account)
open class MdlM42MemberAc: eEntity() {
    var memberAcRowid by long
    var masterAcRowid by long
    var memberAcCat by enum<EnumM42MemberAcCat>()
    var uuid by string()
    var regDate by utc()
}
class MdlM42MemberAcInfo:eEntity(){
    var memberAcInfoRowid by long
    var memberAcRowid by long
    var title by string()
    var contents by string()
}

//계정 정보 업데이트 Param
class MdlM42MemberAcInfoUpdateParam:eEntity(){
    companion object{
        val DEFAULT_LIST = { mutableListOf<MdlM42MemberAcInfoUpdateParam>() }
    }
    var title by string()
    var contents by string()

    //서버용
    var _memberAcRowid by long{
        default(-1L)
        encoding.isExcluded()
    }
}

//계정 인증 완료된 값
class MdlM42MemberAcAuthn:eEntity(){
    val memberAcAuthnRowid by long
    val memberAcRowid by long
    val memberAuthnCat by enum<EnumM42MemberAuthnCat>()
    val authnValue by string()
    val regDate by utc()
}
class MdlM42MemberAcAuthnParam:eEntity(){
    var memberAcRowid by long
    var memberAuthnCat by enum<EnumM42MemberAuthnCat>()
    var authnValue by string()
}

open class MdlM42MemberAcPwParam:eEntity(){
    var rsaId by string{default("")}
    var pw by string{default("")} //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음

    //서버용
    var _memberAcPwRowid by long{
        default(-1L)
        encoding.isExcluded()
    }
}

