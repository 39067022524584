package ein2b.core.date


actual fun eZonedDateTime.Companion.checkZone(zone: String): Boolean =
    supportedBySystem(zone) || zone in altTimeZoneMap || zone.equals("utc",true)

actual fun eZonedDateTime.Companion.supportedBySystem(zone: String): Boolean =
    einDayJs.intlZoneList.findIndex{v->v==zone} >= 0

actual fun eZonedDateTime.Companion.altZone(zone: String): String? = altTimeZoneMap[zone]

actual fun eZonedDateTime.Companion.SYSTEM_TIMEZONE(): String =
    js("""Intl.DateTimeFormat().resolvedOptions().timeZone""") as String

private val altTimeZoneMap = hashMapOf(
    "Africa/Asmara" to	"Africa/Addis_Ababa",
    "America/Argentina/Buenos_Aires" to "America/Sao_Paulo",
    "America/Argentina/Catamarca" to "America/Sao_Paulo",
    "America/Argentina/Cordoba" to "America/Sao_Paulo",
    "America/Argentina/Jujuy" to "America/Sao_Paulo",
    "America/Argentina/Mendoza" to "America/Sao_Paulo",
    "America/Atikokan" to "America/New_York",
    "America/Indiana/Indianapolis" to "America/New_York",
    "America/Kentucky/Louisville" to "America/New_York",
    "America/Nuuk" to "America/Sao_Paulo",
    "America/Shiprock" to "America/Denver",
    "Antarctica/South_Pole" to "Antarctica/McMurdo",
    "Asia/Chongqing" to "Asia/Shanghai",
    "Asia/Harbin" to "Asia/Shanghai",
    "Asia/Ho_Chi_Minh" to "Asia/Bangkok",
    "Asia/Kashgar" to "Asia/Urumqi",
    "Asia/Kathmandu" to "Indian/Cocos",  // 콜카타가 +5:30인데 지원 않함
    "Asia/Yangon" to "Indian/Cocos",
    "Atlantic/Faroe" to "Europe/Lisbo",
    "Europe/Kyiv" to "Europe/Athens",
    "GMT" to "Europe/London",
    "Pacific/Chuuk" to "Pacific/Guam",
    "Pacific/Kanton" to "Pacific/Apia",
    "Pacific/Pohnpei" to "Pacific/Guadalcanal",
)