package app

import bxlt.enum.EnumGrpCat
import ein2b.core.date.eUtc
import entity.EntClientCompAccount
import kotlin.random.Random

object App{
    fun datePart(v:eUtc, part:String = "yyyy-mm-dd(WK) HH24:MM:SS") = """<span style="font-size:12px;color:#666666">${v.format(part, "Asia/Seoul", "")}</span>"""
    fun logDate(v:String, date:eUtc) = """$v<span class="margin-left6">${datePart(date)}</span>"""


    private val random = Random(0)
    fun rand(from:Int, to:Int) = random.nextInt(to - from) + from
    fun groupList(groupList:List<EntClientCompAccount.MemberShipper.ShipperGrp>):List<EntClientCompAccount.MemberShipper.ShipperGrp>{
        val grpList1 = groupList.filter{ it.grpCat == EnumGrpCat.DEFAULT }.sortedBy{ it.grpTitle }
        val grpList2 = groupList.filter{ it.grpCat != EnumGrpCat.DEFAULT }.sortedBy{ it.grpTitle }
        return grpList1 + grpList2
    }
}
external fun frmViewDecorator(r:String, v:String):String
external fun frmHex2Type(r:String, v:String):String
