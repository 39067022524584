package ein2b.core.view

abstract class eBinder<T>{
    var isUpdate = false
    val renderQue = mutableListOf<eRenderItem<T>>()
    abstract suspend fun bind(root:T, view: eView<T>):List<eViewItem<T>>
    abstract suspend fun rebind(root:T, view: eView<T>)
    abstract fun addView(parent:T, child:T)
    abstract fun removeView(parent:T, child:T)
    abstract fun changeView(parent:T, child:T, at:Int)
    abstract fun swapView(old:T, new:T)
    fun addQue(view:T, keyViews:Map<String, T>?, k:String, v:Any){
        renderQue += eRenderItem(view, keyViews, k, v)
    }
    abstract fun cdataWrapper(key:String, str:String, view:eView<T>?):String
    abstract fun errorWrapper(key:String, str:String, view:eView<T>?):String
    abstract fun describe(template:T):String
    abstract fun removeAll(parent: T)
}