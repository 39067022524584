package ein2b.core.net

import ein2b.core.core.eEncodeUrl
import ein2b.core.entity.eEntity
import kotlinx.coroutines.flow.FlowCollector

class eRequest internal constructor(var url:String, val method:String){
    var result:Any = ""
    var retry = 1
        private set
    var readTimeout = 5000
        private set
    var connectTimeout = 3000
        private set
    val items = hashMapOf<String, Any>()
    var header:MutableMap<String, String>? = null
        private set
    var form:MutableList<Pair<String, String>>? = null
        private set
    var blobFile:MutableMap<String, Any>? = null
        private set
    var entity:eEntity? = null
        private set
    var json:String? = null
        private set
    var text:String? = null
        private set
    var byte:ByteArray? = null
        private set
    var files:MutableMap<String, eApiFile>? = null
        private set
    var credentials:String? = null
    fun retry(v:Int){retry = v}
    fun readTimeout(read:Int){
        readTimeout = read
    }
    fun connectTimeout(connect:Int){
        connectTimeout = connect
    }
    fun header(key:String, value:String){
        if(header == null) header = mutableMapOf()
        header?.put(key, value)
    }
    fun form(key:String, value:String){
        if(form == null) form = mutableListOf()
        form?.add(key to value)
    }
    fun entity(v:eEntity){entity = v}//x-ein
    fun json(v:String){json = v}
    fun body(v:String){text = v}
    fun bytes(v:ByteArray){byte = v}
    fun file(key:String, file:eApiFile){
        if(files == null) files = hashMapOf()
        files?.put(key, file)
    }
    fun blobFile(key:String, data:Any){
        if(blobFile == null) blobFile = hashMapOf()
        blobFile?.put(key, data)
    }
    private fun fixUrl(){
        val i = url.lastIndexOf('#')
        if(i != -1) url = url.substring(0, i)
        if(method == "GET") form?.let {
            url += (if(url.lastIndexOf('?') == -1) "?" else "&") +
                    it.map {(k, v)-> "${eEncodeUrl(k)}=${eEncodeUrl(v)}"}.joinToString("&")
        }
    }
    suspend fun send():eResponse{
        fixUrl()
        return eApi.sender(this)
    }
    suspend fun stream(flow: FlowCollector<eApiFlowData>, type: eApi.CollectType, info: eApiInfo){
        fixUrl()
        eApi.stream(this, flow, type, info)
    }
}