package router.masterEdit

import app.ClientApiMemberMasterE
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class MasterEditP(router:eRouter<HTMLElement>): PageBase(::MasterEditView, router){
    override suspend fun net(data:eRouterData){
        N.editReady()?.also{ res ->
            view?.entity(res)
        }
    }
}