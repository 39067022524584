package m42.app

import ein2b.core.cdata.eCdata
import ein2b.core.core.eEncodeQueryString
import ein2b.core.entity.eEntity
import ein2b.core.resource.Resource
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.core.view.router.eHashManager
import ein2b.js.browser.eLocalStorage
import kotlinx.browser.window
import m42.common.M42ApiSetting
import org.w3c.dom.HTMLElement

object M42App{
    lateinit var hashManager:eHashManager
    lateinit var homeRouterKey:String
    suspend fun subHtmlFromEntity(view:eView<HTMLElement>, entity: eEntity, vararg keys:Any){
        entity.props.forEach{ (k, v)-> view.sub(k){ it.html = "$v" } }

        //entity.forEach(*keys.map{ "$it" }.toTypedArray()){ k, v-> view.sub(k){ it.html = "$v" } }
    }
    suspend fun subHtmlFromLabel(view:eView<HTMLElement>, vararg keys:Pair<Any, String>){
        keys.forEach{ (k, v) -> view.sub(k){ it.html = v} }
    }
    suspend fun emptySub(view:eView<HTMLElement>, vararg keys:Any){ keys.forEach{ view.sub(it) } }
    fun reload() = window.location.reload()
    fun windowScrollTo(x:Double = 0.0, y:Double = 0.0) = window.scrollTo(x, y)

    private fun path(k:String, vararg arg:Pair<String, Any>) = if(arg.isEmpty()) k else "$k?${eEncodeQueryString(*arg)}"
    /** 라우팅 할 URL과 현재 URL이 같으면 reload, 현재 URL이 다르면 hashManager.goUrl */
    fun goUrl(k:String, vararg arg:Pair<String, Any>){
        val path = if(arg.isEmpty()) k else "$k?${eEncodeQueryString(*arg)}"
        val url = "${window.location.href.split('#')[0]}${if(path[0] == '#') path else "#$path"}"
        if(window.location.href == url) window.location.reload() else hashManager.goUrl(k, *arg)
    }


    /*fun goUrl(k:String, vararg arg:Pair<String, Any>){
        val path = path(k, *arg)
        val url = "${window.location.href.split('#')[0]}${if(path[0] == '#') path else "#$path"}"
        if(window.location.href == url) window.location.reload() else hashManager.goUrl(k, *arg)
    }*/
    fun changeUrl(hash:String){
        M42ApiSetting.blockingOpen?.invoke()
        window.location.href = "index.html#$hash"
    }
    fun moduleUrl(module:String, k:String, vararg arg:Pair<String, Any>){
        window.location.href = "$module#${path(k, *arg)}"
    }
    fun dateFormat(v:Int):String{
        val d = "0$v"
        return d.substring(d.length-2, d.length)
    }

    suspend fun routerError(){
        window.alert("router data.parse error")
        hashManager.back()
    }

    @Deprecated("CompViewToast setLocalStorage 사용할 것")
    fun setFrontMsg(frontMsg:String){ eLocalStorage["frontMsg"] = frontMsg }
    @Deprecated("CompViewToast openLocalStorage 사용할 것")
    fun getFrontMsg() = eLocalStorage["frontMsg"] ?: ""

    fun phoneFormatter(phone:String) = when(phone.length){
        11 -> "${phone.substring(0,3)}-${phone.substring(3,7)}-${phone.substring(7)}"
        10 -> if(phone.substring(0,2) == "02") "${phone.substring(0,2)}-${phone.substring(2,6)}-${phone.substring(6)}"
        else "${phone.substring(0,3)}-${phone.substring(3,6)}-${phone.substring(6)}"
        8 -> "${phone.substring(0,4)}-${phone.substring(4)}"
        else -> phone
    }

    private val rAttr = """@(v[0-9]+)@""".toRegex()
    private fun attrReplace(str:String, vararg attrs:Pair<String, Any>) = if(attrs.isNotEmpty()){
        val attrMap = hashMapOf<String, Any>()
        attrs.forEach{ (k,v)->
            attrMap[k] = v
        }
        str.replace(rAttr){ "${attrMap[it.groupValues[1]] ?: ""}"}
    }else str
    fun cdata(key:String, vararg attrs:Pair<String, Any>):String{
        val i = key.indexOf('@', 2)
        val cKey = key.substring(if(i == -1) 2 else i + 1)
        val r:Any? = eCdata[cKey]
        val v = if(r == null) """<span class='cdata-default'>${if(i == -1) "--" else key.substring(2, i)}</span>""" else "$r"
        return attrReplace(v, *attrs)
    }
    fun resource(key:String, vararg attrs:Pair<String, Any>):String{
        val i = key.indexOf('@', 2)
        val rKey = key.substring(if(i == -1) 2 else i + 1)
        val r:Any? = Resource[rKey, { resource(key, *attrs) }]
        val default = key.substring(2, i)
        val v = when(r){
            null -> """<span class='cdata-default'>${if(i == -1) "--" else default}</span>"""
            Resource.NO_KEY -> """<span class='rsc-error'>${if(i == -1) "--" else default}</span>"""
            else -> "$r"
        }
        return attrReplace(v, *attrs)
    }
}