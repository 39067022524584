package bxlt.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali

private val REG_UINT = """^[0-9]+$""".toRegex()
internal fun _valiUintCheck(v: Any) =
    (v as? String)?.let{ s ->
        val regexResult = REG_UINT.matches(s)
        if(regexResult) s else eRuleSet.FALSE
    } ?: (v as? Number)?.let{ n ->
        val regexResult = REG_UINT.matches("$n")
        if(regexResult) n else eRuleSet.FALSE
    } ?: eRuleSet.FALSE

//페이지
val ValiPage = eRuleVali{
    Case{
        Rule("페이지는 정수여야 합니다."){v,_->
            _valiUintCheck(v)
        }
        Rule("페이지는 1이상의 숫자여야 합니다."){v,_->
            if("$v".toLong() > 0L) v else eRuleSet.FALSE
        }
    }
}
val ValiRowId = eRuleVali{
    Case{
        Rule("유효하지 않은 일련번호입니다."){v,_->
            _valiUintCheck(v)
        }
    }
}
val ValiFileId = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "파일Id를 입력해주세요")
        MinLength(32, "파일Id는 32자 이내로 입력해주세요")
    }
}
private val REG_PHONE = """^01(?:[0|1|6|7|8|9])(?:[0-9]{3,4})(?:[0-9]{4})$""".toRegex()
internal fun _valiPhoneCheck(v:Any) =
    (v as? String)?.let{ v ->
        if (REG_PHONE.matches(v)) v else eRuleSet.FALSE
    } ?: eRuleSet.FALSE

//https://s3.ap-northeast-2.amazonaws.com/
//사인 이미지 체크용 - 판매(출하)담당자, 운송 제품 패키지 수령완료시
private val REG_IMAGE_URL = """^(https?:)?//(.+)?""".toRegex()
internal fun _valiImageUrlCheck(v:Any):Any{
    val v = "$v"
    return if (v.matches(REG_IMAGE_URL)) v else eRuleSet.FALSE
}

val ValiRowids = eRuleVali{
    Case{
        Rule("c@유효하지 않은 일련번호입니다.@vali/ValiRowid/msg1"){ v, _ ->
            (v as? MutableList<*>)?.let{ list ->
                if(list.all { (it != null) && (_valiUintCheck(it) != eRuleSet.FALSE) } ) list
                else eRuleSet.FALSE
            } ?: eRuleSet.FALSE
        }
    }
}