package bxlt.vali

import ein2b.core.core.ePlatform
import ein2b.core.core.platform
import ein2b.core.validation.eRuleRouteVali
import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali

val isServerVali3: Boolean = platform() == ePlatform.JVM

//이메일
val ValiMemAccountEmail = eRuleVali{
    val msg1 = "r@아이디를 입력해 주세요.@5/5/2"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        Email("r@이메일 주소 형식으로 입력해 주세요.@5/5/3")
        MaxLength(100, "r@100자 이내로 입력해 주세요.@5/5/m")
    }
}
//가입 또는 비밀번호 변경 비밀번호
val ValiMemAccountPw = eRuleRouteVali{
    Case({_,_-> isServerVali3 }){
        val serverMsg4 = "r@새로운 비밀번호를 입력해 주세요.@5/5/1A"
        String(serverMsg4)
        Trim()
        MinLength(1, serverMsg4)
        MinLength(8, pwMsg2)
        MaxLength(32, pwMsg2)
        Rule(pwMsg3){ it, _-> if(it is String && (pw_rNumberSpecial.find(it) != null || pw_rEnglishNumber.find(it) != null || pw_rEnglishSpecial.find(it) != null)) it else eRuleSet.FALSE }
        Rule(pwMsg4){ it, _-> if(it !is String || (pw_rAllSpecial.findAll(it).count() != pw_rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
    Case({_,_-> true }){
        String(pwMsg1)
        Trim()
        MinLength(1, pwMsg1)
        MinLength(8, pwMsg2)
        MaxLength(32, pwMsg2)
        Rule(pwMsg3){ it, _-> if(it is String && (pw_rNumberSpecial.find(it) != null || pw_rEnglishNumber.find(it) != null || pw_rEnglishSpecial.find(it) != null)) it else eRuleSet.FALSE }
        Rule(pwMsg4){ it, _-> if(it !is String || (pw_rAllSpecial.findAll(it).count() != pw_rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
}
//로그인시 비밀번호
val ValiMemAccountPwForLogin = eRuleVali{
    Case{
        String(pwMsg1)
        Trim()
        MinLength(1, pwMsg1)
    }
}
// 회원 이름
val ValiMemAccountUserName = eRuleVali{
    val msg1 = "r@이름을 입력해 주세요.@5/5/19"
    val msg2 = "r@32자 이내로 입력해 주세요.@5/5/1a"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(32, msg2)
    }
}

// 회원 연락처
val ValiMemAccountPhone = eRuleVali{
    val msg1 = "r@연락처를 입력해 주세요.@5/5/1b"
    val msg2 = "r@32자 이내로 입력해 주세요.@5/5/1c"
    Case{
        String(msg1)
        Trim()
        Rule{it,_-> "$it".replace("-","").replace(" ", "") }
        MinLength(1, msg1)
        MaxLength(32, msg2)
    }
}

val ValiMemberLoginUserid = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@이메일을 입력해 주세요@5/5/2")
        Email("r@이메일 형식이 올바르지 않습니다.@5/5/3")
    }
}

val ValiMemberLoginPw = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "r@비밀번호를 입력하세요.@5/5/5")
    }
}
val ValiMemberJoinUsername = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "이름을 입력해 주세요")
        MaxLength(100, "너무 깁니다.")
    }
}

val ValiMemAccountProfileUrl = eRuleVali{
    val msg1 = "c@Invalid image file.@member/vali/profileUrl/01"
    Case{
        String()
        Rule(msg1){it,_-> _valiImageUrlCheck(it) }
    }
}
val ValiMemberJoinEmail = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, "이메일을 입력해 주세요")
        Email("이메일 주소 형식으로 입력해 주세요.")
        MaxLength(100, "너무 깁니다.")
    }
}

val ValiMemberCompanyName = eRuleVali{
    val msg1 = "r@회사명을 입력해 주세요.@5/5/1d"
    val msg2 = "r@회사명: 100자 이내로 입력해 주세요.@5/5/1e"
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(100, msg2)
    }
}
val ValiMemberEmail = eRuleVali{
    val msg1 = "c@이메일 주소를 입력해 주세요.@member/vali/email/01"
    val msg2 = "c@이메일 주소 형식으로 입력해 주세요.@member/vali/email/02"
    val msg3 = "c@100자 이내로 입력해 주세요.@member/vali/email/03"
    val email = """^[0-9a-zA-Z-_.]+@boundx.kr$""".toRegex()
    Case{
        String(msg1)
        Trim()
        MinLength(1, msg1)
        Email(msg2)
        MaxLength(100, msg3)
    }
}

val ValiMemberUserName = eRuleVali {
    val msg1 = "c@이름을 입력해 주세요.@member/vali/userName/01"
    val msg2 = "c@이름: 32자 이내로 입력해 주세요.@member/vali/userName/02"
    Case {
        String(msg1)
        Trim()
        MinLength(1, msg1)
        MaxLength(32, msg2)
    }
}

val ValiMemberPhone = eRuleVali{
    val msg1 = "c@휴대폰번호를 입력해 주세요.@member/vali/phone/01"
    val msg2 = "c@휴대폰번호: 휴대폰번호 형식으로 입력해 주세요.@member/vali/phone/02"
    Case{
        String(msg1)
        Trim()
        Rule{it,_-> "$it".replace("-","").replace(" ", "") }
        MinLength(1, msg1)
        Rule(msg2){it,_-> _valiPhoneCheck(it) }
    }
}

//회원 프로필 URL
val ValiMemberProfileUrl = eRuleVali{
    Case{
        String()
        Rule("c@Invalid image file.@member/vali/profileUrl"){it,_-> _valiImageUrlCheck(it) }
    }
}

private val pwMsg1 = "r@비밀번호를 입력해 주세요.@5/5/c"
private val pwMsg2 = "r@8~32자 이내로 입력해 주세요.@5/5/1B"
private val pwMsg3 = "r@영문/숫자/특수문자 중 2개 이상의 조합으로 입력해 주세요.@5/5/1C"
private val pwMsg4 = "특수문자는 !@#\$%^&*()_+~=-만 사용 가능합니다."
private val pw_rNumberSpecial = """^((?=.*\d)(?=.*[\W|_])).+$""".toRegex()
private val pw_rEnglishNumber = """^((?=.*[a-zA-Z])(?=.*\d)).+$""".toRegex()
private val pw_rEnglishSpecial = """^((?=.*[a-zA-Z])(?=.*[\W|_])).+$""".toRegex()
private val pw_rAllSpecial = """([\W|_])""".toRegex()
private val pw_rSpecial = """([!@#$%^&*()_+~=-])""".toRegex()
val ValiMemberJoinPw = eRuleVali{
    Case{
        String()
        Trim()
        MinLength(1, pwMsg1)
        MinLength(8, pwMsg2)
        MaxLength(32, pwMsg2)
        Rule(pwMsg3){ it, _-> if(it is String && (pw_rNumberSpecial.find(it) != null || pw_rEnglishNumber.find(it) != null || pw_rEnglishSpecial.find(it) != null)) it else eRuleSet.FALSE }
        Rule(pwMsg4){ it, _-> if(it !is String || (pw_rAllSpecial.findAll(it).count() != pw_rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
}

val ValiMemberAccountPw = eRuleRouteVali{
    Case({_,_-> isServerVali3 }){
        val serverMsg4 = "r@새로운 비밀번호를 입력해 주세요.@5/5/10"
        String(serverMsg4)
        Trim()
        MinLength(1, serverMsg4)
        MinLength(8, pwMsg2)
        MaxLength(32, pwMsg2)
        Rule(pwMsg3){ it, _-> if(it is String && (pw_rNumberSpecial.find(it) != null || pw_rEnglishNumber.find(it) != null || pw_rEnglishSpecial.find(it) != null)) it else eRuleSet.FALSE }
        Rule(pwMsg4){ it, _-> if(it !is String || (pw_rAllSpecial.findAll(it).count() != pw_rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
    Case({_,_-> true }){
        String(pwMsg1)
        Trim()
        MinLength(1, pwMsg1)
        MinLength(8, pwMsg2)
        MaxLength(32, pwMsg2)
        Rule(pwMsg3){ it, _-> if(it is String && (pw_rNumberSpecial.find(it) != null || pw_rEnglishNumber.find(it) != null || pw_rEnglishSpecial.find(it) != null)) it else eRuleSet.FALSE }
        Rule(pwMsg4){ it, _-> if(it !is String || (pw_rAllSpecial.findAll(it).count() != pw_rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
}

val ValiMemberAccountOldPw = eRuleVali{
    Case{
        Trim()
        MinLength(1, "r@기존 비밀번호를 입력해 주세요.@5/5/1r")
    }
}

val ValiMemCoName = eRuleVali{
    Case{
        Trim()
        MinLength(1, "r@고객사명을 입력해 주세요.@5/5/1R")
        MaxLength(32, "r@32자 이내로 입력해 주세요.@5/5/1S")
    }
}