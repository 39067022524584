package comp.input

import app.Factory
import comp.OutType
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement
/* ********************* CompInputPassword 사용법 *********************
val inputPassword = rootView.compInputSection("서브키 값", errorClass = "밸리 메시지 클래스"){
    listOf(
        CompInputPassword {
            it.vali = eRuleVali{							    // 밸리데이션
                Case{
                    String()						            // 자료형 밸리
                    Trim()						                // 공백 제거
                    MinLength(1, "패스워드 입력해주세요.")		    // 최소 자리수 설정
                    MaxLength(20, "패스워드 글자수 (@v0@/20)")		// 최대 자리수 설정
                }
            }

            it.placeholder = "placeholder"				        // 플레이스 홀더 설정
            it.inputClass = "form-input-num"					// 인풋 클래스 설정
            it.isDisabled = true						        // 활성화, 비황성화 여부 설정 // 초기화 시 다시 비활성화 해야함
            it.isChangeCheck = true						        // 활성화, 비황성화 여부 설정
            it.enterEvent = { console.log("엔터") }				// 엔터 이벤트 설정
            it.value.inputValue("123")						    // Hook 안의 기본값 설정
        }
    )
}

inputPassword.clear()						                    // 초기화
inputPassword.check()						                    // 검사
inputPassword.values()						                    // 값
*/

class CompInputPassword:CompInput<String, String, String>{
    companion object{
        private const val VIEW_NAME = "CompInputPassword"
        private const val WRAPPER:String = "${VIEW_NAME}_wrapper"
        private const val INPUT:String = "${VIEW_NAME}_input"
        private const val BTN:String = "${VIEW_NAME}_btn"
        private val FACTORY:suspend ()-> HTMLElement = Factory.html("""
            <div data-view="$WRAPPER" class="password-wrapper">
                <input data-view="$INPUT" type="password">
                <div data-view="$BTN"></div>
            </div>
        """)
        private const val ERROR_CLASS:String = "error"
        private const val SELECTED_CLASS:String = "selected"
        private const val DISABLED_CLASS:String = "disabled"
        operator fun invoke(block:(CompInputPassword)->Unit): CompInputPassword {
            val comp = CompInputPassword()
            block(comp)
            return comp
        }
    }
    override val outs:HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to { value.value })
    override lateinit var value:CompValue<String, String>
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali:eVali? = null
    override val factory:suspend () -> HTMLElement = FACTORY
    override var placeholder = ""
    override var tabIndex = -2
    override suspend fun init(it: eView<HTMLElement>){
        it.sub(WRAPPER)
        target = it.sub(INPUT){
            it.valueWithoutAttr = ""
            it.className = setClassName("")
            it.placeholder = placeholder
            it.disabled = isDisabled
            it.focus = { _,_-> if(it.disabled == false) eLaunch{ focus() } }
            it.blur = { _,_-> if(it.disabled == false) eLaunch{ blur() } }
            it.keyup = { e,el->
                val ev = eEvent(e, el)
                changedValue(ev.value, true)
                if(ev.keycode() == 13) eLaunch{ enterEvent?.invoke(this) }
            }
            it.keydown = { e,el-> CompInput.keyDownEvent(maxLength, e, el) }
            it.change = { e,el->
                var v = eEvent(e, el).value.trim()
                if(maxLength > -1 && v.length > maxLength) v = v.substring(0, maxLength)
                changedValue(changeBlock?.invoke(v) ?: v)
            }
        }
        btnView = it.sub(BTN) {
            it.className = "show-btn"
            it.click = {_,_ ->
                isShown = !isShown
                setInputType()
            }
        }
        value = CompValue("", "", vali, errorListener, CompInput.CONV) {
            target.valueWithoutAttr = it
        }
    }
    override suspend fun error(isOk:Boolean){
        target.className = setClassName(if(isOk) { if(isFocus) SELECTED_CLASS else "" } else ERROR_CLASS)
    }
    override suspend fun clear(){
        value.isOk = true
        value.inputValue("")
        enable(true)
        isShown = false
        setInputType()
    }

    private lateinit var target: eView<HTMLElement>
    private lateinit var btnView: eView<HTMLElement>
    private var isShown = false
    private var isFocus = false
    var inputClass:String = "form-input"
    var maxLength:Int = -1
    var enterEvent:(suspend (CompInput<String, String, String>)->Unit)? = null
    var isChangeCheck = false
    var focusBlock:(()->Unit)? = null
    var blurBlock:(()->Unit)? = null
    var changeBlock:((String)->String)? = null
    var isDisabled = false
    private fun setInputType(){
        if(isShown) target.inputType = "text" else target.inputType = "password"
        btnView.className = if(isShown) "show-btn active" else "show-btn"
    }
    fun changedValue(v:String, isViewOnly:Boolean = false){
        target.valueWithoutAttr = if(isViewOnly) eViewOnly(v) else v
        value.inputValue(v)
        if(isChangeCheck) eLaunch { value.check() }
    }
    private fun focus(){
        isFocus = true
        target.className = setClassName(SELECTED_CLASS)
        focusBlock?.invoke()
    }
    private fun blur(){
        isFocus = false
        target.className = setClassName(if(value.isOk) "" else ERROR_CLASS)
        blurBlock?.invoke()
    }
    fun enable(v:Boolean){
        isDisabled = !v
        target.disabled = isDisabled
        if(isFocus) focus() else blur()
    }
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayFlex() = target.displayFlex()
    fun placeholder(v:String){ target.placeholder = v }
    private fun setClassName(cls:String) = "$inputClass ${if(isDisabled) DISABLED_CLASS else cls}"
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compInputPassword(block:(CompInputPassword)->Unit = {}):CompInputPassword{
    val comp = this["compInputPassword_value"] as? CompInputPassword ?: err("fail to get compInputPassword")
    block(comp)
    return comp
}