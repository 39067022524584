package app

import app.Api.apiErrorAfterBlock
import bxlt.api.EntBxltApi
import bxlt.api.BxltApiErrorAction
import bxlt.api.BxltApiErrorMethod
import bxlt.api.BxltApiResponse
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.*
import ein2b.js.browser.eLocalStorage
import ein2b.js.browser.eSessionStorage
import bxlt.api.*
import kotlinx.browser.window
import m42.app.M42App
import m42.common.M42ApiSetting
import m42.common.api.EntM42Api
import view.CompViewAlert
import view.CompViewBlocking
import view.CompViewLnb
import view.CompViewToast

object Api{
    var serverDomain = ""
        set(v){
            M42ApiSetting.domain = v
            field = v
        }
    var isLogin = false

    var midKey:String = "mid"
    var blockingOpen = { CompViewBlocking.open() }
    var blockingClose = { CompViewBlocking.close() }
    //TODO 확인
    var apiErrorAfterBlock:((e: BxltApiResponse.Error)->Unit)? = null
    var sessionStorageMid:String
        get() = eSessionStorage[midKey] ?: ""
        set(v){ eSessionStorage[midKey] = v }
    var localStorageMid:String
        get():String  = eLocalStorage[midKey] ?: ""
        set(v){ eLocalStorage[midKey] = v }
}

val userMenu = mutableListOf(
    CompViewLnb.MenuUser.UserMenu(RouterKey.MASTER_EDIT, "내 정보", clickLink = ALink(RouterKey.MASTER_EDIT)),
    CompViewLnb.MenuUser.UserMenu(RouterKey.PW_CHANGE, "비밀번호 변경", clickLink = ALink(RouterKey.PW_CHANGE)),
    CompViewLnb.MenuUser.UserMenu(RouterKey.LOGIN, "로그아웃", "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_comp3/2023/08/07/700af9b2d73359b9a74b9df7380d2c93.svg"){
        eLaunch{ ClientApiMemberLogout.net()?.also{ hashManager.goUrl(RouterKey.LOGIN) } }
    }
)
abstract class ClientApi<REQ:eEntity, RES:eEntity>(private val apiObject: EntBxltApi<REQ, RES>){
    companion object{
        private const val MID_KEY = "mid"
    }
    private suspend fun responseProcess(result: eApiResult) = if(result.isOk){
        @Suppress("UNCHECKED_CAST")
        (result.response!!.result as? BxltApiResponse<RES>)?.let{ res ->
            Api.isLogin = res.isLogin
            if(Api.isLogin){
                CompViewLnb.displayBlock()
                (res.member as? BxltApiResponseMember.Login)?.also{mem->
                    Api.sessionStorageMid = mem.uuid
                    Api.localStorageMid = mem.uuid
                    CompViewLnb.menuUser = CompViewLnb.MenuUser(mem.profileUrl, "", mem.userName, mem.subInfo).apply{ menuList = userMenu }
//                    if(!res.isError && apiObject.isMenu) App.menuSetting(res.menu)
                }
            }else{
                CompViewLnb.displayNone()
                (res.member as? BxltApiResponseMember.Logout)?.also {
                    Api.sessionStorageMid = ""
                    Api.localStorageMid = ""
                }
                if(apiObject.isTest) CompViewLnb.menuUser = CompViewLnb.MenuUser(
                    "//s3.ap-northeast-2.amazonaws.com/m42upload/m42testimg/2023/06/22/8ea693072e7dfb1b8ce958d010ed6c89.png",
                    "",
                    "이은정",
                    "회사A"
                ).apply{ menuList = userMenu }
            }
            CompViewLnb.setUser()

            if(res.isError){
                res.error.also{ e ->
                    when(e.method){
                        BxltApiErrorMethod.ALERT -> {
                            when(e.action){
                                BxltApiErrorAction.NONE -> CompViewAlert.open(e.message)
                                BxltApiErrorAction.RELOAD -> CompViewAlert.open(e.message){ M42App.reload() }
                                BxltApiErrorAction.MOVE_TO_MAIN -> CompViewAlert.open(e.message){
                                    console.log("=======MOVE_TO_MAIN== err1 ========")
                                    hashManager.goUrl(M42App.homeRouterKey)
                                }
                                BxltApiErrorAction.MOVE_TO_LOGIN -> hashManager.goUrl(RouterKey.LOGIN)
                                BxltApiErrorAction.AFTER_BLOCK -> CompViewAlert.open(e.message){ M42ApiSetting.apiAfterBlock?.invoke()?.also{ M42ApiSetting.apiAfterBlock = null } }
                                else -> {}
                            }
                        }
                        BxltApiErrorMethod.TOAST, BxltApiErrorMethod.TOAST_ERROR -> {
                            val type = if(e.method == BxltApiErrorMethod.TOAST_ERROR) CompViewToast.TOAST_TYPE.ERROR else CompViewToast.TOAST_TYPE.DEFAULT
                            var isMoveToToast = true
                            when(e.action){
                                BxltApiErrorAction.BACK -> window.history.back()
                                BxltApiErrorAction.RELOAD -> M42App.reload()
                                BxltApiErrorAction.MOVE_TO_MAIN ->{
                                    console.log("=======MOVE_TO_MAIN= err2 =========")
                                    hashManager.goUrl(M42App.homeRouterKey)
                                }
                                BxltApiErrorAction.MOVE_TO_LOGIN -> hashManager.goUrl(RouterKey.LOGIN)
                                BxltApiErrorAction.AFTER_BLOCK -> apiErrorAfterBlock?.invoke(e)?.also{ apiErrorAfterBlock = null }
                                else -> isMoveToToast = false
                            }
                            if(e.message.isNotEmpty()){
                                if(isMoveToToast) CompViewToast.setLocalStorage(e.message, type = type)
                                else CompViewToast.open(e.message, type = type)
                            }
                        }
                        BxltApiErrorMethod.AFTER_BLOCK -> apiErrorAfterBlock?.invoke(e)?.also { apiErrorAfterBlock = null }
                        else -> console.log(e.message)
                    }
                }
                null
            }else{
                res.data
            }
        }
    }else{
        log(result.err)
        null
    }

    var headers = mutableListOf(MID_KEY)
    private val apiUrl = "${Api.serverDomain}${apiObject.url()}"
    private var api:eApi = eApi("", eApi.DEFAULT to eApiInfo{
        method = eApi.POST
        url = apiUrl

        items += headers
        items += EntM42Api.ENTITY_KEY
        items += apiObject.apiItems
        apiObject.apiItems.filter{ it.indexOf(EntM42Api.FILE_KEY) == 0 }.let{
            if(it.isNotEmpty()) requestTask += eRequestTask.BlobFile(*it.toTypedArray())
        }

        requestTask += eRequestTask.ReadTimeOut(apiObject.readTimeOut)
        requestTask += if(apiObject.isXeinFormat) eRequestTask.XeinFromEntity(EntM42Api.ENTITY_KEY) else eRequestTask.JsonFromEntity(EntM42Api.ENTITY_KEY)
        requestTask += eRequestTask.Header(*headers.toTypedArray())

        responseTask += eResponseTask.Text
        responseTask += if(apiObject.isXeinFormat) eResponseTask.EntityXein{ BxltApiResponse(apiObject.resCls){ apiObject.response() } }
            else eResponseTask.Entity{ BxltApiResponse(apiObject.resCls){ apiObject.response() } }

    })
    suspend fun net(vararg items:Pair<String,Any>, block:(suspend (req:REQ)->Unit)? = null):RES?{
        val req = apiObject.request()
        Api.blockingOpen.invoke()
        block?.invoke(req)
        val argItems:MutableList<Pair<String, Any>> = mutableListOf(MID_KEY to Api.sessionStorageMid, EntM42Api.ENTITY_KEY to { req })

        items.find{ it.first == EntM42Api.ENTITY_KEY }?.also{
            argItems.forEachIndexed{ idx,d->
                if(d.first == EntM42Api.ENTITY_KEY){
                    argItems.removeAt(idx)
                    return@forEachIndexed
                }
            }
        }

        items.forEach{ argItems += it }
        return if(checkSession()) responseProcess(api(*argItems.toTypedArray())).also{ Api.blockingClose.invoke() } else null
    }

    private fun checkSession():Boolean{
        if(apiObject.midIgnore) return true

        val newId = Api.localStorageMid
        if(newId != Api.sessionStorageMid){
            Api.sessionStorageMid = newId
            if(newId.isNotBlank()){
                hashManager.goUrl(M42App.homeRouterKey)
                return false
            }
        }
        return true
    }
}