package ein2b.core.cdata

import ein2b.core.entity.Union
import ein2b.core.entity.eEntity

sealed class eCdata:eEntity(){
    companion object:Union<eCdata>(::Cat, ::Value){
        val DEFAULT_MAP = { mutableMapOf<String, eCdata>() }
        internal val root:Cat by lazy{ //lazy를 안쓰면 JVM에서 런타임시 "Cannot load from object array" 에러가 남!
            Cat().also{
                it.data = hashMapOf()
            }
        }
        private val cache = hashMapOf<String, Any>()
        fun clearCache(k:String? = null) = k?.let{ cache.remove(k)} ?: cache.clear()
        private val requestKey = hashMapOf<String, HashSet<String>>()
        val request get() = if(requestKey.isEmpty()) "" else {
            val data = requestKey.map { (k, v) -> "$k=[${v.joinToString("&")}]" }.joinToString("&")
            requestKey.clear()
            data
        }
        fun removeObserver(observer: (String, Any) -> Unit){
            eCdataLoader.observers.forEach { (k, v)->
                if(v === observer) eCdataLoader.observers.remove(k)
            }
        }
        fun string(key:String):String{
            return if(key.startsWith("c@")) "${get<Any>(key)}" else return key
        }
        @Suppress("UNCHECKED_CAST")
        operator fun <T> get(key:String, observer:((String, Any)->Unit)? = null):T? {
            val k = if(key.startsWith("c@")) key.substring(2) else key
            observer?.let{
                if(k !in eCdataLoader.observers) eCdataLoader.observers[k] = hashSetOf()
                eCdataLoader.observers[k]?.add(it)
            }
            val cacheKey = "$k:${eCdataCat()}"
            cache[cacheKey]?.let{return it as T}
            var target = if(k in root.data) root.data[k]!! else{
                if(requestKey[k] == null) requestKey[k] = hashSetOf()
                requestKey[k]?.add("*")
                return null
            }
            var i = 50
            var rKey = ""
            while(i-- > 0){
                when(target){
                    is Cat ->{
                        val c = target.cat
                        var cv = eCdataCat[c]
                        var v = target.data[cv]
                        if(v == null) {
                            cv = target.default
                            v = target.data[cv]
                        }
                        if(v == null) {
                            cv = eCdataCat.default[c]
                            v = target.data[cv]
                        }
                        rKey += ",$c=${cv ?: eCdataCat[c]}"
                        target = if(v != null) v else{
                            if(requestKey[k] == null) requestKey[k] = hashSetOf()
                            requestKey[k]?.add(rKey.substring(1))
                            return null
                        }
                    }
                    is Value ->{
                        cache[cacheKey] = target.value
                        return target.value as T
                    }
                }
            }
            return null
        }
        private val CatF:()-> eEntity = ::Cat
        private val ValueF:()-> eEntity = ::Value
        internal val CdataF = ::eCdata
    }
    class Cat:eCdata(){
        var cat by string{ default("") }
        var default by string{ default("") }
        var data by unionMap(eCdata)
    }
    class Value:eCdata(){
        var value by string
    }
}