package router.pwChange

import app.Api
import app.ClientApiMemberLoginCheck
import app.RouterKey
import app.hashManager
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import m42.domBase.PageBase
import org.w3c.dom.HTMLElement

class PwChangeP(router:eRouter<HTMLElement>):PageBase(::PwChangeView, router){
    override suspend fun net(data:eRouterData){
        N.pwChangeReady()?.also{
            if(Api.isLogin) view?.entity(it) else hashManager.goUrl(RouterKey.LOGIN)
        }
    }
}